import React, { Component } from 'react'
import { Header, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { Link } from 'react-router-dom'
import '../../styles/login.css'
import api from '../../classes/api'
import PublicFrame from '../../components/publicFrame'
import OtrTextInput from '../../components/otrTextInput'

class ForgotPasswordScreen extends Component {
  constructor (props) {
    super(props)
    this.onReset = this.onReset.bind(this)
    this.state = {
      loading: false,
      success: false
    }
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  onReset = async ({ emailAddress }) => {
    this.toggleLoading()
    const result = await api.requestPasswordResetEmail({ 
      emailAddress, 
      resetUrl: `${window.location.origin}/reset?token=[token]`
    })
    console.log(result);
    this.toggleLoading()
    this.setState({ error: !result.success })

    if (result.success) {
      this.setState({ success: true })
    }
  }

  render () {
    const { loading, success, error } = this.state

    const resetForm = (
      <React.Fragment>
        <Header as='h1' inverted>RESET YOUR PASSWORD</Header>
        <hr />
        <Form id='loginForm' name='loginForm' onValidSubmit={this.onReset}>
          <OtrTextInput size='huge' name='emailAddress' required placeholder='Email Address' validations='isEmail' validationErrors={{ isEmail: 'Email Address not valid' }} />
          <Button loading={loading} primary fluid size='huge'>SEND RESET EMAIL</Button>
        </Form>
        {
          error && (
            <p className='errorMessage'>
              Sorry something went wrong
            </p>
          )
        }
        <hr />
        <Link to='/login/'>REMEMBER YOUR LOGIN DETAILS?</Link>
      </React.Fragment>
    )

    const successElement = (
      <React.Fragment>
        <Header as='h1' inverted>RESET REQUESTED</Header>
        <Header as='h5' inverted>IF YOU PROVIDED A VALID EMAIL ADDRESS, YOU WILL RECEIVE AN EMAIL WITH A LINK ON HOW TO RESET YOUR PASSWORD</Header>
      </React.Fragment>
    )

    return (
      <PublicFrame>
        <div style={{ textAlign: 'center' }}>
          { success ? successElement : resetForm }
        </div>
      </PublicFrame>
    )
  }
}

export default ForgotPasswordScreen
