import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Grid, Header, Icon } from 'semantic-ui-react'

export default class SubMenu extends Component {
  render () {
    const { to, title, exact = true, show } = this.props

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <span style={{ position: 'absolute', left: '10%', height: '100%', paddingTop: '5px', paddingRight: '3px' }}>
          <Icon name='angle left' size='large' inverted />
        </span>
        <Grid.Row style={{ position: 'absolute', left: '-100%', top: -1, backgroundColor: '#011c36', display: show ? 'block' : 'none' }}>
          <Grid.Row style={{ border: '1px solid white' }}>
            <NavLink exact={exact} to={to} className='menuLink' style={{padding:"0.3rem 1rem"}} >
              {this.props.children ? this.props.children : <div style={{ width: '2rem' }} />}
              <Header inverted as='h5'>{title}</Header>
            </NavLink>
          </Grid.Row>
        </Grid.Row>
      </div>

    )
  }
}
