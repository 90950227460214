import React, { Component } from 'react'
import { Button, Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import OtrTextInput from '../../components/otrTextInput'
import ScreenHeader from '../../components/screenHeader'
import OtrLoadingSpinner from '../../components/otrLoadingSpinner'
import OtrModal from '../../components/otrModal'
import api from '../../classes/api'
import OtrCheckbox from '../../components/otrCheckbox'

import { addValidationRule } from 'formsy-react'

addValidationRule('isFloat', (values, value) => {
  const floatValue = Number.parseFloat(value)
  if (Number.isNaN(floatValue)) {
    return false
  }
  return true
})

addValidationRule('isInteger', (values, value) => {
  const intValue = Number.parseInt(value)
  if (Number.isNaN(intValue)) {
    return false
  }
  return true
})

export default class SettingScreen extends Component {
  constructor(props)
  {
    super(props);
    this.toggleEnabled = this.toggleEnabled.bind(this);
    this.toggleValue = this.toggleValue.bind(this);
    this.state = {
      errors: [],
      loading: false,
      saveLoading: false,
      setting: {
        id: '',
        name: '',
        description: '',
        type: '',
        value: '',
        enabled: false
      }
  }
  }

  toggleLoading (key) {
    const loading = this.state[key]
    this.setState({ [key]: (!loading) })
  }

  async getSettingData () {
    this.toggleLoading('loading')
    const { settingId } = this.props.match.params
    const result = await api.getSetting(settingId)
    this.toggleLoading('loading')
    if (result.success) {
      this.setState({ setting: result.data.setting })
    }
  }

  componentDidMount () {
    this.getSettingData()
  }  

  updateSetting = async ({ value, enabled }) => {
    this.toggleLoading('saveLoading')
    const { settingId } = this.props.match.params
    const result = await api.updateSetting({ id: settingId, value, enabled })
    if (result.success) {
      // This doesn't really serve any purpose updating the name
      //this.setState({ device: { ...this.state.device, name } })
    } else {
      this.setState({ errors: result.data || ['We had a problem saving this setting'] })
    }
    this.toggleLoading('saveLoading')
  }

  toggleEnabled () {
    const tempSetting = this.state.setting;
    tempSetting.enabled = !tempSetting.enabled;
    this.setState({ setting: tempSetting });
  }

  toggleValue () {
    const tempSetting = this.state.setting;
    tempSetting.value = !tempSetting.value;
    this.setState({ setting: tempSetting });
  }

  render () {
    const { errors, setting, saveLoading, loading } = this.state

    let value;
    
    if(setting.type === "System.Boolean")
    {
      if (setting.value === true)
        value = <OtrCheckbox title='Value' name='value' defaultChecked={true} />
      else
        value = <OtrCheckbox title='Value' name='value' defaultChecked={false} />
    }
    else if (setting.type === "System.Single" || setting.type === "System.Double")
    {
        value = <OtrTextInput title='Value' name='value' value={setting.value} validations='isFloat' validationErrors={{ isFloat: 'Value is not a valid Float or Double' }} />
    }
    else if (setting.type === "System.Int32")
    {
        value = <OtrTextInput title='Value' name='value' value={setting.value} validations='isInteger' validationErrors={{ isInteger: 'Value is not a valid Integer' }} />
    }
    else
    {
        value = <OtrTextInput title='Value' name='value' value={setting.value} />
    }

    return (
      <React.Fragment>
        <ScreenHeader
          title={setting.name}
          backText='SETTINGS'
          backLocation='/settings/'
        />
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <Grid>
          <GridRow>
            <GridColumn width={16}>
              <Segment>
                {loading && (<OtrLoadingSpinner shown name='Setting Information' />)}
                {!loading && (
                  <Form onValidSubmit={this.updateSetting}>
                    <Grid>
                      {/*<OtrTextInput title='ID' name='id' value={device.id} disabled />*/}
                      <OtrTextInput title='Name' name='name' value={setting.name} disabled={true} />
                      <OtrTextInput title='Description' required={false} name='description' value={setting.description} disabled={true} />
                      {value}
                      <OtrCheckbox title='Enabled' name='enabled' defaultChecked={!setting.enabled} />
                      <GridRow> 
                        <GridColumn>
                          <Button loading={saveLoading} primary type='submit'>SAVE</Button>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </Form>
                )}
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
        </React.Fragment>
    )
  }
}
