import React, { Component } from 'react'
import _ from 'lodash'
import { Grid } from 'semantic-ui-react'
import Menu from './menu'
import api from '../classes/api'
import '../styles/frame.css'

export default class Frame extends Component {
  state = {
	filters: [],
  environments: [],
  devices: []
  }

  async syncFilters () {
    const result = await api.getFilters()
    if (result.success) {
      this.setState({ filters: result.data })
    }
  }

  // This is really Gammy. This will be accessed multiple times as Im not going to go through and change it to use the device list from here over everything else already implemented
  async syncDevices () {
    const result = await api.allOtrs()
    if (result.success)
    {
      const devices = result.data.map(d => ({id: d.id, name: d.name, rigName: d.rigName}));
      this.setState({devices});
    }
  }

  async syncEnvironments () {
    const result = await api.allEnvironments()
    if (result.success) {
		let data = result.data
		data.sort((a,b) => a.name.localeCompare(b.name))
      this.setState({ environments: data })
    }
  }

  componentDidMount () {
	this.syncFilters()
  this.syncEnvironments()
  this.syncDevices();
  }

  componentDidUpdate (prevProps) {
    const prevStateVal = _.get(prevProps, 'location.state[0]', '')
    const stateVal = _.get(this.props, 'location.state[0]', '')
    if (prevStateVal !== stateVal) {
      if (stateVal === 'refreshFilterAdd' || stateVal === 'refreshFilterDel') {
        this.syncFilters()
      }
    }
  }
  render () {
    const { children } = this.props
    return (
      <Grid id='frame'>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem' }}>
          {children}
        </Grid.Column>
      </Grid>
    )
  }
}
