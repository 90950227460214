import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import OtrModal from '../../../components/otrModal'
import { Form } from 'formsy-semantic-ui-react'
import api from '../../../classes/api'
import OtrTextInput from '../../../components/otrTextInput'
import OtrDropdown from '../../../components/otrDropdown'

export default class AddFilterModal extends Component {
  state = {
    modalOpen: false,
    jobRoles: [],
    groups: [],
    devices: [],
    selectedJobRoles: [],
    selectedGroups: [],
    selectedDevices: []
  }

  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

  validSubmit = (data) => {
    if (data.groupIds === '') {
      data.groupIds = []
    }

    if (data.jobRoleIds === '') {
      data.jobRoleIds = []
    }

    if (data.deviceIds === '') {
      data.deviceIds = []
    }

    this.handleClose()
    this.props.validSubmit(data)
  }

  async getJobRoles () {
    const result = await api.allJobRoles()
    if (result.success) {
      const jobRoles = result.data.map(d => ({ text: d.name, value: d.id }))
      this.setState({ jobRoles })
    }
  }

  async getGroups () {
    const result = await api.allGroups()
    if (result.success) {
      const groups = result.data.map(d => ({ text: d.name, value: d.id }))
      this.setState({ groups })
    }
  }

  async getDevices() {
    const result = await api.allOtrs()
    if (result.success) {
      const devices = result.data.map(d => ({text: d.name, value: d.id}))
      this.setState({devices})
    }
  }

  componentDidMount () {
    this.getJobRoles()
    this.getGroups()
  }

  jobRolesChange = (evt, ele) => {
    this.setState({ selectedJobRoles: ele.value })
  }

  groupsChange = (evt, ele) => {
    this.setState({ selectedGroups: ele.value })
  }

  deviceChange = (evt, ele) => {
    this.setState({ selectedDevices: ele.value })
  }

  render () {
    const { groups, jobRoles, devices, selectedJobRoles, selectedGroups, selectedDevices } = this.state
    const isDropdownRequired = (selectedJobRoles.length + selectedGroups.length + selectedDevices.length === 0)
    return (
      <OtrModal
        title='ADD FILTER'
        trigger={<Button primary onClick={this.handleOpen}>ADD FILTER</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Form onValidSubmit={this.validSubmit}>
          <Grid>
            <OtrTextInput title='Filter Name' name='name' />
            <OtrDropdown title='Job Roles' name='jobRoleIds' options={jobRoles} multiple required={isDropdownRequired} onChange={this.jobRolesChange} />
            <OtrDropdown title='Groups' name='groupIds' options={groups} multiple required={isDropdownRequired} onChange={this.groupsChange} />
            <OtrDropdown title='Devices' name='deviceIds' options={devices} multiple required={isDropdownRequired} onChange={this.deviceChange} />
            <Grid.Row style={{ textAlign: 'right', paddingTop: '1.5rem' }}>
              <Grid.Column>
                <Button primary style={{ margin: 0 }}>
                  SAVE
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </OtrModal>
    )
  }
}
