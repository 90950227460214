import React, { Component } from 'react'
import { Label, Grid, Button, Input, Segment } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import OtrModal from '../../../components/otrModal'
import OtrTextInput from '../../../components/otrTextInput'
import { downloadCSV } from '../../../classes/data'
import ExportButton from '../../../components/exportButton'
import OtrLoadingSpinner from '../../../components/otrLoadingSpinner'
import Papa from 'papaparse'
import api from '../../../classes/api'
import OtrTable from '../../../components/otrTable'
import { async } from 'q'

export default class ImportUsersModal extends Component {
  state = 
  {
    modalOpen: false,
    errors: [],
    selectedFile: null,
    loadedFile: null,
    loading: false,
    importData: null,
    messages: null,
    displayedMessages: [],
    pageSize: 8,
    pageOffset: 0,
    search: '',
  }

  fileInputRef = React.createRef();

  handleOpen = () => {
    this.setState({ modalOpen: true, messages: null, selectedFile: null }) 
  }
  handleClose = () => this.setState({ modalOpen: false })

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  download = async () => {
    const userData = [{UserName: "", Email: "",	Password: "", FirstName: "", LastName: "", JobRole: "", Group: "",
                       CustomDataValue001: "", CustomDataValue002: "", CustomDataValue003: "", CustomDataValue004: "", CustomDataValue005: "",	}]
    downloadCSV(userData, 'otr-users')
  }

  setFile = event => {
    this.setState({selectedFile: event.target.files[0]})
  }
  
  upload = async () => {
    const { selectedFile } = this.state;
    if (selectedFile == null)
    {
      return
    }
    this.toggleLoading()
    this.state.loading = true;
    Papa.parse(selectedFile, {complete: this.updateData, header: true });
  }

  updateData = async (result) =>
  {
    this.loadedFile = result.data
    const processedData = this.loadedFile.map(c => {
      const keyValues = Object.keys(c).map(key => {
        const newKey = key.replace(/ /g, "")
        return { [newKey]: c[key] }
      })
      return Object.assign({}, ...keyValues)
    })
    const importData = {Users: processedData}
    this.importData = importData;
    const apiResult = await api.importUsers(this.importData)
    if ( apiResult.success )
    {
      const messageTextArray = apiResult.data.messages.map( m => ({message: m}))
      this.setState({messages: messageTextArray})
      this.setDisplayedMessage(0, this.state.pageSize)
    }
    this.toggleLoading()
  }

  setDisplayedMessage = (newOffset, pageSize) => {
    this.setState({displayedMessages: this.state.messages.slice(newOffset, Math.min(this.state.messages.length, newOffset+pageSize))})
  }

  paginationPressed = (pageOffset) => {
    this.setState({ pageOffset })
    this.setDisplayedMessage(pageOffset, this.state.pageSize)
  }

  render () {
    const { search, loading, displayedMessages, messages, pageSize, pageOffset, errors, selectedFile} = this.state
    const actions = (
      <React.Fragment>
          <ExportButton onClick={this.download} label=' Download Template' float='left'/>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <OtrModal
          title='IMPORT USERS'
          trigger={<Button primary onClick={this.handleOpen}>IMPORT</Button>}
          open={this.state.modalOpen}
          onClose={this.handleClose}
          actions={actions}
        >
            <Grid>
              <Grid.Row>
                <Segment>
                  <Button content="CHOOSE IMPORT FILE" labelPosition="left" icon="file" onClick={() => this.fileInputRef.current.click()} />
                  {selectedFile != null && <Label horizontal color='green' style={{ fontSize: '1.2rem' }}>{selectedFile.name}</Label>}
                  {selectedFile == null && <Label horizontal color='red' style={{ fontSize: '1.2rem' }}>No File Selected</Label>}
                </Segment>
                <input ref={this.fileInputRef} hidden={true} type="file" name="file" onChange={this.setFile} />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Button primary onClick={this.upload} float='right'>IMPORT</Button>
                </Grid.Column>
              </Grid.Row>
            {messages && 
              <React.Fragment>
              <Grid.Row>
                <OtrTable 
                  search=''
                  data={displayedMessages}
                  loading={loading}
                  totalRows={messages.length}
                  paginationPressed={this.paginationPressed}
                  name= "Import Messages"
                  pageSize={pageSize}
                  pageOffset={pageOffset}
                  clientPaging={false}/> 
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Button primary onClick={this.handleClose} float='right'>DONE</Button>
                </Grid.Column>
              </Grid.Row>
              </React.Fragment>}
            </Grid>
        </OtrModal>
      </React.Fragment>
    )
  }
}
