import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import OtrModal from '../../../components/otrModal'
import OtrTextInput from '../../../components/otrTextInput'

export default class ResetPasswordModal extends Component {
  state = { modalOpen: false }
  handleOpen = () => {
    //console.log("ResetPasswordModal handleOpen")
    this.setState({ modalOpen: true })
  }
  handleClose = () => {
    //console.log("ResetPasswordModal handleClose")
    this.setState({ modalOpen: false })
  }

  validSubmit = (data) => {
    //console.log("ResetPasswordModal validSubmit")
    this.handleClose()
    this.props.validSubmit(data)
  }

  render () {
    return (
      <OtrModal
        title='RESET PASSWORD'
        trigger={<Button negative onClick={this.handleOpen}>RESET</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}        
      >
        <Form onValidSubmit={this.validSubmit}>
          <Grid>
            <OtrTextInput
              title='Password'
              name='password'
              validations={{ minLength: 8, matchRegexp: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/ }}
              validationErrors={{
                minLength: 'Password should be longer than 8 characters',
                matchRegexp: 'Password should include at least one number, uppercase character and special character'
              }}
              titleWidth={4}
              type='password'
            >
            </OtrTextInput>
            <OtrTextInput
              title='Confirm Password'
              name='passwordConfirm'
              validations={{ equalsField: 'password' }}
              validationErrors={{
                equalsField: 'The password should be the same in both fields'
              }}
              titleWidth={4}
              type='password'
            >
            </OtrTextInput>
            <Grid.Row style={{ textAlign: 'right', paddingTop: '1.5rem' }}>
              <Grid.Column>
                <Button primary style={{ margin: 0 }}>
                  RESET
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </OtrModal>
    )
  }
}
