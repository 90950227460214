const checkArray = ['UserManagement', 'ViewReports', 'Student']
const highestUserLevel = (userLevels) => {
    if (Array.isArray(userLevels))
    {
      for (let i = 0; i < checkArray.length; i += 1) 
      {
        if ( userLevels.indexOf(checkArray[i]) != -1 )
        {
          return checkArray[i]
        }
      }
    }
    return userLevels
}

// Changing the above breaks everything else. Not going to go through and sift through this at the moment when this is limited functionality for specific bits
const adminArray = ['DeviceAdministrator', 'UserManagement']
const highestAdminLevel = (userLevels) => {
  if (Array.isArray(userLevels))
  {
    for (let i = 0; i < adminArray.length; i += 1) 
    {
      if ( userLevels.indexOf(adminArray[i]) != -1 )
      {
        return adminArray[i]
      }
    }
  }
  return userLevels
}

const userType = {
    highestUserLevel: (userLevels) => highestUserLevel(userLevels),
    highestAdminLevel: (userLevels) => highestAdminLevel(userLevels),
}
export default userType