import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import moment from 'moment'
import OtrTable from './otrTable'
import OtrSegmentHeader from './otrSegmentHeader'
import OtrTextInput from './otrTextInput'
import OtrDropdown from './otrDropdown'
import ResetPasswordModal from '../screens/user/components/resetPasswordModal'
import OtrLoadingSpinner from './otrLoadingSpinner'
import OtrModal from './otrModal'
import OtrCheckbox from './otrCheckbox'
import api from '../classes/api'
import exerciseResult from '../classes/exerciseResult'
import { downloadCSV } from '../classes/data'
import ExportButton from './exportButton'
import AddUserModal from '../screens/device/components/addUserModal'
import ScreenHeader from './screenHeader'
import OtrTextArea from './otrTextArea'
import ScreenSubHeader from './screenSubHeader'

export default class DevicesUpdateForm extends Component {
  state = {
    errors: [],
    loading: false,
    saveLoading: false,
    usersLoading: false,
    environmentLoading: false,
    device: {
      id: '',
      name: '',
      notes: '',
      latitude: 0,
      longitude: 0,
      rigName: '',
      environments: [],
      deviceEnabled: false,
      currentRig: {
        id: '',
        name: ''
      }
    },
    // New Components to add Users to Devices
    // Don't want all users, just device users
    allUsers: [],
    allEnvironments: [],
    allRigs: [],
    usersSearch: '',
    users: {
      pageSize: 15,
      total: 0,
      data: []
    }
  }

  toggleLoading (key) {
    const loading = this.state[key]
    this.setState({ [key]: (!loading) })
  }

  async getDeviceData () {
    this.toggleLoading('loading')
    const deviceId = this.props.device.id
    const result = await api.otrById(deviceId)
    this.toggleLoading('loading')
    if (result.success) {
      this.setState({ device: result.data, isCheckboxClicked: result.data.deviceEnabled })
    }
  }

  async getEnvironmentData () {
    this.toggleLoading('environmentLoading')
    const result = await api.allEnvironments()
    this.toggleLoading('environmentLoading')
    if (result.success) {
      let allEnvironments = []

      result.data.forEach(environment => {
        allEnvironments.push(environment)
      })
      this.setState({ allEnvironments })
    }
  }

  async getRigsData () {
    this.toggleLoading('rigsLoading')
    const result = await api.getAllRigs()
    this.toggleLoading('rigsLoading')
    if (result.success) {
      let allRigs = []
      result.data.forEach(rig => {
        allRigs.push(rig)
      })
      this.setState({ allRigs })
    }
  }

  async getDeviceUsers () {
    this.toggleLoading('usersLoading')
    const deviceId = this.props.device.id
    const result = await api.allUsers()
    this.toggleLoading('usersLoading')
    if (result.success) {
      this.setState({ allUsers: result.data.data })

      // Filters out the Device Users
      let deviceUsers = this.state.allUsers.filter(x => x.authorizedDevices.some(y => y.id == deviceId))
      let storedDeviceUsers =
        {
          pageSize: 15,
          total: deviceUsers.length,
          data: deviceUsers
        }

      this.setState({ users: storedDeviceUsers })
    }
  }

  componentDidMount () {
    this.getDeviceData()
    this.getEnvironmentData()
    this.getRigsData()
    this.getDeviceUsers()
  }

  updateDevice = async ({ name, rigName, latitude, longitude, notes, deviceEnabled }) => {
    this.toggleLoading('saveLoading')
    const deviceId = this.props.device.id
    const result = await api.updateOtr({ id: deviceId, name, rigName, latitude, longitude, notes, deviceEnabled })
    if (result.success) {
      this.setState({ device: { ...this.state.device, name } })
      this.props.refreshData()
      this.props.closePopUp()
    } else {
      this.setState({ errors: result.data || ['We had a problem saving this device'] })
    }
    this.toggleLoading('saveLoading')
  }

  // New Bits for the User Aspect
  searchUpdated = (event, data) => {
    this.setState({ usersSearch: data.value })
  }

  // May not be used, keep that in mind and can remove.
  async getUsers () {
    const deviceId = this.props.device.id
    const result = await api.devicesGetUsers(deviceId)
    if (result.success) {
      this.setState({ deviceUsers: result.data.users })
    }
  }

  removeClicked = async (user) => {
    const deviceId = this.props.device.id
    await api.devicesRemoveUser({ userId: user.id, otrUnitId: deviceId })
    this.getDeviceUsers()
  }

  addUser = async ({ user }) => {
    const deviceId = this.props.device.id
    await api.devicesAddUser({ userId: user, otrUnitId: deviceId })
    this.getDeviceUsers()
  }

  showUserRemoveConfirm = () => {
    this.setState({ userRemoveOpen: true })
  }

  hideUserRemoveConfirm = () => {
    this.setState({ userRemoveOpen: false })
  }

  userRemoveClicked = (employeeId) => {
    this.showUserRemoveConfirm()
  }

  removeUser = () => {
    this.hideUserRemoveConfirm()
  }

  download = () => {
    const { device } = this.state
    let deviceName = 'otr-' + device.name + '-users'
    downloadCSV(this.state.users.data, JSON.stringify(deviceName))
  }

  environmentChange = async (event, data) => {
    const { value } = data
    const { device } = this.state
    const deviceEnvironments = device.environments.map(u => u.id)
    const removals = _.difference(deviceEnvironments, value)
    const additions = _.difference(value, deviceEnvironments)
    for (let i = 0; i < removals.length; i += 1) {
      let result = await api.devicesRemoveEnvironment({ otrUnitId: device.id, environmentId: removals[i] })
      if (result.success) {
        const newEnvironments = value.map(v => ({ id: v }))
        this.setState({ device: { ...device, environments: newEnvironments } })
      }
    }

    for (let i = 0; i < additions.length; i += 1) {
      let result = await api.devicesAddEnvironment({ otrUnitId: device.id, environmentId: additions[i] })
      if (result.success) {
        const newEnvironments = value.map(v => ({ id: v }))
        this.setState({ device: { ...device, environments: newEnvironments } })
      }
    }
    this.props.refreshData()
  }

  onRigChange = async (event, data) => {
    const { value } = data
    if (this.state.device.currentRig && this.state.device.currentRig.id !== value) {
      if (value === '') {
        api.rigRemoveOtr({ rigId: this.state.device.currentRig.id, otrUnitId: this.state.device.id }).then((response) => {
          this.setState({ device: { ...this.state.device, currentRig: null } })
          this.props.refreshData()
          this.getDeviceData()
        })
      } else {
        api.rigRemoveOtr({ rigId: this.state.device.currentRig.id, otrUnitId: this.state.device.id }).then((response) => {
          if (response.success) {
            api.rigAddOtr({ rigId: value, otrUnitId: this.state.device.id }).then((response) => {
              this.setState({ device: { ...this.state.device, currentRig: { id: value } } })
              this.props.refreshData()
              this.getDeviceData()
            })
          }
        })
      }
    } else if (value !== '') {
      api.rigAddOtr({ rigId: value, otrUnitId: this.state.device.id }).then((response) => {
        this.setState({ device: { ...this.state.device, currentRig: { id: value } } })
        this.props.refreshData()
        this.getDeviceData()
      })
    }
  }

  // Existing
  renderLatitudeAndLongitude=() => {
    let latitude = this.state.device.latitude
    let longitude = this.state.device.longitude
    let disabled = false
    if (this.state.device.currentRig) {
      latitude = this.state.device.currentRig.latitude
      longitude = this.state.device.currentRig.longitude
      disabled = true
    }
    return (<>
      <OtrTextInput title='Latitude' name='latitude' value={latitude} disabled={disabled} validations='isLatitude' validationErrors={{ isLatitude: 'Latitude is not valid' }} />
      <OtrTextInput title='Longitude' name='longitude' value={longitude} disabled={disabled} validations='isLongitude' validationErrors={{ isLongitude: 'Longitude is not valid' }} />
    </>)
  }

  render () {
    const { errors, device, saveLoading, loading, usersSearch, allUsers, users, allEnvironments, allRigs } = this.state

    let avaliableUsers = allUsers.filter(x => !users.data.includes(x))
    // const environments = device.environments.map(u => {const container = {}; container.text = u.name; container.value = u.id; return container;})

    // Weird format returned from the Backend - Need to convert it... Because changing it breaks the other bits
    // HOUSE OF CARDS!
    const environments = this.state.device.environments.map(u => u.id)
    let rig = ''
    if (this.state.device.currentRig) {
      rig = this.state.device.currentRig.id
    }

    const allEnvs = allEnvironments.map(env => {
      const container = {}
      container.text = env.name
      container.value = env.environmentId
      return container
    })
    let allRigsOptions = [{ text: 'None', value: '' }]
    let optionlist = allRigs.map(rig => {
      const container = {}
      container.text = rig.name
      container.value = rig.id
      return container
    })
    allRigsOptions = allRigsOptions.concat(optionlist)

    const actions = (
      <React.Fragment>
        <ExportButton onClick={this.download} />
        <AddUserModal users={avaliableUsers} validSubmit={this.addUser} />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <Grid>
          <GridRow>
            <GridColumn width={16}>
              <Segment>
                {loading && (<OtrLoadingSpinner shown name='Device Information' />)}
                {!loading && (
                  <Form onValidSubmit={this.updateDevice}>
                    <Grid>
                      {/* <OtrTextInput title='ID' name='id' value={device.id} disabled /> */}
                      <OtrTextInput title='Name' name='name' value={device.name} />
                      <OtrDropdown title='Rig' name='rigs' options={allRigsOptions} required={false} value={rig} onChange={this.onRigChange} />
                      {this.renderLatitudeAndLongitude()}
                      <OtrTextArea title='Notes' required={false} name='notes' value={device.notes} />
                      <OtrDropdown title='Environments' name='environments' options={allEnvs} multiple value={environments} onChange={this.environmentChange} />
                      <OtrCheckbox title='Device Enabled' name='deviceEnabled' value={device.deviceEnabled} />
                      <GridRow>
                        <GridColumn>
                          <Button loading={saveLoading} primary type='submit'>SAVE</Button>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </Form>
                )}
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
        <ScreenSubHeader
          title='DEVICE USERS'
          titleColor='rgb(10, 55, 157)'
          search={usersSearch}
          searchUpdated={this.searchUpdated}
          actions={actions}
        />
        <OtrTable
          data={users.data}
          name='Users'
          loading={saveLoading}
          search={usersSearch}
          hideColumns={['ID', 'EMAIL ADDRESS', 'ACCOUNT ENABLED', 'ROLES', 'AUTHORIZED DEVICES', 'RIGS', 'ENVIRONMENTS',
            'CUSTOM DATA NAME 001', 'CUSTOM DATA VALUE 001',
            'CUSTOM DATA NAME 002', 'CUSTOM DATA VALUE 002',
            'CUSTOM DATA NAME 003', 'CUSTOM DATA VALUE 003',
            'CUSTOM DATA NAME 004', 'CUSTOM DATA VALUE 004',
            'CUSTOM DATA NAME 005', 'CUSTOM DATA VALUE 005']}
          filteredColumns={['groups', 'jobRoles']}
          rowRemove={this.removeClicked}
          totalRows={users.total}
          pageSize={users.pageSize}
        />
      </React.Fragment>
    )
  }
}
