import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class OtrTextInput extends Component {
  render () {
    const { title, name, disabled = false, value = '', validations, validationErrors, required = true, size = 'medium', placeholder = '', type = 'text', titleWidth = 3 } = this.props
    return (
      <Grid.Row>
        <Grid.Column width={titleWidth} style={{ display: 'flex', alignItems: 'center' }}>
          <label>{title}</label>
        </Grid.Column>
        <Grid.Column width={16 - titleWidth} style={{ display: 'block', alignItems: 'center' }}>
          <Form.Input
            name={name}
            placeholder={placeholder}
            fluid
            size={size}
            value={value}
            required={required}
            disabled={disabled}
            instantValidation
            validations={validations}
            validationErrors={{
              ...validationErrors,
              isDefaultRequiredValue: `${(title || placeholder)} is Required`
            }}
            errorLabel={<div className='errorLabel' icon='close' color='red' />}
            type={type}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
