import React, { Component } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import UserMenuLink from './userMenuLink'

export default class UserMenuItems extends Component {
  render () {
    const { admin } = this.props;
    return (
      <React.Fragment>
        {admin && 
          <Grid.Row style={{ border: '1px solid white' }}>
            <UserMenuLink to='/export/' exact={false} title='Export'>
              <Icon name='external' size={'large'} />
            </UserMenuLink>
          </Grid.Row>
        }        
        <Grid.Row style={{ border: '1px solid white' }}>
          <UserMenuLink to='/userSettings/' exact={false} title='Settings'>
            <Icon name='setting' size={'large'} />
          </UserMenuLink>
        </Grid.Row>
        <Grid.Row style={{ border: '1px solid white' }}>
          <UserMenuLink to='/logout/' exact={false} title='Logout'>
            <Icon name='user times' size={'large'} />
          </UserMenuLink>
        </Grid.Row>
      </React.Fragment>
    )
  }
}
