import React, { Component } from 'react'
import { Icon, Segment } from 'semantic-ui-react'
import '../styles/reportIcon.css'
import squareIcon from '../img/square.svg'
import ExportButton from './exportButton'
import api from '../classes/api'
import { serializeToJson, serializeToJsonWithDeep } from '../classes/jsonSerialize'
import exportToXLSX from '../classes/exportToXLSX'
import * as XLSX from 'xlsx-js-style'
import exportToXLSXWithTable from '../classes/exportToXLSXWithTable'

export default class ReportIcon extends Component {
  constructor (props) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }
  async handleOnClick (e) {
    e.stopPropagation()
    const result = await api.reportData(this.props.id)
    let rowDp = result.data.data.rows.map((array) => {
      return [...array]
    })
    let headerDp = [...result.data.data.columns]
    //console.log(result.data.data)

    if (result.data.name === 'Remedial Report') {
      serializeToJson(headerDp, result.data.data.rows)
      await exportToXLSX('/reports/RemedialReportV3/RemedialReportV4.zip', result.data.data, 'RemedialReport.xlsx')
    } else if (result.data.name === 'Weekly Report') {
      serializeToJson(headerDp, result.data.data.rows)
      await exportToXLSX('/reports/RemedialReportV3/RemedialReportV4.zip', result.data.data, 'WeeklyReport.xlsx')
    } else if (result.data.name === 'Regional Student List') {
      serializeToJsonWithDeep(headerDp, result.data.data.rows, result.data.reportDepth)
      await exportToXLSXWithTable('/reports/RegionalStudentList/RegionalStudentListV11.zip', result.data.data, 'RegionalStudentList.xlsx')
    } else if (result.data.name === 'Exercise Feedback') {
      serializeToJsonWithDeep(headerDp, result.data.data.rows, result.data.reportDepth)
      await exportToXLSXWithTable('/reports/ExerciseFeedback/ExerciseFeedbackV11.zip', result.data.data, 'ExerciseFeedback.xlsx')
    } else if (result.data.name === 'Student List') {
      serializeToJsonWithDeep(headerDp, result.data.data.rows, result.data.reportDepth)
      await exportToXLSXWithTable('/reports/studentList/studentListTest.zip', result.data.data, 'studentList.xlsx')
    } else if (result.data.name === 'Rig Competency Report') {
      serializeToJsonWithDeep(headerDp, result.data.data.rows, result.data.reportDepth)
      await exportToXLSX('/reports/RigCompetencyReportV3/RigCompetencyReportV3.zip', result.data.data, 'RigCompetencyReport.xlsx')
    } else if (result.data.name === 'Student Competency Report') {
      serializeToJsonWithDeep(headerDp, result.data.data.rows, result.data.reportDepth)
      await exportToXLSX('/reports/StudentCompetencyReportV3/StudentCompetencyReportV3.zip', result.data.data, 'StudentCompetencyReport.xlsx')
    } else {
      serializeToJsonWithDeep(headerDp, result.data.data.rows, result.data.reportDepth)
      const processedData = result.data.data.rows.map((rowData) => {
        const row = {}
        result.data.data.columns.forEach((column, idx) => {
          row[column] = rowData[idx]
        })
        return row
      })
      // STEP 1: Create a new workbook
      const wb = XLSX.utils.book_new()
      // STEP 3: Create worksheet with rows; Add worksheet to workbook
      const ws = XLSX.utils.json_to_sheet(processedData)
      XLSX.utils.book_append_sheet(wb, ws, result.data.name)

      XLSX.writeFile(wb, `${result.data.name}.xlsx`)
    }
  }
  renderStartIcon (title) {
    if (title === 'Weekly Report' || title === 'Remedial Report' || title === 'Regional Student List') {
      return (<div style={{ float: 'right', paddingTop: '0.3rem' }}> <Icon color={'yellow'} size={'big'} name='star' /></div>)
    }
  }
  render () {
    const { id, color, title, subtitle, selected, onClick } = this.props

    const iconElement = (
      <div style={{ position: 'absolute', top: '0.2rem', right: 0 }}>
        <Icon name='check circle' />
      </div>
    )

    return (
      <Segment onClick={() => { onClick(id, title) }} className='reportIcon' inverted style={{ backgroundColor: 'rgba(255,255,255, 1)' }}>
        { selected && iconElement }
        <div>
          <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', color: 'gray' }}>
            <h4 className='uppercase' style={{ margin: 0, alignSelf: 'center', display: 'inline', width: '24%', fontSize: '1.5rem' }}>{title}</h4>
            <p style={{ paddingLeft: '1rem', flex: 1, height: '100%', fontSize: '1.2rem', margin: '0px', paddingTop: '0.5rem', fontWeight: '800', color: 'gray' }}>{subtitle}</p>
            {this.renderStartIcon(title)}
            <div style={{ float: 'right' }}> <ExportButton onClick={this.handleOnClick} /></div>
          </div>
        </div>
      </Segment>
    )
  }
}
