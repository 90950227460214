import React, { Component } from 'react'
import {
  Grid,
  GridRow,
  GridColumn,
  Icon
} from 'semantic-ui-react'
import SummaryItem from './summaryItem'
import { withRouter } from 'react-router-dom'
import FleetCompetency from './fleetCompetency'
import FleetCompliance from './fleetCompliance'
import { addPageFilter, resetPageFilter } from '../../../redux/actions'
import { connect } from 'react-redux'

class SummaryRow extends Component {
    state = {
      activeUsers: 0,
      registered: 0,
      fleetCompetency: 0,
      fleetCompliance: 0
    }
    constructor (props) {
      super(props)
      this.handleOnClickLocations = this.handleOnClickLocations.bind(this)
      this.handleOnClickDevices = this.handleOnClickDevices.bind(this)
      this.handleOnClickActiveUsers = this.handleOnClickActiveUsers.bind(this)
      this.handleOnClickRegistered = this.handleOnClickRegistered.bind(this)
    }
    handleOnClickLocations () {
      this.props.history.push('/rigs/')
    }
    handleOnClickDevices () {
      this.props.history.push('/devices/')
    }
    handleOnClickActiveUsers () {
      // MISSING FILTER FOR ONLY ACTIVE USERS
      this.props.history.push('/users/')
    }
    handleOnClickRegistered () {
      this.props.history.push('/users/')
    }
    render () {
      const { otrs, locations, userStats } = this.props;
      const otrCount = otrs ? otrs.length : 0;
      const locationsCount = locations ? locations.length : 0;
      const activeCount = userStats ? userStats.activeUsers : 0;
      const registeredCount = userStats ? userStats.registeredUsers : 0;
      const totalCompetency = userStats ? userStats.averageCompetency * 100 : 0;
      const totalCompliance = userStats ? userStats.averageCompliance * 100 : 0;
      return (
        <React.Fragment>
          <GridRow style={{ marginBottom: '25px' }}>
            <Grid>
              <GridRow columns={7} style={{ paddingBottom: 0 }}>
                <GridColumn GridColumn={1} style={{ paddingRight: 0 }}>
                  <SummaryItem icon={<Icon name='globe' />} title='Locations' number={locationsCount} onClick={this.handleOnClickLocations}  />
                </GridColumn>
                <GridColumn GridColumn={1} style={{ paddingRight: 0 }}>
                  <SummaryItem icon={<Icon name='tablet' />} title='Devices' number={otrCount} onClick={this.handleOnClickDevices} />
                </GridColumn>
                <GridColumn GridColumn={1} style={{ paddingRight: 0 }}>
                  <SummaryItem icon={<Icon name='user' />} title='Active Users' number={activeCount} onClick={this.handleOnClickActiveUsers} />
                </GridColumn>
                <GridColumn GridColumn={1}>
                  <SummaryItem icon={
                    <Icon.Group >
                      <Icon name='file' />
                      <Icon color='black' size='tiny' name='check' />
                    </Icon.Group>
                  } title='Registered' number={registeredCount} onClick={this.handleOnClickRegistered} />
                </GridColumn>
                <div style={{ width: '21.4%' , paddingRight: 15}} >
                  <FleetCompetency percentage={totalCompetency} />
                </div>
                <div style={{ width: '21.4%' }} >
                  <FleetCompliance value={totalCompliance} />
                </div>
              </GridRow>
            </Grid>
          </GridRow>
        </React.Fragment>
      )
    }
}
const mapDispatchToProps = {
  resetPageFilter,
  addPageFilter
}
export default connect(null, mapDispatchToProps)(withRouter(SummaryRow))
