import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import ScreenHeader from '../../components/screenHeader'
import api from '../../classes/api'
import OtrTextInput from '../../components/otrTextInput'
import OtrDropdown from '../../components/otrDropdown'
import OtrLoadingSpinner from '../../components/otrLoadingSpinner'
import OtrModal from '../../components/otrModal'
import OtrSegmentHeader from '../../components/otrSegmentHeader'
import BarChartTable from '../../components/charts/barChartTable'
import OtrTable from '../../components/otrTable'
import exerciseResult from '../../classes/exerciseResult'
import moment from 'moment'
import { async } from 'q'
import OtrCheckbox from '../../components/otrCheckbox'
import ResetPasswordModal from './components/resetPasswordModal'

export default class UserScreen extends Component {
  state = {
    errors: [],
    loading: false,
    saveLoading: false,
    user: {
      id: '',
      firstName: '',
      lastName: '',
      customDataName001: 'Rig',
      customDataValue001: '',
      customDataName002: 'Environment',
      customDataValue002: '',
      customDataName003: 'Region',
      customDataValue003: '',
      customDataName004: 'EmployeeId',
      customDataValue004: '',
      customDataName005: '',
      customDataValue005: '',
      jobRoles: [],
      groups: [],
      roles: [],
      authorizedDevices: [],
      accountEnabled: true
    },
    groups: [],
    jobRoles: [],
    appRoles: [],
    devices: [],
    usage: [],
    totalUsage: 0,
    pageSize: 15,
    pageOffset: 0
  }

  toggleLoading (key) {
    const loading = this.state[key]
    this.setState({ [key]: (!loading) })
  }

  async getUserData () {
    this.toggleLoading('loading')
    const { userId } = this.props.match.params
    const result = await api.userById(userId)
    this.toggleLoading('loading')
    if (result.success) {
      this.setState({ user: result.data })
    }
    this.getStudentUsage(userId)
  }

  async getJobRoles () {
    const result = await api.allJobRoles()
    if (result.success) {
      const jobRoles = result.data.map(d => ({ text: d.name, value: d.id }))
      this.setState({ jobRoles })
    }
  }

  async getAppRoles () {
    const result = await api.getAppRoles()
    if (result.success) {
      const appRoles = result.data.map(d => ({ text: d.name, value: d.id }))
      this.setState({ appRoles })
    }
  }

  async getGroups () {
    const result = await api.allGroups()
    if (result.success) {
      const groups = result.data.map(d => ({ text: d.name, value: d.id }))
      this.setState({ groups })
    }
  }

  async getDevices () {
    const result = await api.allOtrs()
    if (result.success) {
      const devices = result.data.map(d => ({ text: d.name, value: d.id }))
      this.setState({ devices })
    }
  }

  componentDidMount () {
    this.getUserData()
    this.getJobRoles()
    this.getAppRoles()
    this.getGroups()
    this.getDevices()
  }

  updateUser = async ({ firstName, lastName, emailAddress, customDataValue001, customDataValue002, customDataValue003, customDataValue004, customDataValue005, accountEnabled }) => {
    this.toggleLoading('saveLoading')
    const { userId } = this.props.match.params
    let { customDataName001, customDataName002, customDataName003, customDataName004 } = this.state.user
    if (customDataName001 === '') {
      customDataName001 = 'RigName'
    }
    if (customDataName002 === '') {
      customDataName002 = 'Environment'
    }
    if (customDataName003 === '') {
      customDataName003 = 'Region'
    }
    if (customDataName004 === '') {
      customDataName004 = 'EmployeeId'
    }
    const result = await api.updateUser({ id: userId, firstName, lastName, emailAddress, customDataValue001, customDataValue002, customDataValue003, customDataValue004, customDataValue005, accountEnabled, customDataName001, customDataName002, customDataName003, customDataName004 })
    if (result.success) {
      this.setState({ user: { ...this.state.user, firstName, lastName } })
    } else {
      this.setState({ errors: result.data || ['We had a problem saving this user'] })
    }
    this.toggleLoading('saveLoading')
  }

  groupChange = async (event, data) => {
    const { value } = data
    const { user } = this.state
    const userGroups = user.groups.map(u => u.id)

    const removals = _.difference(userGroups, value)
    const additions = _.difference(value, userGroups)

    for (let i = 0; i < removals.length; i += 1) {
      await api.groupRemoveUser({ groupId: removals[i], userId: user.id })
    }

    for (let i = 0; i < additions.length; i += 1) {
      await api.groupAddUser({ groupId: additions[i], userId: user.id })
    }

    const newUserGroups = value.map(v => ({ id: v }))
    this.setState({ user: { ...user, groups: newUserGroups } })
  }

  jobRoleChange = async (event, data) => {
    const { value } = data
    const { user } = this.state
    const userJobRoles = user.jobRoles.map(u => u.id)

    const removals = _.difference(userJobRoles, value)
    const additions = _.difference(value, userJobRoles)

    for (let i = 0; i < removals.length; i += 1) {
      await api.jobRoleRemoveUser({ jobRoleId: removals[i], userId: user.id })
    }

    for (let i = 0; i < additions.length; i += 1) {
      await api.jobRoleAddUser({ jobRoleId: additions[i], userId: user.id })
    }

    const newJobRoles = value.map(v => ({ id: v }))
    this.setState({ user: { ...user, jobRoles: newJobRoles } })
  }

  appRoleChange = async (event, data) => {
    const { value } = data
    const { user } = this.state
    const userAppRoles = user.roles.map(u => u.id)
    const removals = _.difference(userAppRoles, value)
    const additions = _.difference(value, userAppRoles)

    for (let i = 0; i < removals.length; i += 1) {
      for (let j = 0; j < this.state.appRoles.length; j += 1) {
        if (removals[i] == this.state.appRoles[j].value) {
          await api.appRoleRemoveUser({ role: this.state.appRoles[j].text, userId: user.id })
        }
      }
    }

    for (let i = 0; i < additions.length; i += 1) {
      for (let j = 0; j < this.state.appRoles.length; j += 1) {
        if (additions[i] == this.state.appRoles[j].value) {
          await api.appRoleAddUser({ role: this.state.appRoles[j].text, userId: user.id })
        }
      }
    }

    const newAppRoles = value.map(v => ({ id: v }))
    this.setState({ user: { ...user, roles: newAppRoles } })
  }

  deviceChange = async (event, data) => {
    const { value } = data
    const { user } = this.state
    const userDevices = user.authorizedDevices.map(u => u.id)

    const removals = _.difference(userDevices, value)
    const additions = _.difference(value, userDevices)

    for (let i = 0; i < removals.length; i += 1) {
      await api.devicesRemoveUser({ otrUnitId: removals[i], userId: user.id })
    }

    for (let i = 0; i < additions.length; i += 1) {
      await api.devicesAddUser({ otrUnitId: additions[i], userId: user.id })
    }

    const newDevices = value.map(v => ({ id: v }))
    this.setState({ user: { ...user, authorizedDevices: newDevices } })
  }

  getStudentUsage = async (studentId) => {
    const { pageSize, pageOffset } = this.state
    const result = await api.studentUsageLog(studentId, pageSize, pageOffset)
    const { data, total } = result.data
    if (result.success) {
      const usageData = data.map(row => (
        { exercise: row.exercise, result: exerciseResult.toString(row.result), date: moment(row.date).format('DD/MM/YYYY - HH:mm:ss') }
      ))
      this.setState({ usage: usageData, totalUsage: total })
    }
  }

  paginationPressed = (pageOffset) => {
    this.setState({ pageOffset }, () => {
      const { userId } = this.props.match.params
      this.getStudentUsage(userId)
    })
  }

  resetPasswordSubmitted = async (data) => {
    const { password } = data
    const { user } = this.state
    try {
      // get reset token
      let error
      let result = await api.requestPasswordReset({ EmailAddress: user.emailAddress })
      if (!result.success) {
        error = (result.data.length > 0) ? result.data[0] : result.error
        throw Error(result.error)
      }

      // use token to reset password
      const token = result.data.token
      result = await api.resetPassword({ EmailAddress: user.emailAddress, NewPassword: password, Token: token })
      if (!result.success) {
        error = (result.data.length > 0) ? result.data[0] : result.error
        throw Error(error)
      }

      //console.log(result)
    } catch (err) {
      console.log(err)
    }
  }

  // Need to pass in OTR Devices
  render () {
    const { errors, user, saveLoading, groups, jobRoles, appRoles, devices, loading, usage, totalUsage, pageSize, pageOffset } = this.state
    const userJobRoles = user.jobRoles.map(u => u.id)
    const userGroups = user.groups.map(u => u.id)
    const userAppRoles = user.roles.map(u => u.id)

    const userDevices = user.authorizedDevices.map(u => u.id)

    return (
      <React.Fragment>
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <ScreenHeader
          title={`${user.firstName || ''} ${user.lastName || ''}`}
          backText='USERS'
          backLocation='/users/'
        />
        <Grid>
          <GridRow>
            <GridColumn width={16}>
              <Segment>
                {loading && (<OtrLoadingSpinner shown name='User Information' />)}
                {!loading && (
                  <Form onValidSubmit={this.updateUser}>
                    <Grid>
                      {/* <OtrTextInput title='ID' name='id' value={user.id} disabled /> */}
                      <OtrTextInput title='User Name' name='userName' value={user.userName} disabled />
                      <OtrTextInput title='First Name' name='firstName' value={user.firstName} />
                      <OtrTextInput title='Last Name' name='lastName' value={user.lastName} />
                      <OtrTextInput title='Email Address' name='emailAddress' validations='isEmail' validationErrors={{ isEmail: 'Email Address not valid' }} value={user.emailAddress} />
                      <GridRow>
                        <GridColumn width={3} style={{ display: 'flex', alignItems: 'center' }}>
                          <label>Password</label>
                        </GridColumn>
                        <GridColumn>
                          <ResetPasswordModal validSubmit={this.resetPasswordSubmitted} />
                        </GridColumn>
                      </GridRow>
                      <OtrDropdown title='Job Roles' name='jobRoles' options={jobRoles} multiple value={userJobRoles} onChange={this.jobRoleChange} />
                      <OtrDropdown title='Groups' name='groups' options={groups} multiple value={userGroups} onChange={this.groupChange} />
                      <OtrDropdown title='Roles' name='roles' options={appRoles} multiple value={userAppRoles} onChange={this.appRoleChange} />
                      <OtrDropdown title='Authorized Devices' name='devices' options={devices} multiple value={userDevices} onChange={this.deviceChange} />
                      <OtrCheckbox title='Account Enabled' name='accountEnabled' value={user.accountEnabled} />
                      <OtrTextInput required={false} title='Rig' name='customDataValue001' value={user.customDataValue001} />
                      <OtrTextInput required={false} title='Environment' name='customDataValue002' value={user.customDataValue002} />
                      <OtrTextInput required={false} title='Region' name='customDataValue003' value={user.customDataValue003} />
                      <OtrTextInput required={false} title='Employee Id' name='customDataValue004' value={user.customDataValue004} />
                      {
                        // <OtrTextInput required={false} title={user.customDataName005} name='customDataValue005' value={user.customDataValue005} />
                      }
                      <GridRow>
                        <GridColumn>
                          <Button loading={saveLoading} primary type='submit'>SAVE</Button>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </Form>
                )}
              </Segment>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <Segment className='invertedSegment'>
                <OtrSegmentHeader inverted title='USAGE LOG' />
                <OtrTable
                  data={usage}
                  name='Usage'
                  loading={loading}
                  totalRows={totalUsage}
                  pageSize={pageSize}
                  pageOffset={pageOffset}
                  paginationPressed={this.paginationPressed}
                  clientPaging={false}
                  extendedPaging
                />
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
      </React.Fragment>
    )
  }
}
