import React, { Component } from 'react'
import '../styles/menu.css'
import { Grid, Icon } from 'semantic-ui-react'
import AdminMenuItems from './adminMenuItems'
import StudentMenuItems from './studentMenuItems'
import ViewReportsMenuItems from './viewReportsMenuItems'
import auth from '../services/auth'
import userType from '../classes/userType'
import { withRouter } from 'react-router-dom'

class MenuHamburger extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false
    }
    this.handleOnClick = this.handleOnClick.bind(this)
  }
  handleOnClick () {
    this.setState({ showMenu: !this.state.showMenu })
    console.log('click')
  }
  render () {
    const result = auth.getUserLevel()
    const { filters = [], environments = [], devices = [] } = this.props
    let element = null
    if (result.success) {
      let userLevel = userType.highestUserLevel(result.userLevel)
      switch (userLevel) {
        case 'UserManagement':
          element = <AdminMenuItems filters={filters} environments={environments} devices={devices} />
          break
        case 'Student':
          element = <StudentMenuItems />
          break
        case 'ViewReports':
          element = <ViewReportsMenuItems filters={filters} devices={devices} />
          break
        default:
          element = null
      }
    }
    return (
      <div style={{ float: 'right', position: 'relative' }}>
        <Icon inverted size={'huge'} onClick={this.handleOnClick} onBlur={this.handleOnClick} style={{ cursor: 'pointer' }} name='bars' />
        <div style={{ position: 'absolute', zIndex: 50, backgroundColor: '#011c36', display: this.state.showMenu ? 'block' : 'none', left: '-200%', top: '100%' }}>
          {element}
        </div>
      </div>
    )
  }
}
export default withRouter(MenuHamburger)
