import React, { Component } from 'react'
import { Grid, Label } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Form } from 'formsy-semantic-ui-react'
import moment from 'moment'
export default class OtrDatePicker extends Component {
  state = {
    selectedDate: this.props.value,    
    isChecked: this.props.isActive || false
  }
  renderTypesInput = (disabled) => {
    return (
      <Grid.Column width={1} style={{ display: 'flex', alignItems: 'center', paddingLeft:'0rem' }}>
        <Form.Dropdown
          name={`types[${this.props.name}]`}
          selection
          value={this.props.typeOptionsValue}
          disabled={disabled}
          options={[{ text: '>', value: '>' }, { text: '<', value: '<' }, { text: '=', value: '=' }]}
          errorLabel={<Label style={{ marginTop: '0.5rem', border: 0, fontFamily: 'Helvetica', fontWeight: 'normal', fontSize: '16px', padding: 0 }} icon='close' color='red' basic />}
        />
      </Grid.Column>
    )
  }
  setDate=(d) => {
    if (d !== null) {
      let date = moment(d)
      this.setState({ selectedDate: date.format('YYYY-MM-DD') })
    } else {
      this.setState({ selectedDate: '' })
    }
  }
  render () {
    const { title, name, value, disabled = false, isActive = false } = this.props
    const { isChecked } = this.state; 
    const inputDisabled = disabled || !isChecked; 
    
    return (
      <Grid.Row>
        <Grid.Column width={1} style={{ 'alignContent': 'center' }}>
          <Form.Checkbox 
            name={`selected[${name}]`}
            disabled={disabled}
            checked={isChecked}
            defaultChecked={isActive}
            toggle
            onChange={(e, data) => this.setState({ isChecked: !isChecked })}
          />
        </Grid.Column>
        <Form.Input style={{ display: 'none' }} name={`value[${name}]`} type={'text'} value={this.state.selectedDate} />
        <Grid.Column width={4} style={{ alignContent: 'center', paddingInlineStart: '1.5rem' }}>
          <label>{title}</label>
        </Grid.Column>
        {this.renderTypesInput(inputDisabled)}
        <Grid.Column width={9} style={{paddingTop: '0.3rem'}} >
          <div style={{ position: 'absolute' }}>
            <SemanticDatepicker 
              id={name} 
              value={value} 
              selected={value}
              disabled={inputDisabled} 
              format={'DD/MM/YYYY'} 
              onDateChange={this.setDate} 
              name={name} 
              type={'basic'} />
          </div>
        </Grid.Column>
      </Grid.Row>
    )
  }
}
