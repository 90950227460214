import React, { Component } from 'react'
import api from '../../classes/api'
import ScreenHeader from '../../components/screenHeader'
import OtrModal from '../../components/otrModal'
import { sortByPropertyIndex } from '../../classes/tableHelpers'
import OtrTableReleases from '../../components/otrTableReleases'
import moment from 'moment'

export default class Releases extends Component {
  constructor (props) {
    super(props)
    this.handleOnOrderBy = this.handleOnOrderBy.bind(this)
    this.handleClickOnUpdateRelease = this.handleClickOnUpdateRelease.bind(this)
  }
  state = {
    releases: [],
    errors: [],
    totalReleases: 0,
    pageSize: 15,
    pageOffset: 0,
    search: '',
    loading: false,
    orderBy: null,
    saveLoading: false,
    releaseView: null
  }
  searchUpdated = (event, data) => {
    this.setState({ search: data.value }, () => {
      this.getData()
    })
  }
  handleClickOnUpdateRelease = (release, newStatus) => {
    release.status = newStatus
    if (newStatus === 1) {
      release.released = moment().format()
    }
    if (newStatus === 3) {
      release.deprecated = moment().format()
    }
    api.updateRelease(release).then((response) => {
      this.getData()
    })
  }
  handleClickOnViewNote= (release) => {
    this.setState({ releaseView: release })
  }
  handleOnOrderBy (data) {
    let type
    if (this.state.orderBy) {
      if (this.state.orderBy.idx === data) {
        if (this.state.orderBy.type === 'desc') {
          type = 'asc'
          this.setState({ orderBy: { idx: data, type: type } }, () => {
            this.getData()
          })
        } else {
          this.setState({ orderBy: null }, () => {
            this.getData()
          })
        }
      } else {
        this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
          this.getData()
        })
      }
    } else {
      this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
        this.getData()
      })
    }
  }
  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  async getData () {
    this.toggleLoading()
    const { pageSize, pageOffset, search } = this.state
    let result
    if (search.length > 0) {
      result = await api.getAllReleases()
    } else {
      result = await api.getAllReleases()
    }

    this.toggleLoading()
    if (result.success) {
      let data = result.data
      let total = data.length
      if (this.state.orderBy) {
        //data = sortByPropertyIndex(this.state.orderBy.idx, this.state.orderBy.type, data)
      }
      this.setState({ releases: data, totalReleases: total })
    }
  }

  componentDidMount () {
    this.getData()
  }
  paginationPressed = (pageOffset) => {
    this.setState({ pageOffset }, () => {
      this.getData()
    })
  }
  viewReleaseNote (note) {
    return note.split('\\n').map((line) => {
      return <p>
        {line}
      </p>
    })
  }
  render () {
    const { releases, search, loading, totalReleases, pageSize, pageOffset, errors } = this.state

    let actions = (
      <React.Fragment>
        <div style={{ paddingTop: '38px' }} />
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <OtrModal open={this.state.releaseView !== null} title='Notes' onClose={() => { this.setState({ releaseView: null }) }}>
          <p >
            {
              this.state.releaseView && this.viewReleaseNote(this.state.releaseView.notes)
            }
          </p>
        </OtrModal>
        <ScreenHeader
          title='Releases'
          search={search}
          actions={actions}
          searchUpdated={this.searchUpdated}
        />
        <OtrTableReleases
          orderBy={this.state.orderBy}
          data={releases}
          arrayLengthColumns={['CURRENT DEVICES']}
          hideColumns={['ID',
            'CUSTOM DATA NAME 001', 'CUSTOM DATA VALUE 001',
            'CUSTOM DATA NAME 002', 'CUSTOM DATA VALUE 002',
            'CUSTOM DATA NAME 003', 'CUSTOM DATA VALUE 003',
            'CUSTOM DATA NAME 004', 'CUSTOM DATA VALUE 004',
            'CUSTOM DATA NAME 005', 'CUSTOM DATA VALUE 005']}
          name='Releases'
          loading={loading}
          totalRows={totalReleases}
          pageSize={pageSize}
          pageOffset={pageOffset}
          handleClickOnViewNote={this.handleClickOnViewNote}
          handleClickOnUpdateRelease={this.handleClickOnUpdateRelease}
          handleOnOrderBy={this.handleOnOrderBy}
          paginationPressed={this.paginationPressed}
          clientPaging
          search={search}
        />
      </React.Fragment>
    )
  }
}
