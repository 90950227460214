import Fuse from 'fuse.js'

const searchData = (results = [], searchTerm = '') => {
  if (searchTerm.length > 0 && results.length > 0) {
    const keys = Object.keys(results[0])
    const options = {
      shouldSort: true,
      threshold: 0.08,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys
    }
    const fuse = new Fuse(results, options)
    return fuse.search(searchTerm)
  }
  return results
}

export { searchData }
