import React, { Component } from 'react'
import { Grid, Label } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class OtrDropdownFilter extends Component {
  state = {  
    isChecked: this.props.isActive || false
  }
  render () {
    const { title, name, disabled = false, isActive = false, value = '', options = [], multiple = false, required = true, onChange = null } = this.props
    const { isChecked } = this.state;
    const inputDisabled = disabled || !isChecked; 
    return (
      <Grid.Row>
        <Grid.Column width={1} style={{ 'alignContent': 'center' }}>
          <Form.Checkbox 
            name={`selected[${name}]`}
            disabled={disabled}
            checked={isChecked}
            defaultChecked={isActive}
            toggle
            onChange={(e, data) => this.setState({ isChecked: !isChecked })}
          />
        </Grid.Column>
        <Form.Input style={{ display: 'none' }} name={`types[${name}]`} type={'text'} value={'includes'} />
        <Grid.Column width={4} style={{ 'alignContent': 'center', paddingInlineStart: '1.5rem' }}>
          <label>{title}</label>
        </Grid.Column>
        <Grid.Column width={11} style={{ 'alignContent': 'center', paddingLeft: '0rem' }}>
          <Form.Dropdown
            name={`value[${name}]`}
            selection
            search
            multiple={multiple}
            value={value}
            required={required}
            options={options}
            disabled={inputDisabled}
            instantValidation
            validationErrors={{
              isDefaultRequiredValue: `${title} is Required`
            }}
            onChange={onChange}
            errorLabel={<Label style={{ marginTop: '0.5rem', border: 0, fontFamily: 'Helvetica', fontWeight: 'normal', fontSize: '16px', padding: 0 }} icon='close' color='red' basic />}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
