import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class OtrCheckbox extends Component {
  render () {
    const { title, name, disabled = false, value, validations, validationErrors, size = 'medium', placeholder = '', type = 'checkbox', defaultChecked } = this.props
    return (





      <Grid.Row>
        <Grid.Column width={3} style={{ display: 'flex', alignItems: 'center' }}>
          <label>{title}</label>
        </Grid.Column>
        <Grid.Column width={13}>
          <Form.Checkbox
            name={name}
            placeholder={placeholder}
            size={size}
            disabled={disabled}
            instantValidation
            validations={validations}
            validationErrors={{
              ...validationErrors,
              isDefaultRequiredValue: `${(title || placeholder)} is Required`
            }}
            errorLabel={<div className='errorLabel' icon='close' color='red' />}
            type={type}
            value={value}
            defaultChecked={defaultChecked}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
