const getComplianceColor = (value) => {
    if (value < 1) {
        return '#e64435'
    } else if (value < 3) {
        return '#ffcc00'
    } else {
        return '#b1db1d'
    }
}


const ACTIVE_THRESHOLD = 1;
const DEVICE_THRESHOLD = 0;

const getComplianceColorBasedOnActivesAndDevices = (value, Actives = 1, Devices = 1,icon) => {
  if (Actives < ACTIVE_THRESHOLD || Devices < DEVICE_THRESHOLD) {
    if(icon){
      return '#45808080'
    }else{
      return '#808080'
    }
  }
  return getComplianceColor(value)
}
export { getComplianceColor, getComplianceColorBasedOnActivesAndDevices }