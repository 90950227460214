import React, { Component } from 'react'
import { Grid, Form, Label, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import BarChartTable from '../../components/charts/barChartTable'
import OtrSegmentHeader from '../../components/otrSegmentHeader'
import OtrTable from '../../components/otrTable'
import api from '../../classes/api'
import DashboardHeader from '../../components/dashboardHeader'
import exerciseResult from '../../classes/exerciseResult'
import moment from 'moment'

export default class StudentHome extends Component {
  state = {
    usage: [],
    jobRoles: [],
    groups: [],
    user: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      id: '',
      groups: [],
      jobRoles: []
    },
    totalUsage: 0,
    pageSize: 15,
    pageOffset: 0,
    debugData: '',
    showHeader: false
  }

  getStudentUsage = async () => {
    const { pageSize, pageOffset } = this.state
    const result = await api.studentUsage(pageSize, pageOffset)
    if (result.success) {
      const usageData = result.data.data.map(row => (
        { exercise: row.exercise, result: exerciseResult.toString(row.result), date:moment(row.date).format('DD/MM/YYYY - HH:mm:ss') }
      ))
      const { total } = result.data
      this.setState({ usage: usageData, totalUsage: total })
    }
  }

  getStudentCompetencies = async () => {
    const result = await api.currentStudentCompetency()
    if (result.success) {
      const barData = result.data.userCompetency.roleCompetencies.map(row => (
        { key: row.name, value: Math.round(row.competency * 100) }
      ))
      this.setState({ jobRoles: barData})
    }
  }

  getGroupCompetencies = async () => {
    const result = await api.competencyByGroups()
    if (result.success) {
      const barData = result.data.map(row => (
        { key: row.name, value: Math.round(row.competency * 100) }
      ))
      this.setState({ groups: barData })
    }
    }

  getUserData = async () => {
    const result = await api.user()
    if (result.success) {
      if (result.data) {
        this.setState({ user: result.data })
      }
    }
    /* need to do these after the user data has been obtained successfully or not*/
    this.getStudentCompetencies()
    this.getGroupCompetencies()
  }

  componentDidMount () {
    this.getUserData()
    this.getStudentUsage()
    /*this.getJobRoleCompetencies()
    this.getGroupCompetencies() */
  }

  paginationPressed = (pageOffset) => {
    this.setState({ pageOffset }, () => {
      this.getStudentUsage()
    })
  }
  render () {
      const { usage, jobRoles, groups, user, totalUsage, pageSize, pageOffset, jobRoles1 } = this.state
    return (
      <Grid>
        {this.state.showHeader && <GridRow columns={1} style={{ paddingBottom: 0 }}>
          <GridColumn>
            <DashboardHeader />
          </GridColumn>
        </GridRow>}
        <GridRow columns={2} style={{ paddingBottom: 0 }}>
          <GridColumn style={{ paddingRight: 0 }}>
            <Segment>
              <OtrSegmentHeader title='STUDENT INFORMATION' />
              <Grid style={{ padding: '1rem', paddingTop: 0 }}>
              {/*
                <Grid.Row>
                  <Grid.Column width={3} className='formLabel'>
                    ID
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Form.Input disabled fluid value={user.id} />
                  </Grid.Column>
                </Grid.Row>
              */}
                <Grid.Row>
                  <Grid.Column width={3} className='formLabel'>
                    Email Address
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Form.Input disabled fluid value={user.emailAddress} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3} className='formLabel'>
                    Name
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Form.Input disabled fluid value={`${user.firstName} ${user.lastName}`} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3} className='formLabel'>
                    Groups
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Label.Group>
                      {
                        user.groups.map(g => <Label key={g.id} className='groupLabel'>{g.name}</Label>)
                      }
                    </Label.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3} className='formLabel'>
                    Job Roles
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Label.Group>
                      {
                        user.jobRoles.map(g => <Label key={g.id} className='groupLabel'>{g.name}</Label>)
                      }
                    </Label.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </GridColumn>
          <GridColumn>
            <Segment>
              <OtrSegmentHeader title='ROLE COMPETENCIES' />
                        <BarChartTable min={0} max={100} data={jobRoles} />
            </Segment>
            <Segment>
              <OtrSegmentHeader title='GROUP COMPETENCIES' />
              <BarChartTable min={0} max={100} data={groups} />
            </Segment>
          </GridColumn>
        </GridRow>
        <GridRow>{this.barData}</GridRow>
        <GridRow columns={1}>
          <GridColumn>
            <Segment className='invertedSegment'>
              <OtrSegmentHeader inverted title='USAGE LOG' />
              <OtrTable
                data={usage}
                name='Usage'
                totalRows={totalUsage}
                pageSize={pageSize}
                pageOffset={pageOffset}
                paginationPressed={this.paginationPressed}
                clientPaging={false}
                extendedPaging={true}
              />
            </Segment>
          </GridColumn>
        </GridRow>
      </Grid>
    )
  }
}
