import _, { result } from 'lodash'
import React, { Component } from 'react'
import AdminHome from '../adminHome'
import StudentHome from '../studentHome'
import auth from '../../services/auth'
import ScreenHeader from '../../components/screenHeader'
import api from '../../classes/api'
import userType from '../../classes/userType'
import {Redirect} from "react-router-dom";

const defaultState = { filter: null, filterId: '', device: null, deviceId: '' }

export default class HomeScreen extends Component {
  state = defaultState
  
  async refreshFilterData () {
    const filterId = _.get(this.props, 'match.params.filterId', null)
    console.log('Homescreen Filter ID', filterId)


    if (filterId) {
      const result = await api.getFilters([filterId])
      if (result.success && result.data.length > 0) {
        const filter = result.data[0]
        this.setState({ filter, filterId })
      }
    }
  }

  async refreshDeviceData(){
    const deviceId = _.get(this.props, 'match.params.deviceId', null)
    if (deviceId) {
      const result = await api.otrById(deviceId);
      if (result.success){
        const device = result.data;
        this.setState({device, deviceId})
      }      
    }
  }

  async componentDidMount () {
    //this.refreshFilterData();
    //this.refreshDeviceData();
  }

  async componentDidUpdate () {
    const filterIdParam = _.get(this.props, 'match.params.filterId', null)
    const deviceIdParam = _.get(this.props, 'match.params.deviceId', null)
    const { filterId, deviceId } = this.state
    
    console.log("HomeScreen DeviceId", deviceIdParam, "Props", this.props);

    // Previously was being returned as per below - Moved up here
    if (deviceIdParam !== deviceId) {
      console.log("Updating Device Id", deviceIdParam);
      this.refreshDeviceData();
    }

    if (filterIdParam === null) {
      if (this.state.filter) {
        this.setState(defaultState)
      }
      return
    }

    if (filterIdParam !== filterId) {
      this.refreshFilterData()
    }


  }

  addFilter = async (data) => {
    const result = await api.createFilter(data)
    if (result.success) {
      this.props.history.push(`/filter/${result.data}`, ['refreshFilterAdd'])
    }
  }

  deleteFilter = async (filter) => {
    const result = await api.deleteFilter({ filterId: filter.id })
    if (result.success) {
      this.props.history.push('/', ['refreshFilterDel'])
    }
  }

  render () {
    const { filter, device } = this.state
    const { history } = this.props    

    const result = auth.getUserLevel()
    let element = null
    if (result.success) {
      switch ( userType.highestUserLevel(result.userLevel)) {
        case 'UserManagement':
          element = <AdminHome history={history} />
          break
        case 'Student':
          element = <StudentHome />
          break
        case 'ViewReports':
          element = <AdminHome history={history} />
          break
        default:
          element = null
      }
    }

    const pageTitle = (filter ? filter.name : device ? device.name : 'DASHBOARD')
    let isDrillsimInstructor = auth.checkForIDrillSimRole()
    if(isDrillsimInstructor){
      return <Redirect to='/instructor' />
    }
    return (
      <React.Fragment>
        <ScreenHeader 
          title={pageTitle} 
          filter={filter} 
          history={history}
          onFilterAdd={this.addFilter} 
          onFilterDelete={this.deleteFilter}
        />
        {element}
      </React.Fragment>
    )
  }
}
