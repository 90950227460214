import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

export default class MenuLinkIco extends Component {
  render () {
    const { to, title, exact = true } = this.props
    return (
      <NavLink exact={exact} to={to} className='menuLink'>
        {this.props.children ? this.props.children : <div style={{ width: '2rem' }} />}
        <Header inverted as='h5'>{title}</Header>
      </NavLink>
    )
  }
}
