import React, { Component } from 'react'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import ScreenHeader from '../../components/screenHeader'
import ExportButton from '../../components/exportButton'
import AddUserModal from './components/addUserModal'
import OtrModal from '../../components/otrModal'
import ImportUsersModal from './components/importUsersModal'
import DeleteUserModal from './components/deleteUserModal'

import auth from '../../services/auth'
import userType from '../../classes/userType'
import OtrTableUpgraded from '../../components/otrTableUpgraded'
import DeleteFromSelectedModal from '../../components/deleteFromSelectedModal'
import { sortByPropertyIndex, sortColumns } from '../../classes/tableHelpers'
import { Form } from 'formsy-semantic-ui-react'
import { Button, Grid, GridColumn, GridRow } from 'semantic-ui-react'
import OtrTextInput from '../../components/otrTextInput'
import ResetPasswordModal from '../user/components/resetPasswordModal'
import OtrDropdown from '../../components/otrDropdown'
import OtrCheckbox from '../../components/otrCheckbox'
import UserUpdateForm from '../../components/UserUpdateForm'

export default class Rigs extends Component {
  constructor (props) {
    super(props)
    this.handleOnOrderBy = this.handleOnOrderBy.bind(this)
  }
  state = {
    rigs: [],
    errors: [],
    totalRigs: 0,
    pageSize: 15,
    pageOffset: 0,
    search: '',
    loading: false,
    orderBy: null,
    saveLoading: false
  }
  searchUpdated = (event, data) => {
    this.setState({ search: data.value }, () => {
      this.getData()
    })
  }
  handleOnOrderBy (data) {
    let type
    if (this.state.orderBy) {
      if (this.state.orderBy.idx === data) {
        if (this.state.orderBy.type === 'desc') {
          type = 'asc'
          this.setState({ orderBy: { idx: data, type: type } }, () => {
            this.getData()
          })
        } else {
          this.setState({ orderBy: null }, () => {
            this.getData()
          })
        }
      } else {
        this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
          this.getData()
        })
      }
    } else {
      this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
        this.getData()
      })
    }
  }
  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  async getData () {
    this.toggleLoading()
    const { pageSize, pageOffset, search } = this.state
    let result
    if (search.length > 0) {
      result = await api.getAllRigs()
    } else {
      result = await api.getAllRigs()
    }

    this.toggleLoading()
    if (result.success) {
      let data = result.data
      let total = data.length
      if (this.state.orderBy) {
        //data = sortByPropertyIndex(this.state.orderBy.idx, this.state.orderBy.type, data)
      }
      this.setState({ rigs: data, totalRigs: total })
    }
  }

  componentDidMount () {
    this.getData()
  }
  paginationPressed = (pageOffset) => {
    this.setState({ pageOffset }, () => {
      this.getData()
    })
  }
  
  handleSortColumns (data) {
    return sortColumns(data, ['active'])
  }

  render () {
    const { rigs, search, loading, totalRigs, pageSize, pageOffset, errors } = this.state

    let actions = (
      <React.Fragment>
        <div style={{ paddingTop: '38px' }} />
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <ScreenHeader
          title='Rigs'
          search={search}
          actions={actions}
          searchUpdated={this.searchUpdated}
        />
        <OtrTableUpgraded
          orderBy={this.state.orderBy}
          data={rigs}
          hideColumns={['ID']}
          arrayLengthColumns={['DEVICES', 'USERS']}    
          sortColumnsHandler={this.handleSortColumns}
          name='Rigs'
          loading={loading}
          totalRows={totalRigs}
          pageSize={pageSize}
          pageOffset={pageOffset}
          handleOnOrderBy={this.handleOnOrderBy}
          paginationPressed={this.paginationPressed}
          clientPaging
          search={search}
        />
      </React.Fragment>
    )
  }
}
