import React, { Component, useRef } from 'react'
import _ from 'lodash'
import {
  Grid,
  GridRow,
  GridColumn,
  Loader,
  Segment,
  TableRow,
  TableCell} from 'semantic-ui-react'
import BarChartTable from '../../components/charts/barChartTable'
import WorldMap from '../../components/charts/worldMap'
import api from '../../classes/api'
import auth from '../../services/auth'
import userType from '../../classes/userType'
import SummaryRow from './component/summaryRow'
import { connect } from 'react-redux'
import { setPageData } from '../../redux/actions'
import WithRouter from 'react-router-dom/withRouter'
import { filterDataByObject, getFiltersOfPage } from '../../classes/filterHelper'
import queryString from 'query-string'
import { addPageFilter, removePageFilter, resetPageFilter } from '../../redux/actions'
import OtrNumberInputFilter from '../../components/otrNumberInputFilter'
import HoverInfo from '../../components/charts/mapComponents/HoverInfo'

const defaultLoadingState = {
  otrs: false,
  userStats: false,
  locations: false
}

const defaultState = {
  loading: defaultLoadingState,
}

class AdminHome extends Component {
  constructor(props){
    super(props);
      this.state = defaultState;
    this.cancellationToken = { cancelled: false } 
  }

  toggleLoading (attribute) {    
    if (this.cancellationToken.cancelled)
      return;

    let loading = this.state.loading;
    loading[attribute] = !loading[attribute];
    this.setState({ loading })
  }

  updateData(key, value, filter = null) { 
    let { pageData } = this.props;    
    pageData[key] = (value instanceof Array && filter instanceof Function) ?  value.filter(filter) : value;
    this.props.setPageData(pageData)
  }

  async loadOtrs () {
    this.toggleLoading('otrs')
    let locationIds = this.props.pageData.locations instanceof Array ? this.props.pageData.locations.map(x => x.rigId) : [];
    await api.getOtrStats(locationIds).then((result) => {
      if (this.cancellationToken.cancelled)
        throw new Error("loadOtrs got cancelled");

      if (result.success) {
        const otrs = result.data ? result.data.otrs : []
        this.updateData('otrs', otrs)
      }
      this.toggleLoading('otrs')
    }).catch(err => {
      console.log(err.message);
    })
  }

  async loadLocations () {    
    this.toggleLoading('locations')
    await api.getRigStats().then(result => {
      if (this.cancellationToken.cancelled)
        throw new Error("loadLocations got cancelled");
      
      if (result.success) {    
        const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
        const locations = result.data ? result.data.rigs : []  
        let filtered = filterDataByObject(pageFilters, locations)
        this.updateData('locations', filtered)
      }
      this.toggleLoading('locations')
    }).catch(err => {
      console.log(err.message);
    })
  }

  async loadUserStats () {
    this.toggleLoading('userStats')
    const { pageData } = this.props;   
    let locationIds = pageData.locations instanceof Array ? pageData.locations.map(x => x.rigId) : [];
    let deviceIds = pageData.otrs instanceof Array ? pageData.otrs.map(x => x.otrId) : [];
    await api.getUserStats(locationIds, deviceIds).then(result => {
      if (this.cancellationToken.cancelled)
        throw new Error("loadUserStats got cancelled");

      if (result.success) {
        this.updateData('userStats', result.data)
      }
      this.toggleLoading('userStats')
    }).catch(err => {
      console.log(err.message);
    })
  } 

  async loadData () {
    await this.loadLocations()
    await this.loadOtrs();
    this.loadUserStats();
  }

  componentDidMount () {  
    const filterOptions = {
        name: "Num. Active Users",
        headerValue: "activeUsers",
        type: ">",
        dataType: "Int32",
        controlComponent: OtrNumberInputFilter,  
        value: "1"        
    }
    const page = "/"
    this.cancellationToken.cancelled = false;
    this.loadData()  
    const params = queryString.parse(window.location.search)
    if (!params.defaultFilters && this.props.location.pathname === '/'){
        this.props.addPageFilter({ page: page, filterOptions })
        const newParams = {...params, defaultFilters: 'true'}
        const newQueryString = queryString.stringify(newParams)
        window.history.replaceState(null, '',  `?${newQueryString}`)
        
    }
  }

  componentWillUnmount() {
    this.cancellationToken.cancelled = true;
  }

  componentDidUpdate (prevProps) {
    // possibly redundant?
    /* if (prevProps.device != this.props.device) {
      const filterId = _.get(this.props, 'filter.id', null)
      const deviceId = _.get(this.props, 'device.id', null)
      this.loadDataByDevice(filterId, deviceId)
    } */
  }

  renderCompetencyRow = (rowData, key) => {
    const { userId, name, roleCompetencies } = rowData
    const onClick = () => this.studentClicked({ userId })
    return (
      <TableRow key={key} onClick={onClick}>
        <TableCell width={4}>
          <p>{name}</p>
        </TableCell>
        <TableCell width={4}>
          {roleCompetencies.map((r) => (
            <p key={r.id}>{r.name}</p>
          ))}
        </TableCell>
        <TableCell>
          {roleCompetencies.map((r) => (
            <BarChartTable
              key={r.id}
              min={0}
              max={100}
              data={[{ value: r.competency * 100 }]}
            />
          ))}
        </TableCell>
      </TableRow>
    )
  };

  studentClicked = ({ userId }) => {
    const result = auth.getUserLevel()
    if (
      result.success &&
      userType.highestUserLevel(result.userLevel) == 'UserManagement'
    ) {
      this.props.history.push(`/users/${userId}`)
    }
  };

  deviceClicked = (filterID) => {
    if (filterID !== null && filterID.length > 0) {
      this.props.history.push(`/otr/${filterID}`)
      // this.props.history.push(`/filter/${filterID}`);
    }
  };

  isLoading() {
    let result = false;
    const { pageData } = this.props;
    const { loading } = this.state;

    Object.keys(loading).forEach(key => {
      result = result || (loading[key] && !pageData[key]);
    });

    return result;
    }

  render () {    
    let { pageData } = this.props;
    let otrs = pageData ? pageData.otrs : []
    let locations = pageData ? pageData.locations : []
    let userStats = pageData ? pageData.userStats : []
    let userExerciseDetails = userStats && userStats.data ? userStats.data.userExerciseDetails : [];

    let mapData = {
      locations,
      userExerciseDetails
    }

    return (
      <Grid>
        <GridRow columns={1} style={{ paddingBottom: 0, paddingLeft: 15, paddingRight: 15 }}>
          <GridColumn>
            <SummaryRow mapData={mapData} userStats={userStats} otrs={otrs} locations={locations} />
          </GridColumn>
          <GridColumn style={{ paddingRight: 0}}> 
            <Segment style={{ margin: 0, padding: 0}} >     
              <Loader active={this.isLoading()} size='medium' inverted></Loader>
              <WorldMap mapData={mapData} markers={otrs} />
            </Segment>
          </GridColumn>  
        </GridRow> 
      </Grid>
    )
  }
}
const mapStateToProps = state => {
  return {
    filtersByPage: state.filtersByPage,
    pageData: state.pageData
  }
}
const mapDispatchToProps = {
  setPageData,
  addPageFilter
}
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(AdminHome))
