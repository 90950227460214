import React, { Component } from 'react'
import '../styles/UserMenu.css'
import { Grid, Icon } from 'semantic-ui-react'
import drillingSystemsLogo from '../img/drilling_systems_logo.svg'
import UserMenuItems from './UserMenuItems'
import { withRouter } from 'react-router-dom'
import api from '../classes/api'

class UserMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userImg: null,
      showUserMenu: false,
      name: '',
      surname: ''
    }
    this.handleOnClick = this.handleOnClick.bind(this)
    this.renderUserImage = this.renderUserImage.bind(this)
    this.getUserImg()
    this.getUserData()
  }
  async getUserImg () {
    /*
    TO DO: Connect To a api to get User IMG
    if (!this.state.logoSrc) {
      let meta = await api.meta()
      let logo = meta.data ? meta.data.logo || otrLogo : otrLogo
      this.setState({ userImg: logo })
    }
     */

  }
  async getUserData () {
    await api.user().then((response) => {
      this.setState({ name: response.data.firstName, surname: response.data.lastName })
    })
    /*
    TO DO: Connect To a api to get User IMG
    if (!this.state.logoSrc) {
      let meta = await api.meta()
      let logo = meta.data ? meta.data.logo || otrLogo : otrLogo
      this.setState({ userImg: logo })
    }
     */
  }
  handleOnClick () {
    this.setState({ showUserMenu: !this.state.showUserMenu })
  }
  renderUserImage () {
    // TODO: Connect to back-end
    const name = this.state.name + ' ' + this.state.surname
    // GET FIRST LETTER OF MAX 2 WORDS and join them by space (there is probably a better way to do this)
    // TODO: SOme users might have 2 surnames and complex names, Might be a good idea to get just name and the surname based of amount of words
    let arrayLetter = []
    name.split(' ').slice(0, 2).forEach((word) => {
      arrayLetter.push(word[0])
    })
    const initials = arrayLetter.join(' ')
    if (this.state.userImg) {
      return (
        <img alt='User Avatar' src={this.state.userImg} style={{ height: '4rem', width: '4rem' }}
        />
      )
    } else {
      return (
        <h1>
          {initials}
        </h1>
      )
    }
  }
  render () {
    return (
      <Grid.Column style={{ paddingRight: 0 }} mobile={4} tablet={4} computer={3} largeScreen={3}>
        <Grid.Row className={'userMenu'}>
          <Grid.Row className={'userMenuBox'} onClick={this.handleOnClick}>
            <Icon inverted style={{ fontSize: '2.5rem', marginRight: '0px' }} name='user' />
          </Grid.Row>
          <div style={{ position: 'absolute', zIndex: 50, backgroundColor: '#011c36', display: this.state.showUserMenu ? 'block' : 'none', right: '0%', top: '90%' }}>
            <UserMenuItems admin={this.props.admin} />
          </div>
        </Grid.Row>
      </Grid.Column>
    )
  }
}
export default withRouter(UserMenu)
