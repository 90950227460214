const changeDataInXmL = (xml, data) => {
  var sheetData = xml.childNodes[0].getElementsByTagName('sheetData')[0]
  removeAllChildNodes(sheetData)
  addRows(sheetData, data, xml)
  let last = numberToColumn(data[0].length - 1) + data.length
  updateDimensionOfData(xml, 'A1', last)
  function removeAllChildNodes (parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild)
    }
  }
  return `A1:${last}`
}

const changePivotCacheWorkSource = (xml, workSourceDirection) => {
  xml.childNodes[0].getElementsByTagName('cacheSource')[0].getElementsByTagName('worksheetSource')[0].setAttribute('ref', workSourceDirection)
}
const changeTableRef = (xml, Ref) => {
  xml.childNodes[0].setAttribute('ref', Ref)
  xml.childNodes[0].getElementsByTagName('autoFilter')[0].setAttribute('ref', Ref)
}
const updateDimensionOfData = (xml, First, End) => {
  let dimension = xml.childNodes[0].getElementsByTagName('dimension')[0]
  dimension.setAttribute('ref', `${First}:${End}`)
}

const addRows = (parent, data, xlmDoc) => {
  data.forEach((row, index) => {
    let rowNumber = index + 1
    let rowElement = xlmDoc.createElement('row')
    rowElement.setAttribute('r', rowNumber)
    row.forEach((column, indexColumn) => {
      if (column !== null) {
        let columnElement = xlmDoc.createElement('c')
        columnElement.setAttribute('s', 2)
        if (typeof column === 'string') {
          columnElement.setAttribute('t', 'str')
        }
        columnElement.setAttribute('r', numberToColumn(indexColumn) + rowNumber)
        let valueElement = xlmDoc.createElement('v')
        let textNode = xlmDoc.createTextNode(column)
        valueElement.appendChild(textNode)
        columnElement.appendChild(valueElement)
        rowElement.appendChild(columnElement)
      }
    })
    parent.appendChild(rowElement)
  })
}
function numberToColumn (n) {
  const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26]
  return n >= 26 ? numberToColumn(Math.floor(n / 26) - 1) + res : res
}
export { changeDataInXmL, changePivotCacheWorkSource , changeTableRef }
