import jwtDecode from 'jwt-decode'
const tokenKey = 'otr_token'

const setToken = (token) => {
  window.sessionStorage.setItem(tokenKey, token)
}

const getToken = () => {
  const data = window.sessionStorage.getItem(tokenKey)
  if (!data) {
    return { success: false }
  }
  return { success: true, token: data }
}

const isAuthenticated = () => {
  const result = getToken()
  return result
}

const clearToken = () => {
  window.sessionStorage.removeItem(tokenKey)
}

const getUserLevel = () => {
  const result = getToken()
  if (result.success) {
    const tokenData = jwtDecode(result.token)
    const role = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    return {
      success: true,
      userLevel: role
    }
  } else {
    return { success: false }
  }
}
const checkForIDrillSimRole = () => {
  let userLevel=getUserLevel()
  return userLevel.userLevel.includes("iDrillSIM")
}
export default {
  isAuthenticated,
  checkForIDrillSimRole,
  setToken,
  clearToken,
  getUserLevel
}
