import React, { Component } from 'react'
import { GridColumn, GridRow, Icon } from 'semantic-ui-react'
import { getCompetencyColor, getCompetencyColorBasedOnActivesAndDevices } from '../../../classes/competencyHelper'
import { Link } from 'react-router-dom'
import rigNameImage from '../../../img/Oil_Rig_Stencil.png'
import { addPageFilter, resetPageFilter } from '../../../redux/actions'
import { connect } from 'react-redux'
import WithRouter from 'react-router-dom/withRouter'
import { getComplianceColor, getComplianceColorBasedOnActivesAndDevices } from '../../../classes/complianceHelper'
import { Marker } from 'react-simple-maps'

class HoverInfo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      left: 0,
      top: 0
      }
      this.handleOnClick = this.handleOnClick.bind(this)
    }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.markerInfo !== this.props.markerInfo) {
      if (this.props.markerInfo) {
        this.calculatePosition(this.props.id)
      }
    }
    }
    handleOnClick(rig, region) {
        this.props.resetPageFilter('/reports/70f6930d-e368-4e93-b951-ac4f6594e679')
        setTimeout(() => {
            this.props.addPageFilter({
                page: '/reports/70f6930d-e368-4e93-b951-ac4f6594e679',
                filterOptions: {
                    headerValue: 'Region',
                    value: region,
                    type: 'includes',
                    dataType: 'String'
                }
            })
            this.props.addPageFilter({
                page: '/reports/70f6930d-e368-4e93-b951-ac4f6594e679',
                filterOptions: {
                    headerValue: 'Rig',
                    value: rig,
                    type: 'includes',
                    dataType: 'String'
                }
            })
            this.props.history.push('/reports/70f6930d-e368-4e93-b951-ac4f6594e679')
        }, 300)
    }


  calculatePosition (ElementId) {
    const markerElement = document.getElementById(`markerLink-${ElementId}`)
    const tooltipMarker = document.getElementById(`tooltipMarker`)
    const { top, left } = this.getCoords(markerElement)
    this.setState({ left: left - tooltipMarker.clientWidth / 2 - 20, top: top - (tooltipMarker.clientHeight) + 50 })
  }
  getCoords (elem) { // crossbrowser version
    var box = elem.getBoundingClientRect()

    var body = document.body
    var docEl = document.documentElement

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    var clientTop = docEl.clientTop || body.clientTop || 0
    var clientLeft = docEl.clientLeft || body.clientLeft || 0

    var top = (box.top + scrollTop - clientTop) - 25
    var left = (box.left + scrollLeft - clientLeft) + 180

    return { top: Math.round(top), left: Math.round(left) }
  }
  getFormatedDateString (dateString) {
    if (dateString) {
      const date = new Date(dateString)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      const hour = date.getHours()
      const minute = date.getMinutes()
      return `${hour}:${minute} ${day}/${month}/${year}`
    } else {
      return 'Never'
    }
  }
    getMarkerStyle(markerInfo) {   
    return {
      color: 'white',
      textAlign: 'center',
      outlineWidth: '0.3rem',
      outlineColor: 'rgba(48, 82, 156, 1)' ,
      outlineStyle: 'solid',
      width: '18rem',
    };
  }
renderMarkerInfo () {
  const markerInfo = this.props.markerInfo
    if (markerInfo) {
    const competencyHeight = (markerInfo.competency * 15) + 'rem';
    const complianceHeight = (markerInfo.compliance * 15) + 'rem';

    var backColor = ''
    const total = (markerInfo.compliance + markerInfo.competency) / 2 * 100
    if (total === 0) {
        backColor = 'rgba(190, 190, 190, 0.8)'
    } else if (total < 45) {
      backColor = 'rgb(230,68,53, 0.8)'
    } else if (total < 70) {
        backColor = 'rgb(255, 208, 22, 0.8)'
    } else {
      backColor = 'rgb(177,219,29, 0.8)'
    }

    return (
      <div id={'tooltipMarker'} style={this.getMarkerStyle(markerInfo)}>
        <div style={{ backgroundColor: 'rgba(48, 82, 156, 1)', position: 'relative' }}>
          <p style={{ marginBottom: '0rem', color: 'white', fontSize: '1.1rem', textTransform: 'uppercase', fontWeight: 'bold', paddingTop: '0.2rem' }}>
            {markerInfo.name}
          </p>
          <p style={{ color: 'white', fontSize: '0.9rem', textTransform: 'uppercase', paddingBottom: '0.2rem' }}>
            {markerInfo.region}
          </p>
        </div>
        <div style={{ backgroundColor: backColor, height: '10rem' }}>
          <img alt={'RigImage'} src={markerInfo.icon} style={{ width: '100%', height: '100%', objectFit: 'contain'}} />
        </div>
        <div style={{ height: '0.1rem', backgroundColor: 'rgba(48, 82, 156, 1)'}}/>
        <div style={{ position: 'relative', height: '15rem', display: 'flex', alignItems: 'flex-end', backgroundColor: 'rgba(190, 190, 190, 0.8' }}>
          <div style={{ position: 'relative', width: '49.5%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center',
            backgroundColor: getCompetencyColorBasedOnActivesAndDevices(markerInfo.competency * 100, markerInfo.actives, markerInfo.devices),
            textAlign: 'center', borderTopLeftRadius: '10px', fontSize: '1.1rem', padding: '4px 0px', height: competencyHeight}}>
            <div style={{ position: 'absolute', bottom: '0', color: 'white', fontSize: '1rem', fontWeight: 'bold', zIndex: 2, marginBottom: '0.4rem' }}>
              OVERALL COMPETENCY
              <br />
              {(markerInfo.competency * 100).toFixed(1)}%
            </div>
          </div>
          <div style={{ position: 'relative', width: '1%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(48, 82, 156, 1)', height: '100%' }}/>
          <div style={{ position: 'relative', width: '49.5%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center',
            backgroundColor: getComplianceColorBasedOnActivesAndDevices(markerInfo.compliance * 3, markerInfo.actives, markerInfo.devices),
            textAlign: 'center', borderTopRightRadius: '10px', fontSize: '1.1rem', padding: '4px 0px', height: complianceHeight}}>
            <div style={{ position: 'absolute', bottom: '0', color: 'white', fontSize: '1rem', fontWeight: 'bold', zIndex: 2 , marginBottom: '0.4rem'}}>
              OVERALL COMPLIANCE
              <br />
              {(markerInfo.compliance * 3).toFixed(1)}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'rgba(48, 82, 156, 1)', marginTop: '0px', paddingTop: '0px' }}>
          <p style={{ marginBottom: '0.2rem', color: 'white', fontSize: '1rem', paddingTop: '0.4rem' }}>
            <Icon name='tablet' /> DEVICES: <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{markerInfo.devices}</span>
          </p>
          <p style={{ marginBottom: '0.2rem', color: 'white', fontSize: '1rem', paddingBottom: '0rem'}}>
            <Icon name='user' /> ACTIVE USERS: <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{markerInfo.actives}</span>
          </p>
          <p style={{ marginBottom: '1rem', color: 'white', fontSize: '1rem', paddingBottom: '0.2rem' }}>
            <Icon name='user' /> REGISTERED USERS: <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{markerInfo.users}</span>
          </p>
        </div>
      </div>
    )
  }
}
  render () {
    return (
      <React.Fragment>
            <div onMouseEnter={() => { this.props.handleOnEnter(this.props.id, true) }} onClick={() => {this.props.handleClickOff()}} onMouseLeave={() => { this.props.handleOnLeave(true); this.props.setMouseIn(false) }} 
        style={{ position: 'absolute', zIndex: 50, left: this.state.left, top: this.state.top, padding: '1rem'}}>
          {this.renderMarkerInfo()}
        </div>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  resetPageFilter,
  addPageFilter
}
export default connect(null, mapDispatchToProps)(WithRouter(HoverInfo))
