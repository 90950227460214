import React, { Component } from 'react'
import { Button, Icon, Segment } from 'semantic-ui-react'

export default class ExportButton extends Component {
  render () {
    const { onClick, float, label } = this.props
    return (
      <React.Fragment>
        <Button icon color='green' onClick={onClick} float={float}>
          <Icon name='external' />
          <label style={{ color: 'white' }}>{label}</label>
        </Button>
      </React.Fragment>
    )
  }
}
