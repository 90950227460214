// the number of repeats the algorithm runs
const maxRepeats = 20

// the distance a marker has to be within to be considered nearby
const nearbyRadius = 7

// how much the marker attempts to avoid neighbours
const avoidFactor = 1.7

// how much the marker attempts to go to average neighbour position
const centeringFactor = 1.2

const getNeighbours = (markers = [], current) => {
  let neighbourhood = []
  let dist = 0
  let diff = []
  let neighbour
  for (let i = 0; i < markers.length; i++) {
    neighbour = markers[i]
    if (neighbour !== current) {
      diff = [neighbour.position[0] - current.position[0], neighbour.position[1] - current.position[1]]
      dist = Math.hypot(diff[0], diff[1])
      if (dist <= nearbyRadius) {
        neighbourhood.push({ neighbour, distance: dist, position: neighbour.position })
      }
    }
  }
  return neighbourhood
}

const separation = (marker) => {
  let dir = [0, 0]
  marker.neighbours.forEach(n => {
    dir[0] += marker.position[0] - n.position[0]
    dir[1] += marker.position[1] - n.position[1]
  })

  marker.movement[0] += dir[0] * avoidFactor
  marker.movement[1] += dir[1] * avoidFactor
}

const cohesion = (marker) => {
  let avg = [0, 0]
  const neighbourCount = marker.neighbours.length
  marker.neighbours.forEach(n => {
    avg[0] += n.position[0] / neighbourCount
    avg[1] += n.position[1] / neighbourCount
  })

  marker.movement[0] += (avg[0] - marker.position[0]) * centeringFactor
  marker.movement[1] += (avg[1] - marker.position[1]) * centeringFactor
}

const update = (markers, current) => {
  for (let i = 0; i < markers.length; i++) {
    if (markers[i].id === current.id) {
      markers[i].coordinates += current.position
      return
    }
  }
}

const spaceMarkers = (markers = [], numRepeats = maxRepeats) => {
  let repeats = 0
  let mappedMarkers = markers.map(m => ({
    id: m.otrId,
    name: m.name,
    position: m.coordinates,
    movement: [0, 0]
  }))

  while (repeats < numRepeats) {
    mappedMarkers.forEach(mm => {
      // update neighbours
      mm.neighbours = getNeighbours(mappedMarkers, mm)
      if (mm.neighbours.length > 0) {
        // separate and then group them
        separation(mm)
        cohesion(mm)

        // limit movement
        let magnitude = Math.hypot(mm.movement[0], mm.movement[1])
        mm.movement = magnitude > 0 ? [mm.movement[0] / magnitude, mm.movement[1] / magnitude] : [0, 0]

        // update position
        mm.position[0] += mm.movement[0]
        mm.position[1] += mm.movement[1]

        // update markers array
        update(markers, mm)
      }
    })

    repeats++
  }

  return markers
}

export { spaceMarkers }
