import React, { Component } from 'react'
import ScreenHeader from '../../components/screenHeader'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import OtrTable from '../../components/otrTable'
import ExportButton from '../../components/exportButton'

export default class Exercises extends Component {
  state = {
    search: '',
    loading: false,
    exercises: []
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  searchUpdated = (event, data) => {
    this.setState({ search: data.value })
  }

  downloadExercises = () => {
    downloadCSV(this.state.exercises, 'otr-exercises')
  }

  async getData () {
    this.toggleLoading()
    const result = await api.allExercises()
    this.toggleLoading()
    if (result.success) {
      this.setState({ exercises: result.data })
    }
  }

  componentDidMount () {
    this.getData()
  }

  render () {
    const { exercises, loading, search } = this.state
    return (
      <React.Fragment>
        <ScreenHeader
          title='EXERCISES'
          actions={<ExportButton onClick={this.downloadExercises} />}
          search={search}
          searchUpdated={this.searchUpdated}
        />
        <OtrTable
          data={exercises}
          hideColumns={["EXERCISE ID"]}
          name='Exercises'
          loading={loading}
          search={search}
          clientPaging
        />
      </React.Fragment>
    )
  }
}
