import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Menu, Header, Table, Segment, Icon } from 'semantic-ui-react'
import { searchData } from '../classes/search'
import loadingImage from '../img/OTR-loading-animation-small.gif'
import { Checkbox } from 'formsy-semantic-ui-react'
import { serializeToJson } from '../classes/jsonSerialize'
export default class OtrTableV2 extends Component {
  state = {
    clientPagingOffset: 0,
    rows: []
  }

  renderHeaders = (data, rowRemove, hideColumns) => {
    const firstRow = (data.length > 0 ? data[0] : {})
    const keys = Object.keys(firstRow)
    const rows = keys.map((val, idx) => {
      if (Array.isArray(hideColumns) && hideColumns.indexOf(_.upperCase(val)) > -1) {
        return (null)
      } else {
        return (<Table.HeaderCell key={idx}>{_.startCase(val)}</Table.HeaderCell>)
      }
    })

    if (rowRemove) {
      rows.push(<Table.HeaderCell key={999}></Table.HeaderCell>)
    }

    return rows
  }
  renderRowsWithJson (data) {
    let rows = []
    Object.keys(data).reverse().map((region) => {
      let regionRow1TotalValue = 0; let regionRow2TotalValue = 0; let regionRow3TotalValue = 0; let regionRow4TotalValue = 0; let regionRow5TotalValue = 0
      let rigNamesRows = []
      Object.keys(data[region]).forEach((rigName) => {
        let rigNameRow1TotalValue = 0; let rigNameRow2TotalValue = 0; let rigNameRow3TotalValue = 0; let rigNameRow4TotalValue = 0; let rigNameRow5TotalValue = 0
        let jobRolesRows = []
        Object.keys(data[region][rigName]).forEach((jobRole) => {
          let jobRoleRow1TotalValue = 0; let jobRoleRow2TotalValue = 0; let jobRoleRow3TotalValue = 0; let jobRoleRow4TotalValue = 0; let jobRoleRow5TotalValue = 0
          let userRows = []
          Object.keys(data[region][rigName][jobRole]).forEach((user) => {
            let userRow1TotalValue = 0; let userRow2TotalValue = 0; let userRow3TotalValue = 0; let userRow4TotalValue = 0; let userRow5TotalValue = 0
            let disciplineRows = []
            Object.keys(data[region][rigName][jobRole][user]).forEach((discipline) => {
              let disciplineRow0TotalValue = 0; let disciplineRow1TotalValue = 0; let disciplineRow2TotalValue = 0; let disciplineRow3TotalValue = 0; let disciplineRow4TotalValue = 0; let disciplineRow5TotalValue = 0

              let volumeRows = []
              Object.keys(data[region][rigName][jobRole][user][discipline]).forEach((volume) => {
                let volumeRow0TotalValue = 0; let volumeRow1TotalValue = 0; let volumeRow2TotalValue = 0; let volumeRow3TotalValue = 0; let volumeRow4TotalValue = 0; let volumeRow5TotalValue = 0
                let exercisesRows = []
                data[region][rigName][jobRole][user][discipline][volume].forEach((exercise) => {
                  volumeRow0TotalValue += exercise[1]
                  volumeRow1TotalValue += exercise[3]
                  volumeRow2TotalValue += exercise[4]
                  volumeRow3TotalValue += exercise[5]
                  volumeRow4TotalValue += exercise[6]
                  volumeRow5TotalValue += exercise[7]
                  exercisesRows.push(this.exerciseRoleRow(exercise, region, rigName, jobRole, user, discipline, volume))
                })
                volumeRows.push(this.userVolumeRow(volumeRow0TotalValue / data[region][rigName][jobRole][user][discipline][volume].length, volumeRow1TotalValue, volumeRow2TotalValue, volumeRow3TotalValue, volumeRow4TotalValue, volumeRow5TotalValue, region, rigName, jobRole, user, discipline, volume))
                volumeRows.push(exercisesRows)
                disciplineRow0TotalValue += volumeRow0TotalValue / data[region][rigName][jobRole][user][discipline][volume].length
                disciplineRow1TotalValue += volumeRow1TotalValue
                disciplineRow2TotalValue += volumeRow2TotalValue
                disciplineRow3TotalValue += volumeRow3TotalValue
                disciplineRow4TotalValue += volumeRow4TotalValue
                disciplineRow5TotalValue += volumeRow5TotalValue
              })
              disciplineRows.push(this.userDisciplineRow(disciplineRow0TotalValue / Object.keys(data[region][rigName][jobRole][user][discipline]).length, disciplineRow1TotalValue, disciplineRow2TotalValue, disciplineRow3TotalValue, disciplineRow4TotalValue, disciplineRow5TotalValue, region, rigName, jobRole, user, discipline))
              disciplineRows.push(volumeRows)
              userRow1TotalValue += disciplineRow1TotalValue
              userRow2TotalValue += disciplineRow2TotalValue
              userRow3TotalValue += disciplineRow3TotalValue
              userRow4TotalValue += disciplineRow4TotalValue
              userRow5TotalValue += disciplineRow5TotalValue
            })
            userRows.push(this.userRoleRow(user, userRow1TotalValue, userRow2TotalValue, userRow3TotalValue, userRow4TotalValue, userRow5TotalValue, region, rigName, jobRole))
            userRows.push(disciplineRows)
            jobRoleRow1TotalValue += userRow1TotalValue
            jobRoleRow2TotalValue += userRow2TotalValue
            jobRoleRow3TotalValue += userRow3TotalValue
            jobRoleRow4TotalValue += userRow4TotalValue
            jobRoleRow5TotalValue += userRow5TotalValue
          })
          rigNameRow1TotalValue += jobRoleRow1TotalValue
          rigNameRow2TotalValue += jobRoleRow2TotalValue
          rigNameRow3TotalValue += jobRoleRow3TotalValue
          rigNameRow4TotalValue += jobRoleRow4TotalValue
          rigNameRow5TotalValue += jobRoleRow5TotalValue
          jobRolesRows.push(this.jobRoleRow(jobRole, jobRoleRow1TotalValue, jobRoleRow2TotalValue, jobRoleRow3TotalValue, jobRoleRow4TotalValue, jobRoleRow5TotalValue, region, rigName))
          jobRolesRows.push(userRows)
        })
        regionRow1TotalValue += rigNameRow1TotalValue
        regionRow2TotalValue += rigNameRow2TotalValue
        regionRow3TotalValue += rigNameRow3TotalValue
        regionRow4TotalValue += rigNameRow4TotalValue
        regionRow5TotalValue += rigNameRow5TotalValue
        rigNamesRows.push(this.rigNameRow(rigName, rigNameRow1TotalValue, rigNameRow2TotalValue, rigNameRow3TotalValue, rigNameRow4TotalValue, rigNameRow5TotalValue, region))
        rigNamesRows.push(jobRolesRows)
      })
      rows.push(this.regionRow(region, regionRow1TotalValue, regionRow2TotalValue, regionRow3TotalValue, regionRow4TotalValue, regionRow5TotalValue))
      rows.push(rigNamesRows)
    })
    this.setState({ rows: rows })
  }
  handleClickOnRegion (region) {
    let nameFixed = region.replace(/ /g, '')
    let regionRow = document.getElementById(nameFixed)
    if (regionRow.classList.contains('expanded')) {
      regionRow.querySelector('.minus').classList.add('hideRow')
      regionRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      regionRow.querySelector('.minus').classList.remove('hideRow')
      regionRow.querySelector('.plus').classList.add('hideRow')
    }
    regionRow.classList.toggle('expanded')

    let regionRows = document.getElementsByClassName(`region/${nameFixed}`)
    for (let regionRowsKey in regionRows) {
      if (typeof regionRows[regionRowsKey] === 'object') {
        this.hideOrShowRowBaseOfParent(regionRows[regionRowsKey])
      }
    }
  }
  hideOrShowRowBaseOfParent (row) {
    if (row.classList.contains('exerciseRow')) {
      let region = ''
      let rig = ''
      let role = ''
      let user = ''
      let discipline = ''
      let volume = ''
      const iterator = row.classList.values()
      for (const value of iterator) {
        let array = value.split('/')
        if (array.length > 1) {
          let propertyValue = ''
          let propertyId = array[0]
          if (array.length > 2) {
            propertyId = array.slice(1, array.length).join('')
          } else {
            propertyValue = array[1]
          }
          if (propertyId === 'region') {
            region = propertyValue
          }
          if (propertyId === 'rigName') {
            rig = propertyValue
          }
          if (propertyId === 'jobRole') {
            role = propertyValue
          }
          if (propertyId === 'user') {
            user = propertyValue
          }
          if (propertyId === 'discipline') {
            discipline = propertyValue
          }
          if (propertyId === 'volume') {
            volume = propertyValue
          }
        }
      }
      let parentRow = document.getElementById(`${region}/${rig}/${role}/${user}/${discipline}/${volume}`)
      if (parentRow !== null) {
        if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
          row.classList.remove('hideRow')
        } else {
          if (!row.classList.contains('hideRow')) {
            row.classList.add('hideRow')
          }
        }
      }
    }
    if (row.classList.contains('volumeRow')) {
      let region = ''
      let rig = ''
      let role = ''
      let user = ''
      let discipline = ''
      const iterator = row.classList.values()
      for (const value of iterator) {
        let array = value.split('/')
        if (array.length > 1) {
          let propertyValue = ''
          let propertyId = array[0]
          if (array.length > 2) {
            propertyId = array.slice(1, array.length).join('')
          } else {
            propertyValue = array[1]
          }
          if (propertyId === 'region') {
            region = propertyValue
          }
          if (propertyId === 'rigName') {
            rig = propertyValue
          }
          if (propertyId === 'jobRole') {
            role = propertyValue
          }
          if (propertyId === 'user') {
            user = propertyValue
          }
          if (propertyId === 'discipline') {
            discipline = propertyValue
          }
        }
      }
      let parentRow = document.getElementById(`${region}/${rig}/${role}/${user}/${discipline}`)
      if (parentRow !== null) {
        if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
          row.classList.remove('hideRow')
        } else {
          if (!row.classList.contains('hideRow')) {
            row.classList.add('hideRow')
          }
        }
      }
    }
    if (row.classList.contains('disciplineRow')) {
      let region = ''
      let rig = ''
      let role = ''
      let user = ''
      const iterator = row.classList.values()
      for (const value of iterator) {
        let array = value.split('/')
        if (array.length > 1) {
          let propertyValue = ''
          let propertyId = array[0]
          if (array.length > 2) {
            propertyId = array.slice(1, array.length).join('')
          } else {
            propertyValue = array[1]
          }
          if (propertyId === 'region') {
            region = propertyValue
          }
          if (propertyId === 'rigName') {
            rig = propertyValue
          }
          if (propertyId === 'jobRole') {
            role = propertyValue
          }
          if (propertyId === 'user') {
            user = propertyValue
          }
        }
      }
      let parentRow = document.getElementById(`${region}/${rig}/${role}/${user}`)
      if (parentRow !== null) {
        if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
          row.classList.remove('hideRow')
        } else {
          if (!row.classList.contains('hideRow')) {
            row.classList.add('hideRow')
          }
        }
      }
    }
    if (row.classList.contains('userRow')) {
      let region = ''
      let rig = ''
      let role = ''
      const iterator = row.classList.values()
      for (const value of iterator) {
        let array = value.split('/')
        if (array.length > 1) {
          let propertyValue = ''
          let propertyId = array[0]
          if (array.length > 2) {
            propertyId = array.slice(1, array.length).join('')
          } else {
            propertyValue = array[1]
          }
          if (propertyId === 'region') {
            region = propertyValue
          }
          if (propertyId === 'rigName') {
            rig = propertyValue
          }
          if (propertyId === 'jobRole') {
            role = propertyValue
          }
        }
      }
      let parentRow = document.getElementById(`${region}/${rig}/${role}`)
      if (parentRow !== null) {
        if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
          row.classList.remove('hideRow')
        } else {
          if (!row.classList.contains('hideRow')) {
            row.classList.add('hideRow')
          }
        }
      }
    }
    if (row.classList.contains('jobRoleRow')) {
      let region = ''
      let rig = ''
      const iterator = row.classList.values()
      for (const value of iterator) {
        let array = value.split('/')
        if (array.length > 1) {
          let propertyValue = ''
          let propertyId = array[0]
          if (array.length > 2) {
            propertyId = array.slice(1, array.length).join('')
          } else {
            propertyValue = array[1]
          }
          if (propertyId === 'region') {
            region = propertyValue
          }
          if (propertyId === 'rigName') {
            rig = propertyValue
          }
        }
      }
      let parentRow = document.getElementById(`${region}/${rig}`)
      if (parentRow !== null) {
        if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
          row.classList.remove('hideRow')
        } else {
          if (!row.classList.contains('hideRow')) {
            row.classList.add('hideRow')
          }
        }
      }
    }
    if (row.classList.contains('rigNameRow')) {
      let region = ''
      const iterator = row.classList.values()
      for (const value of iterator) {
        let array = value.split('/')
        if (array.length > 1) {
          let propertyValue = ''
          let propertyId = array[0]
          if (array.length > 2) {
            propertyId = array.slice(1, array.length).join('')
          } else {
            propertyValue = array[1]
          }
          if (propertyId === 'region') {
            region = propertyValue
          }
        }
      }
      let parentRow = document.getElementById(`${region}`)
      if (parentRow !== null) {
        if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
          row.classList.remove('hideRow')
        } else {
          if (!row.classList.contains('hideRow')) {
            row.classList.add('hideRow')
          }
        }
      }
    }
  }
  regionRow (region, row1 = 0, row2 = 0, row3 = 0, row4 = 0, row5 = 0, first = false) {
    return (
      <Table.Row id={region.replace(/ /g, '')} onClick={() => { this.handleClickOnRegion(region) }} key={region} style={{ cursor: 'pointer' }}>
        <Table.Cell style={{ paddingLeft: '0rem', borderBottom: '2px solid black' }}>
          <p><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{region}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p >{}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }} >
          <p>{}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row1}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row2}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row3}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row4}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row5}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  handleClickOnRigName (region, rigName) {
    let nameFixed = region.replace(/ /g, '')
    let rigNameFixed = rigName.replace(/ /g, '')
    let rigNameRow = document.getElementById(`${nameFixed}/${rigNameFixed}`)

    if (rigNameRow.classList.contains('expanded')) {
      rigNameRow.querySelector('.minus').classList.add('hideRow')
      rigNameRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      rigNameRow.querySelector('.minus').classList.remove('hideRow')
      rigNameRow.querySelector('.plus').classList.add('hideRow')
    }
    rigNameRow.classList.toggle('expanded')
    let rigRows = document.getElementsByClassName(`region/${nameFixed} rigName/${rigNameFixed}`)
    for (let rigRowsKey in rigRows) {
      if (typeof rigRows[rigRowsKey] === 'object') {
        this.hideOrShowRowBaseOfParent(rigRows[rigRowsKey])
      }
    }
  }
  rigNameRow (rigName, row1 = 0, row2 = 0, row3 = 0, row4 = 0, row5 = 0, region, first = false) {
    return (
      <Table.Row id={`${region.replace(/ /g, '')}/${rigName.replace(/ /g, '')}`} onClick={() => { this.handleClickOnRigName(region, rigName) }} className={`hideRow rigNameRow region/${region.replace(/ /g, '')}`} key={rigName} style={{ cursor: 'pointer' }}>
        <Table.Cell key={rigName} style={{ paddingLeft: '6rem', borderBottom: '2px solid black' }}>
          <p><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{rigName}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p >{}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }} >
          <p>{}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row1}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row2}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row3}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row4}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row5}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  handleClickOnJobRoleRow (region, rigName, jobRole) {
    let nameFixed = region.replace(/ /g, '')
    let rigNameFixed = rigName.replace(/ /g, '')
    let jobRoleFixed = jobRole.replace(/ /g, '')
    let jobRoleRow = document.getElementById(`${nameFixed}/${rigNameFixed}/${jobRoleFixed}`)

    if (jobRoleRow.classList.contains('expanded')) {
      jobRoleRow.querySelector('.minus').classList.add('hideRow')
      jobRoleRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      jobRoleRow.querySelector('.minus').classList.remove('hideRow')
      jobRoleRow.querySelector('.plus').classList.add('hideRow')
    }
    jobRoleRow.classList.toggle('expanded')
    let jobRoleRows = document.getElementsByClassName(`region/${nameFixed} rigName/${rigNameFixed} jobRole/${jobRoleFixed}`)
    for (let jobRoleKey in jobRoleRows) {
      if (typeof jobRoleRows[jobRoleKey] === 'object') {
        this.hideOrShowRowBaseOfParent(jobRoleRows[jobRoleKey])
      }
    }
  }
  jobRoleRow (role, row1 = 0, row2 = 0, row3 = 0, row4 = 0, row5 = 0, region, rigName, first = false) {
    return (
      <Table.Row style={{ cursor: 'pointer' }} id={`${region.replace(/ /g, '')}/${rigName.replace(/ /g, '')}/${role.replace(/ /g, '')}`} onClick={() => { this.handleClickOnJobRoleRow(region, rigName, role) }} className={`jobRoleRow region/${region.replace(/ /g, '')} rigName/${rigName.replace(/ /g, '')} hideRow`}>
        <Table.Cell style={{ paddingLeft: '12rem', borderBottom: '2px solid black' }}>
          <p><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{role}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p >{}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row1}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row2}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row3}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row4}</p>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center', borderBottom: '2px solid black' }}>
          <p>{row5}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  handleOnClickUser (region, rigName, jobRole, user) {
    let nameFixed = region.replace(/ /g, '')
    let rigNameFixed = rigName.replace(/ /g, '')
    let jobRoleFixed = jobRole.replace(/ /g, '')
    let userFixed = user.replace(/ /g, '')
    let userRow = document.getElementById(`${nameFixed}/${rigNameFixed}/${jobRoleFixed}/${userFixed}`)

    if (userRow.classList.contains('expanded')) {
      userRow.querySelector('.minus').classList.add('hideRow')
      userRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      userRow.querySelector('.minus').classList.remove('hideRow')
      userRow.querySelector('.plus').classList.add('hideRow')
    }
    userRow.classList.toggle('expanded')
    let userRows = document.getElementsByClassName(`region/${nameFixed} rigName/${rigNameFixed} jobRole/${jobRoleFixed} user/${userFixed}`)
    for (let userKey in userRows) {
      if (typeof userRows[userKey] === 'object') {
        this.hideOrShowRowBaseOfParent(userRows[userKey])
      }
    }
  }
  userRoleRow (user, row1 = 0, row2 = 0, row3 = 0, row4 = 0, row5 = 0, region, rigName, jobRole) {
    return (
      <Table.Row className={`userRow region/${region.replace(/ /g, '')} rigName/${rigName.replace(/ /g, '')} jobRole/${jobRole.replace(/ /g, '')} hideRow`} onClick={() => { this.handleOnClickUser(region, rigName, jobRole, user) }} id={`${region.replace(/ /g, '')}/${rigName.replace(/ /g, '')}/${jobRole.replace(/ /g, '')}/${user.replace(/ /g, '')}`} style={{ textAlign: 'center', cursor: 'pointer' }} key={user}>
        <Table.Cell className={'userName'}>
          <p style={{ paddingLeft: '18rem', textAlign: 'left' }}><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{user}</p>
        </Table.Cell>
        <Table.Cell >
          <p>{}</p>
        </Table.Cell>
        <Table.Cell >
          <p>{}</p>
        </Table.Cell>
        <Table.Cell >
          <p>{row1}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row2}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row3}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row4}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row5}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  handleOnClickDiscipline (region, rigName, jobRole, user, discipline) {
    let nameFixed = region.replace(/ /g, '')
    let rigNameFixed = rigName.replace(/ /g, '')
    let jobRoleFixed = jobRole.replace(/ /g, '')
    let userFixed = user.replace(/ /g, '')
    let disciplineFixed = discipline.replace(/ /g, '')
    let disciplineRow = document.getElementById(`${nameFixed}/${rigNameFixed}/${jobRoleFixed}/${userFixed}/${disciplineFixed}`)

    if (disciplineRow.classList.contains('expanded')) {
      disciplineRow.querySelector('.minus').classList.add('hideRow')
      disciplineRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      disciplineRow.querySelector('.minus').classList.remove('hideRow')
      disciplineRow.querySelector('.plus').classList.add('hideRow')
    }
    disciplineRow.classList.toggle('expanded')
    let disciplineRows = document.getElementsByClassName(`region/${nameFixed} rigName/${rigNameFixed} jobRole/${jobRoleFixed} user/${userFixed} discipline/${disciplineFixed}`)
    for (let disciplineKey in disciplineRows) {
      if (typeof disciplineRows[disciplineKey] === 'object') {
        this.hideOrShowRowBaseOfParent(disciplineRows[disciplineKey])
      }
    }
  }
  userDisciplineRow (row0 = 0, row1 = 0, row2 = 0, row3 = 0, row4 = 0, row5 = 0, region, rigName, jobRole, user, discipline) {
    return (
      <Table.Row onClick={() => { this.handleOnClickDiscipline(region, rigName, jobRole, user, discipline) }} className={`disciplineRow region/${region.replace(/ /g, '')} rigName/${rigName.replace(/ /g, '')} jobRole/${jobRole.replace(/ /g, '')} user/${user.replace(/ /g, '')} hideRow`} id={`${region.replace(/ /g, '')}/${rigName.replace(/ /g, '')}/${jobRole.replace(/ /g, '')}/${user.replace(/ /g, '')}/${discipline.replace(/ /g, '')}`} style={{ textAlign: 'center', cursor: 'pointer' }} key={discipline}>
        <Table.Cell className={'userName'}>
          <p style={{ paddingLeft: '24rem', textAlign: 'left' }}><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{discipline}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row0.toFixed(0)}%</p>
        </Table.Cell>
        <Table.Cell>
          <p>{} </p>
        </Table.Cell>
        <Table.Cell >
          <p>{row1}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row2}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row3}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row4}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row5}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  handleOnClickVolume (region, rigName, jobRole, user, discipline, volume) {
    let nameFixed = region.replace(/ /g, '')
    let rigNameFixed = rigName.replace(/ /g, '')
    let jobRoleFixed = jobRole.replace(/ /g, '')
    let userFixed = user.replace(/ /g, '')
    let volumeFixed = volume.replace(/ /g, '')
    let disciplineFixed = discipline.replace(/ /g, '')

    let volumeRow = document.getElementById(`${nameFixed}/${rigNameFixed}/${jobRoleFixed}/${userFixed}/${disciplineFixed}/${volumeFixed}`)

    if (volumeRow.classList.contains('expanded')) {
      volumeRow.querySelector('.minus').classList.add('hideRow')
      volumeRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      volumeRow.querySelector('.minus').classList.remove('hideRow')
      volumeRow.querySelector('.plus').classList.add('hideRow')
    }
    volumeRow.classList.toggle('expanded')
    let volumeRows = document.getElementsByClassName(`region/${nameFixed} rigName/${rigNameFixed} jobRole/${jobRoleFixed} user/${userFixed} discipline/${disciplineFixed} volume/${volumeFixed}`)
    for (let volumeKey in volumeRows) {
      if (typeof volumeRows[volumeKey] === 'object') {
        this.hideOrShowRowBaseOfParent(volumeRows[volumeKey])
      }
    }
  }

  userVolumeRow (row0, row1 = 0, row2 = 0, row3 = 0, row4 = 0, row5 = 0, region, rigName, jobRole, user, discipline, volume) {
    return (
      <Table.Row onClick={() => { this.handleOnClickVolume(region, rigName, jobRole, user, discipline, volume) }} className={`volumeRow region/${region.replace(/ /g, '')} rigName/${rigName.replace(/ /g, '')} jobRole/${jobRole.replace(/ /g, '')} user/${user.replace(/ /g, '')} discipline/${discipline.replace(/ /g, '')} hideRow`} id={`${region.replace(/ /g, '')}/${rigName.replace(/ /g, '')}/${jobRole.replace(/ /g, '')}/${user.replace(/ /g, '')}/${discipline.replace(/ /g, '')}/${volume.replace(/ /g, '')}`} style={{ textAlign: 'center', cursor: 'pointer' }} key={volume}>
        <Table.Cell className={'userName'}>
          <p style={{ paddingLeft: '28rem', textAlign: 'left' }}><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{volume}</p>
        </Table.Cell>
        <Table.Cell >
          <p>{row0.toFixed(0)}%</p>
        </Table.Cell>
        <Table.Cell >
          <p>{}</p>
        </Table.Cell>
        <Table.Cell >
          <p>{row1}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row2}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row3}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row4}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{row5}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  exerciseRoleRow (exercise, region, rigName, jobRole, user, discipline, volume) {
    let currentDate = ''
    if (exercise[2]) {
      const date = new Date(exercise[2])

      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      currentDate = `${day}/${month}/${year}`
    }
    return (
      <Table.Row className={`exerciseRow hideRow user/${user.replace(/ /g, '')} region/${region.replace(/ /g, '')} rigName/${rigName.replace(/ /g, '')} jobRole/${jobRole.replace(/ /g, '')} discipline/${discipline.replace(/ /g, '')} volume/${volume.replace(/ /g, '')}`} data-user={user} style={{ textAlign: 'center' }}>
        <Table.Cell>
          <p style={{ paddingLeft: '30rem' }}>{exercise[0]}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{exercise[1]}%</p>
        </Table.Cell>
        <Table.Cell>
          <p>{currentDate}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{exercise[3]}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{exercise[4]}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{exercise[5]}</p>
        </Table.Cell>
        <Table.Cell>
          <p>{exercise[6]}</p>
        </Table.Cell>
      </Table.Row>
    )
  }
  renderLoading = (name) => (
    <div className='tableLoadingSpinner'>
      <img alt='loading spinner' src={loadingImage} />
      <h4>Loading {name}</h4>
    </div>
  );

  clientPagingationPressed = (offset) => {
    this.setState({ clientPagingOffset: offset })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.search !== this.props.search) {
      this.setState({ clientPagingOffset: 0 })
    }
  }
  componentDidMount () {
    this.renderRowsWithJson(serializeToJson(this.props.header, this.props.data))
  }
  render () {
    let {
      data = [],
      hideColumns = [],
      rows = null,
      totalRows = 0,
      pageSize = 25,
      loading = false,
      name = '',
      pageOffset = 0,
      paginationPressed = () => {},
      rowClicked = null,
      search = '',
      filteredColumns = [],
      rowRemove = null,
      clientPaging = false,
      extendedPaging = false
    } = this.props

    const { clientPagingOffset } = this.state

    const searchedData = data.filter(d => d.includes(search))
    let filteredData = serializeToJson(this.props.header, searchedData)
    const table = (
      <Table basic='very' selectable={(rowClicked !== null)}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={1}>{_.startCase('')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={4}>{_.startCase('Competency')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={4}>{_.startCase('Last Record')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={6}>{_.startCase('Total Attempts')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={7}>{_.startCase('Session Passed')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={7}>{_.startCase('Session Aborted')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={7}>{_.startCase('Session Failed')}</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'center' }} key={7}>{_.startCase('Session Unknown')}</Table.HeaderCell>
            {
              /*
              <Table.HeaderCell style={{ textAlign: 'center' }} key={8}>{_.startCase('Leason Compliance')}</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }} key={9}>{_.startCase('Leason Competence')}</Table.HeaderCell>
               */
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {// rows || this.renderRows(filteredData, rowClicked, rowRemove, hideColumns)
            this.state.rows
          }
        </Table.Body>
      </Table>
    )

    const empty = (
      <Header as='h4' className='emptyMessage' textAlign='center' style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>No {name} found</Header>
    )

    return (
      <Segment>
        { loading && this.renderLoading(name) }
        { (this.state.rows.length > 0 && !loading) && table }
        { (filteredData.length === 0 && !loading) && empty }
      </Segment>
    )
  }
}
