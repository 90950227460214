import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import './semantic/dist/semantic.min.css'
import './styles/styles.css'
import './styles/fonts.css'
import HomeScreen from './screens/home'
import StudentHomeScreen from './screens/studentHome'
import LoginScreen from './screens/login'
import ReportsScreen from './screens/reports'
import ReportScreen from './screens/report'
import DevicesScreen from './screens/devices'
import DeviceScreen from './screens/device'
import UsersScreen from './screens/users'
import ReleasesScreen from './screens/releases'
import RigsScreen from './screens/rigs'
import UserScreen from './screens/user'
import GroupsScreen from './screens/groups'
import GroupScreen from './screens/group'
import JobRolesScreen from './screens/jobRoles'
import ExercisesScreen from './screens/exercises'
import MatrixScreen from './screens/matrix'
import ForgotScreen from './screens/forgot'
import ResetScreen from './screens/reset'
import LogoutScreen from './screens/logout'
import * as serviceWorker from './serviceWorker'
import authService from './services/auth'
import FourofourScreen from './screens/404'
import Frame from './components/frame'

import SettingsScreen from './screens/settings'
import SettingScreen from './screens/setting'
import UserSettings from './screens/userSettings'
import configureStore from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import ExportScreen from './screens/export'
import SimulatorSelector from './screens/simulatorSelector'
import SimulatorIntructor from './screens/simulatorIntructor'

function PrivateRoute ({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authService.isAuthenticated().success ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
const { store, persistor } = configureStore()
const router = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch id='pageContainer'>
          <Route exact path='/login' component={LoginScreen} />
          <Route exact path='/forgot' component={ForgotScreen} />
          <Route exact path='/reset' component={ResetScreen} />
          <Switch>
            <Frame>
              <PrivateRoute exact path='/' component={HomeScreen} />
              <PrivateRoute exact path='/filter/:filterId' component={HomeScreen} />
              <PrivateRoute exact path='/otr/:deviceId' component={HomeScreen} />

              <PrivateRoute exact path='/reports/' component={ReportsScreen} />
              <PrivateRoute exact path='/reports/:reportId' component={ReportScreen} />
              <PrivateRoute exact path='/devices/' component={DevicesScreen} />
              <PrivateRoute exact path='/devices/:deviceId' component={DeviceScreen} />
              <PrivateRoute exact path='/users/' component={UsersScreen} />
              <PrivateRoute exact path='/userSettings/' component={UserSettings} />
              <PrivateRoute exact path='/users/:userId' component={UserScreen} />
              <PrivateRoute exact path='/groups/' component={GroupsScreen} />
              <PrivateRoute exact path='/groups/:groupId' component={GroupScreen} />

              <PrivateRoute exact path='/settings/' component={SettingsScreen} />
              <PrivateRoute exact path='/export/' component={ExportScreen} />

              <PrivateRoute exact path='/releases/' component={ReleasesScreen} />
              <PrivateRoute exact path='/simulator/' component={SimulatorSelector} />
              <PrivateRoute exact path='/instructor/' component={SimulatorIntructor} />

              <PrivateRoute exact path='/rigs/' component={RigsScreen} />

              <PrivateRoute exact path='/settings/:settingId' component={SettingScreen} />

              <PrivateRoute exact path='/matrix/' component={MatrixScreen} />
              <PrivateRoute exact path='/matrix/:environment' component={MatrixScreen} />
              {/* }<PrivateRoute exact path='/job-roles/' component={JobRolesScreen} /> { */}
              {/* }<PrivateRoute exact path='/exercises/' component={ExercisesScreen} /> { */}
              <PrivateRoute exact path='/student/' component={StudentHomeScreen} />
              <PrivateRoute exact path='/logout/' component={LogoutScreen} />
            </Frame>
          </Switch>
          <Route from='*' exact component={FourofourScreen} />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>

)

ReactDOM.render(router, document.getElementById('root'))
window.store = store
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
