import React, { Component } from 'react'
import { Grid, Header, Button, Label, GridColumn } from 'semantic-ui-react'
import auth from '../services/auth'
import { Link } from 'react-router-dom'
import SearchBox from './searchBox'
import squareIcon from '../img/square.svg'
import Menu from './menu'
import UserMenu from './UserMenu'
import MenuHamburger from './menuHamburger'
import AddFilterModalRedux from './addFilterModalRedux'
import MenuRow from './menuRow'
import SearchBoxImproved from './searchBoxImproved'
import userType from '../classes/userType'

export default class ScreenHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      isAdmin: this.isAdmin()      
    }
  }
  isAdmin () {
    const result = auth.getUserLevel();
    switch (userType.highestUserLevel(result.userLevel)){
      case 'DeviceAdministrator':
          return true;
      case 'UserManagement':
          return true;
      default:
        return false;
    }
  }
  render () {
    const {
      title,
      search,
      searchUpdated = null,
      actions = null,
      backText = '',
      backLocation = null,
      reduxFilter,
      table,
      header
    } = this.props
    return (
      <Grid>
        <div style={{ padding: 0, paddingBottom: '0.5rem', display: 'flex', width: '100%', flexWrap: 'wrap', paddingRight: '15px', paddingLeft: '15px', height: '7rem' }}>
          <div style={{ display: 'flex', width: '60%' }}>
            <div style={{ paddingRight: '1.45rem', width: '18rem' }} >
              <Menu />
            </div>
            <MenuRow />
          </div>
          <div style={{ display: 'flex', width: '40%', flexDirection: 'row', justifyContent: 'flex-end' }}>
            { searchUpdated &&
              <Grid.Column style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '2rem' }}>
                <SearchBoxImproved value={search} onChange={searchUpdated} />
              </Grid.Column>
            }
            { (this.state.isAdmin || reduxFilter) &&
            <Grid.Column style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '2rem' }}>
              {
                <AddFilterModalRedux table={table} header={header} />
              }
            </Grid.Column>
            }
            <Grid.Column width={2} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <UserMenu admin={this.state.isAdmin} />
            </Grid.Column>
          </div>
        </div>
        <Grid.Row>
          <Grid.Column width={16}>
            <hr />
          </Grid.Column>
        </Grid.Row>
        {
          actions && (
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='actionsContainer'>
                  {actions}
                </div>
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
    )
  }
}
