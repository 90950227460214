import React, { Component } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import squareInverted from '../img/squareInverted.svg'
import square from '../img/square.svg'

export default class OtrSegmentHeader extends Component {
  render () {
    const { title, inverted = false } = this.props
    return (
      <Grid padded>
        <Grid.Row columns={1} style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>
          <Grid.Column width={16} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Header as='h4' style={{ margin: 0 }}>{title}</Header>
            {/*<img src={(inverted ? square : squareInverted)} style={{ width: '2rem', height: '2rem' }} alt='Square Logo' /> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column width={16}>
            <hr className='inverted' />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
