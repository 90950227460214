import React, { Component } from 'react'
import { Icon, Input } from 'semantic-ui-react'

export default class SearchBoxImproved extends Component {
  render () {
    const { value, onChange } = this.props
    return (
      <>
        <Input
          icon='search'
          iconPosition='left'
          size={'huge'}
          placeholder='SEARCH'
          style={{ width: '20rem', height: '3rem' }}
          className='searchBoxImproved'
          value={value}
          onChange={onChange}
        />
        <button onClick={(e) => { onChange(e, { value: '' }) }} style={{ height: '3rem', paddingRight: '0px', paddingLeft: '0px', cursor: 'pointer', backgroundColor: 'white', border: '0px', marginLeft: '2px' }}><Icon style={{ marginRight: '0px' }} color={'red'} size='big' name='close' /></button>
      </>
    )
  }
}
