import React, { Component } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import SearchBox from './searchBox'
import squareIcon from '../img/square.svg'
import { Link } from 'react-router-dom'

export default class ScreenSubHeader extends Component {
  render () {
    const {
      title,
      search,
      searchUpdated = null,
      actions = null,
      titleColor = 'white',
      goBackButtonUrl = null
    } = this.props
    return (
      <Grid>
        <Grid.Row style={{ padding: 0, paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
          <Grid.Column width={8} className='screenHeaderContainer' >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <img src={squareIcon} style={{ width: '2.5rem', height: '2.5rem', marginRight: '0.5rem' }} alt='Sub Heading Icon' /> */}
              {goBackButtonUrl && (
                <Link to={goBackButtonUrl} style={{ color: titleColor, margin: 0, textTransform: 'uppercase', fontSize: '2rem', paddingBottom: '0.6rem', paddingLeft: '1rem', paddingRight: '1rem' }} >&lt;</Link>
              )}
              <Header style={{ color: titleColor, margin: 0, textTransform: 'uppercase' }} inverted as='h3'>{title}</Header>
            </div>
            <div className='actionsContainer'>
              {actions}
            </div>
          </Grid.Column>
          <Grid.Column width={8} style={{ textAlign: 'right' }}>
            { searchUpdated && <SearchBox value={search} onChange={searchUpdated} /> }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: '0.5rem' }}>
          <Grid.Column width={16}>
            <hr />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
