import moment from "moment";

function getSortedArrayFirst(obj, sortType){
  if (obj instanceof Array && obj.length > 0){    
    obj.sort((pa, pb) => compare('name', pa, pb, sortType));
    return obj[0];
  }
  return obj
}

function getObjectPropertyValueName(obj, property){
  if (obj instanceof Object){
    if (property === 'installedAppVersion'){
      return obj.version;
    } else if (obj.name){
      return obj.name;
    }
  } 
  return obj;
}

function getValueAsMoment(obj){
  if (obj !== null){
    let date = moment(obj, 'DD-MM-YYYY HH:mm:ss');
    if (date.isValid()){
      obj = date.valueOf();
    }
  }
  return obj;
}

function compare (property, a, b, type, getArrayLength = false) {
  let propA = a[property];
  let propB = b[property];

  if (getArrayLength){
    propA = propA.length;
    propB = propB.length;
  }

  propA = getSortedArrayFirst(propA, type);
  propB = getSortedArrayFirst(propB, type);

  propA = getObjectPropertyValueName(propA, property);
  propB = getObjectPropertyValueName(propB, property);

  propA = getValueAsMoment(propA);
  propB = getValueAsMoment(propB);

  if (type === 'desc') {
    if (propA < propB) {
      return 1
    }
    if (propA > propB) {
      return -1
    }
    if (propA && propB === null){
      return 1
    }
    if (propA === null && propB){
      return -1
    }
    return 0
  } else {
    if (propA < propB) {
      return -1
    }
    if (propA > propB) {
      return 1
    }
    if (propA && propB === null){
      return -1
    }
    if (propA === null && propB){
      return 1
    }
    return 0
  }
}

export function sortByPropertyIndex (index, type, arrayLengthColumns, rows, sortColumnsHandler = null) {
  const keys = sortColumnsHandler ? sortColumnsHandler(rows[0]) : Object.keys(rows[0]);
  let property = keys[index]
  let getArrayLength = property && arrayLengthColumns instanceof Array && arrayLengthColumns.includes(property.toUpperCase())
  return rows.sort((a, b) => {
    return compare(property, a, b, type, getArrayLength)
  })
}

export function sortColumns (columns, forcedToEnd = [], forcedToStart = []){
  const keys = Object.keys(columns).sort((a, b) => {
    if (forcedToEnd && forcedToEnd.includes(b)) {
      return -1
    } else if (forcedToStart && forcedToStart.includes(b)){
      return 1
    }
    return 0
  });

  return keys;
}