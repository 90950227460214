import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import MenuLink from './menuLink'
import {NavLink} from "react-router-dom";

export default class StudentMenuItems extends Component {
  render () {
    return (
      <React.Fragment>
        <Grid.Row>
          <MenuLink to='/' title='Student' />
        </Grid.Row>
        <Grid.Row>
          <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '3rem' }} >
              <NavLink exact className='menuLinkRow' to={'/'}>HOME</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/simulator/'}>SIMULATOR</NavLink>
          </div>
        </Grid.Row>
      </React.Fragment>
    )
  }
}
