import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import auth from '../../services/auth'

export default class Logout extends Component {
  componentWillMount () {
    auth.clearToken()
  }
  render () {
    return <Redirect to='/' />
  }
}
