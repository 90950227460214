import React, { Component } from 'react'
import ScreenHeader from '../../components/screenHeader'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import OtrTable from '../../components/otrTable'
import ExportButton from '../../components/exportButton'
import AddGroupModal from './components/addGroupModal'

export default class Groups extends Component {
  state = {
    search: '',
    loading: false,
    groups: []
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  searchUpdated = (event, data) => {
    this.setState({ search: data.value })
  }

  download = () => {
    downloadCSV(this.state.groups, 'otr-groups')
  }

  async getData () {
    this.toggleLoading()
    const result = await api.allGroups()
    if (result.success) {
      this.setState({ groups: result.data })
      this.toggleLoading()
    }
  }

  componentDidMount () {
    this.getData()
  }

  groupClicked = ({ id }) => {
    this.props.history.push(`/groups/${id}`)
  }

  groupAdd = async (data) => {
    const result = await api.groupCreate(data)
    if (result.success) {
      this.getData()
    }
  }

  render () {
    const { groups, loading, search } = this.state
    const actions = (
      <React.Fragment>
        <ExportButton onClick={this.download} />
        <AddGroupModal validSubmit={this.groupAdd} />
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <ScreenHeader
          title='GROUPS'
          actions={actions}
          search={search}
          searchUpdated={this.searchUpdated}
        />
        <OtrTable
          data={groups}
          hideColumns={["ID",
                        "CUSTOM DATA NAME 001","CUSTOM DATA VALUE 001",
                        "CUSTOM DATA NAME 002","CUSTOM DATA VALUE 002",
                        "CUSTOM DATA NAME 003","CUSTOM DATA VALUE 003",
                        "CUSTOM DATA NAME 004","CUSTOM DATA VALUE 004",
                        "CUSTOM DATA NAME 005","CUSTOM DATA VALUE 005"]}
          name='Groups'
          loading={loading}
          rowClicked={this.groupClicked}
          search={search}
          filteredColumns={['users']}
        />
      </React.Fragment>
    )
  }
}
