import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import api from '../../classes/api'
import { searchData } from '../../classes/search'
import ScreenHeader from '../../components/screenHeader'
import ScreenSubHeader from '../../components/screenSubHeader'
import OtrTable from '../../components/otrTable'
import ExportButton from '../../components/exportButton'
import loadingImage from '../../img/OTR-loading-animation-small.gif'
import { Text, Pie, PieChart, Tooltip, Legend, Cell, Bar, BarChart, XAxis, YAxis } from 'recharts'
import OtrTableV2 from '../../components/otrTableV2'
import * as XLSX from 'xlsx-js-style'
import OtrTableGrouped from '../../components/otrTableGrouped'
import exportToXLSX from '../../classes/exportToXLSX'
import { withRouter } from 'react-router-dom'
import exportToXLSXWithTable from '../../classes/exportToXLSXWithTable'
import { filterData, getFiltersOfPage } from '../../classes/filterHelper'
import { connect } from 'react-redux'
import { setPageReportData } from '../../redux/actions'
import OtrTableUpgraded from '../../components/otrTableUpgraded'
class Report extends Component {
  state = {
    loading: false,
    reportLoading: false,
    search: '',
    reports: [],
    selectedReport: null,
    reportSearch: '',
    filter: null,
    filterId: '',
    reportName: ''
  }
  searchUpdated = (event, data) => {
    this.setState({ search: data.value })
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  toggleReportLoading () {
    const { reportLoading } = this.state
    this.setState({ reportLoading: (!reportLoading) })
  }

  async getReport (reportId) {
    this.toggleReportLoading()
    const result = await api.reportData(reportId)
    this.toggleReportLoading()
    if (result.success) {
      if (result.data.name === 'Regional Student List' || result.data.name === 'Remedial Report' || result.data.name === 'Exercise Feedback' || result.data.name === 'Rig Competency Report' || result.data.name === 'Student Competency Report' || result.data.name === 'Weekly Report') {
        switch (result.data.name) {
          case 'Regional Student List':
            {
              const newObject = JSON.parse(JSON.stringify(result.data))
              this.props.setPageReportData(newObject)
              let rowDp = result.data.data.rows.map((array) => {
                return [...array]
              })
              let headerDp = [...result.data.data.columns]
              const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
              result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)

              this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: result.data.data.rows, reportName: result.data.name, resultData: result.data.data, rows: rowDp, header: headerDp, depth: result.data.reportDepth } })
            }
            break
          case 'Exercise Feedback':
            {
              const newObject = JSON.parse(JSON.stringify(result.data))
              this.props.setPageReportData(newObject)
              const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
              result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)
              let rowDp = result.data.data.rows.map((array) => {
                return [...array]
              })
              let headerDp = [...result.data.data.columns]
              this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: result.data.data.rows, reportName: result.data.name, resultData: result.data.data, rows: rowDp, header: headerDp, depth: 6 } })
            }
            break
          case 'Rig Competency Report':
            {
              const newObject = JSON.parse(JSON.stringify(result.data))
              this.props.setPageReportData(newObject)
              const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
              result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)
              if (this.props.location.state && this.props.location.state.id) {
                let filteredRows = result.data.data.rows.map((row, i) => {
                  if (row[0] === this.props.location.state.id) {
                    return row
                  } else {
                    delete result.data.data.rows[i]
                  }
                })
              }
              let rowDp = result.data.data.rows.map((array) => {
                return [...array]
              })
              let headerDp = [...result.data.data.columns]
              this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: result.data.data.rows, reportName: result.data.name, resultData: result.data.data, rows: rowDp, header: headerDp, depth: result.data.reportDepth } })
            }
            break
          case 'Remedial Report':
            {
              const newObject = JSON.parse(JSON.stringify(result.data))
              this.props.setPageReportData(newObject)
              const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
              result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)
              let rowDp = result.data.data.rows.map((array) => {
                return [...array]
              })
              let headerDp = [...result.data.data.columns]
              this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: result.data.data.rows, reportName: result.data.name, resultData: result.data.data, rows: rowDp, header: headerDp, depth: result.data.reportDepth } })
            }
            break
          case 'Weekly Report':
            {
              const newObject = JSON.parse(JSON.stringify(result.data))
              this.props.setPageReportData(newObject)
              const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
              result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)
              let rowDp = result.data.data.rows.map((array) => {
                return [...array]
              })
              let headerDp = [...result.data.data.columns]
              this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: result.data.data.rows, reportName: result.data.name, resultData: result.data.data, rows: rowDp, header: headerDp, depth: result.data.reportDepth } })
            }
            break
          case 'Student Competency Report':
            {
              const newObject = JSON.parse(JSON.stringify(result.data))
              this.props.setPageReportData(newObject)
              const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
              result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)
              let rowDp = result.data.data.rows.map((array) => {
                return [...array]
              })
              let headerDp = [...result.data.data.columns]
              this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: result.data.data.rows, reportName: result.data.name, resultData: result.data.data, rows: rowDp, header: headerDp, depth: result.data.reportDepth } })
            }
            break
          default:
            break
        }
      } else {
        if (result.success) {
          this.setState({ reportName: result.data.name })
          switch (result.data.reportType) {
            //  Basic text report
            case 0:
              {
                const newObject = JSON.parse(JSON.stringify(result.data))
                this.props.setPageReportData(newObject)
                const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
                result.data.data.rows = filterData(pageFilters, result.data.data.columns, result.data.data.rows)
                const processedData = result.data.data.rows.map((rowData) => {
                  const row = {}
                  result.data.data.columns.forEach((column, idx) => {
                    row[column] = rowData[idx]
                  })
                  return row
                })
                let headerDp = [...result.data.data.columns]
                this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: processedData, reportName: result.data.name, resultData: result.data.data, header: headerDp } })
              }
              break

            //  grouped/stacked chart data
            case 101:
            case 102:
            case 103:
              {
                var workingArray = []
                var mappingArray = []
                var subGroups = []
                result.data.data.rows.forEach((row, idx) => {
                  var mapIdx = mappingArray.indexOf(row[0])
                  if (mapIdx == -1) {
                    mappingArray.push(row[0])
                    workingArray.push({ [result.data.data.columns[0]]: row[0], [row[1]]: row[2] })
                  } else {
                    workingArray[mapIdx] = { ...workingArray[mapIdx], [row[1]]: row[2] }
                  }
                  if (subGroups.indexOf(row[1]) == -1) {
                    subGroups.push(row[1])
                  }
                })

                const processedData = workingArray
                this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: processedData, dataKey: result.data.data.columns[0], subGroups: subGroups, reportName: result.data.name, resultData: result.data.data } })
              }
              break

            // basic chart data
            default:
              {

                const processedGraphData = result.data.data.rows.map((rowData) => {
                  var row = {}
                  result.data.data.columns.forEach((column, idx) => {
                    row = { ...row, [column]: rowData[idx] }
                  })
                  return row
                })
                this.setState({ selectedReport: { name: result.data.name, reportType: result.data.reportType, data: processedGraphData, nameKey: Object.keys(processedGraphData[0])[0], dataKey: Object.keys(processedGraphData[0])[1] }, reportName: result.data.name, resultData: result.data.data })
              }
              break
          }
        }
      }
    }
  }

  componentDidMount () {
    const { match: { params: { reportId } } } = this.props
    if (reportId) {
      this.getReport(reportId)
    }
  }

  searchUpdated = (event, data) => {
    this.setState({ reportSearch: data.value })
  }
  fixConversion (data, useData = false) {
    if (useData) {
      // checkForCompetencyAndConvert(data.columns, data.rows)
      let result = []
      result.push(data.columns)
      data.rows.forEach((row) => {
        result.push(row)
      })
      return result
    } else {
      // checkForCompetencyAndConvert(this.state.selectedReport.header, this.state.selectedReport.rows)
      let result = []
      result.push(this.state.selectedReport.header)
      this.state.selectedReport.rows.forEach((row) => {
        result.push(row)
      })
      return result
    }
  }
  generateColGroupInfo (data) {
    let rowIn = []
    const rowInfo = {
      level: 3
    }
    data.forEach((row) => {
      rowIn.push(rowInfo)
    })
    return rowIn
  }
  download = async () => {
    if (this.state.selectedReport.name === 'Remedial Report') {
      await exportToXLSX('/reports/RemedialReportV3/RemedialReportV4.zip', this.state.selectedReport.resultData, 'RemedialReport.xlsx')
    } else if (this.state.selectedReport.name === 'Weekly Report') {
      await exportToXLSX('/reports/RemedialReportV3/RemedialReportV4.zip', this.state.selectedReport.resultData, 'WeeklyReport.xlsx')
    } else if (this.state.selectedReport.name === 'Regional Student List') {
      await exportToXLSXWithTable('/reports/RegionalStudentList/RegionalStudentListV11.zip', this.state.selectedReport.resultData, 'RegionalStudentList.xlsx')
    } else if (this.state.selectedReport.name === 'Student List') {
      await exportToXLSXWithTable('/reports/studentList/studentListTest.zip', this.state.selectedReport.resultData, 'studentList.xlsx')
    } else if (this.state.selectedReport.name === 'Exercise Feedback') {
      await exportToXLSXWithTable('/reports/ExerciseFeedback/ExerciseFeedbackV11.zip', this.state.selectedReport.resultData, 'ExerciseFeedback.xlsx')
    } else if (this.state.selectedReport.name === 'Rig Competency Report') {
      await exportToXLSX('/reports/RigCompetencyReportV3/RigCompetencyReportV3.zip', this.state.selectedReport.resultData, 'RigCompetencyReport.xlsx')
    } else if (this.state.selectedReport.name === 'Student Competency Report') {
      await exportToXLSX('/reports/StudentCompetencyReportV3/StudentCompetencyReportV3.zip', this.state.selectedReport.resultData, 'StudentCompetencyReport.xlsx')
    } else {
      // STEP 1: Create a new workbook
      const wb = XLSX.utils.book_new()
      // STEP 3: Create worksheet with rows; Add worksheet to workbook
      const ws = XLSX.utils.aoa_to_sheet(this.fixConversion(this.state.selectedReport.resultData, true))
      XLSX.utils.book_append_sheet(wb, ws, this.state.selectedReport.name)

      XLSX.writeFile(wb, `${this.state.selectedReport.name}.xlsx`)
    }
  }
  render () {
    const { reports, loading, reportLoading, search, selectedReport, reportSearch } = this.state

    const filteredReports = searchData(reports, search, ['name', 'description'])
    const { match: { params: { reportId } } } = this.props
    const emptyReports = <p>No reports found</p>

    const loadingElement = (
      <Segment>
        <div className='tableLoadingSpinner'>
          <img alt='loading spinner' src={loadingImage} />
          <h4>Loading Reports</h4>
        </div>
      </Segment>
    )
    const COLORS = [
      '#4fae7c',
      '#b1342d',
      '#5d89ea',
      '#db7a3f',
      '#6d29b9',
      '#bbad57',
      '#5096b4',
      '#999ba1'
    ]
    return (
      <React.Fragment>
        {selectedReport && selectedReport.reportName ? <ScreenHeader backText={'Reports'} backLocation={'/reports'} reduxFilter table header={selectedReport.header} title={selectedReport.reportName} /> : ''}
        { selectedReport && selectedReport.name === 'Remedial Report' && (
          <React.Fragment>
            <ScreenSubHeader
              title={'Remedial Report'}
              goBackButtonUrl={'/reports'}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableGrouped
              name='Report Data'
              data={selectedReport}
              loading={reportLoading}
              firstHeaderOnGroup
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.name === 'Weekly Report' && (
          <React.Fragment>
            <ScreenSubHeader
              title={'Weekly Report'}
              goBackButtonUrl={'/reports'}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableGrouped
              name='Report Data'
              data={selectedReport}
              loading={reportLoading}
              firstHeaderOnGroup
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.name === 'Regional Student List' && (
          <React.Fragment>
            <ScreenSubHeader
              title={selectedReport.name}
              goBackButtonUrl={'/reports'}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableGrouped
              name='Report Data'
              data={selectedReport}
              loading={reportLoading}
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.name === 'Exercise Feedback' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableGrouped
              name='Report Data'
              data={selectedReport}
              loading={reportLoading}
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.name === 'Student Competency Report' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableGrouped
              name='Report Data'
              data={selectedReport}
              loading={reportLoading}
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.name === 'Rig Competency Report' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableGrouped
              name='Report Data'
              data={selectedReport}
              loading={reportLoading}
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.reportType === 0 && selectedReport.name !== 'Remedial Report' && selectedReport.name !== 'Regional Student List' && selectedReport.name !== 'Exercise Feedback' && selectedReport.name !== 'Rig Competency Report' && selectedReport.name !== 'Student Competency Report' && selectedReport.name !== 'Weekly Report' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
              search={reportSearch}
              searchUpdated={this.searchUpdated}
              actions={<ExportButton onClick={this.download} />}
            />
            <OtrTableUpgraded
              name='Report Data'
              data={selectedReport.data}
              loading={reportLoading}
              search={reportSearch}
              clientPaging
              hideColumns={['ID']}
            />
          </React.Fragment>
        )}
        { selectedReport && selectedReport.reportType === 100 && selectedReport.name !== 'Remedial Report' && selectedReport.name !== 'Regional Student List' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
            />
            <BarChart layout='vertical' width={700} height={75 * selectedReport.data.length} data={selectedReport.data} margin={{ top: 100, bottom: 100, right: 100, left: 100 }} >
              <XAxis type='number' tick={{ fill: '#ffffff' }} stroke='#ffffff' >
                <label value={selectedReport.dataKey} offset={0} position='bottom'stroke='#ffffff' />
              </XAxis>
              <YAxis type='category' dataKey={selectedReport.nameKey} tick={{ fill: '#ffffff' }} stroke='#ffffff' width={150} label={{ value: selectedReport.nameKey, position: 'top', stroke: '#ffffff' }} />
              <Bar layout='vertical' dataKey={selectedReport.dataKey} fill={COLORS[0]} />
              <Tooltip cursor={{ stroke: 'white', strokeWidth: 2, fill: '#ffffff10' }} />
            </BarChart>
          </React.Fragment>
        )}
        { selectedReport && selectedReport.reportType === 101 && selectedReport.name !== 'Remedial Report' && selectedReport.name !== 'Regional Student List' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
            />
            <BarChart layout='vertical' width={700} height={125 * selectedReport.data.length} data={selectedReport.data} margin={{ top: 50, bottom: 50, right: 50, left: 50 }} >
              <XAxis type='number' stroke='#ffffff' />
              <YAxis type='category' dataKey={selectedReport.dataKey} stroke='#ffffff' />
              <Tooltip cursor={{ stroke: 'white', strokeWidth: 2, fill: '#ffffff10' }} />
              <Legend formatter={(value, entry, index) => {
                const { color } = entry
                return <span style={{ color }}>{value}</span>
              }} />
              {selectedReport.subGroups.map((row, idx) => <Bar dataKey={selectedReport.subGroups[idx]} stackId='a' fill={COLORS[idx % COLORS.length]} />)}
            </BarChart>
          </React.Fragment>
        )}
        { selectedReport && selectedReport.reportType === 102 && selectedReport.name !== 'Remedial Report' && selectedReport.name !== 'Regional Student List' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
            />
            <BarChart layout='vertical' width={700} height={75 * selectedReport.data.length * selectedReport.subGroups.length} data={selectedReport.data} margin={{ top: 50, bottom: 50, right: 50, left: 50 }} >
              <XAxis type='number' stroke='#ffffff' />
              <YAxis type='category' dataKey={selectedReport.dataKey} stroke='#ffffff' />
              <Tooltip cursor={{ stroke: 'white', strokeWidth: 2, fill: '#ffffff10' }} />
              <Legend formatter={(value, entry, index) => {
                const { color } = entry
                return <span style={{ color }}>{value}</span>
              }} />
              {selectedReport.subGroups.map((row, idx) => <Bar dataKey={selectedReport.subGroups[idx]} fill={COLORS[idx % COLORS.length]} />)}
            </BarChart>
          </React.Fragment>
        )}
        { selectedReport && selectedReport.reportType == 103 && selectedReport.name !== 'Remedial Report' && selectedReport.name !== 'Regional Student List' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
            />
            <BarChart layout='horizontal' width={1000} height={500} data={selectedReport.data} margin={{ top: 50, bottom: 50, right: 50, left: 50 }} >
              <YAxis type='number' stroke='#ffffff' />
              <XAxis type='category' dataKey={selectedReport.dataKey} stroke='#ffffff' />
              <Tooltip cursor={{ stroke: 'white', strokeWidth: 2, fill: '#ffffff10' }} />
              <Legend formatter={(value, entry, index) => {
                const { color } = entry
                return <span style={{ color }}>{value}</span>
              }} />
              {selectedReport.subGroups.map((row, idx) => <Bar dataKey={selectedReport.subGroups[idx]} stackId='a' fill={COLORS[idx % COLORS.length]} />)}
            </BarChart>
          </React.Fragment>
        )}
        { selectedReport && selectedReport.reportType == 200 && selectedReport.name !== 'Remedial Report' && selectedReport.name !== 'Regional Student List' && (
          <React.Fragment>
            <ScreenSubHeader
              goBackButtonUrl={'/reports'}
              title={selectedReport.name}
            />
            <PieChart width={700} height={500}>
              <Pie data={selectedReport.data}
                nameKey={selectedReport.nameKey}
                dataKey={selectedReport.dataKey}
                cx='50%'
                cy='50%'
                fill='#8884d8'
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index
                }) => {
                  const RADIAN = Math.PI / 180
                  const radius = 25 + innerRadius + (outerRadius - innerRadius)
                  const x = cx + radius * Math.cos(-midAngle * RADIAN)
                  const y = cy + radius * Math.sin(-midAngle * RADIAN)
                  return (
                    <Text
                      x={x}
                      y={y}
                      fill='#ffffff'
                      textAnchor={x > cx ? 'start' : 'end'}
                      dominantBaseline='central' >
                      {selectedReport.data[index][selectedReport.nameKey]}
                    </Text>
                  )
                }
                }
              >
                {
                  selectedReport.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return { filtersByPage: state.filtersByPage }
}
const mapDispatchToProps = {
  setPageReportData
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Report))
