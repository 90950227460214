import React, { Component } from 'react'
import { Grid, Form, Label, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import apiSimulator from '../../../classes/apiSimulationServerSelector'
import InstructorServerForm from './InstructorServerForm'

export default class ServerInstructor extends Component {
  state = {
    data:[],
    errorMessage:null,
  }
  render () {
      return (
        <Grid style={{width:'100%'}}>
            <h3 style={{marginRight:"4rem",marginLeft:"4rem",color:"white"}}>Welcome to your iDrillSIM Dashboard.<br/> Please enter your email address, start/end duration of the required sessions and click Send to start.</h3>
            <InstructorServerForm></InstructorServerForm>
        </Grid>
      )
    }
}
