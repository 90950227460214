import React, { Component } from 'react'
import { Button, Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import { addValidationRule } from 'formsy-react'
import { Form } from 'formsy-semantic-ui-react'
import isValidCoordinates from 'is-valid-coordinates'
import OtrTextInput from '../../components/otrTextInput'
import OtrTextArea from '../../components/otrTextArea'
import OtrDropdown from '../../components/otrDropdown'
import ScreenHeader from '../../components/screenHeader'
import OtrLoadingSpinner from '../../components/otrLoadingSpinner'
import OtrModal from '../../components/otrModal'
import api from '../../classes/api'

import ScreenSubHeader from '../../components/screenSubHeader'
import { downloadCSV } from '../../classes/data'
import OtrTable from '../../components/otrTable'
import ExportButton from '../../components/exportButton'
import AddUserModal from './components/addUserModal'

import _ from 'lodash'
import OtrCheckbox from '../../components/otrCheckbox'

addValidationRule('isLatitude', (values, value) => {
  const floatVal = Number.parseFloat(value)
  if (Number.isNaN(floatVal)) {
    return false
  }
  return isValidCoordinates.latitude(floatVal)
})

addValidationRule('isLongitude', (values, value) => {
  const floatVal = Number.parseFloat(value)
  if (Number.isNaN(floatVal)) {
    return false
  }
  return isValidCoordinates.longitude(floatVal)
})

export default class DeviceScreen extends Component {
  state = {
    errors: [],
    loading: false,
    saveLoading: false,
    usersLoading: false,
    environmentLoading: false,
    device: {
      id: '',
      name: '',
      notes: '',
      latitude: 0,
      longitude: 0,
      rigName: '',
      environments: [],
      deviceEnabled: false,
    },
    // New Components to add Users to Devices
    // Don't want all users, just device users
    allUsers: [],
    allEnvironments: [],
    usersSearch: '',
    users: {
      pageSize: 15,
      total: 0,
      data: []
    },
    isCheckboxClicked: false
  }

  toggleLoading (key) {
    const loading = this.state[key]
    this.setState({ [key]: (!loading) })
  }

  async getDeviceData () {
    this.toggleLoading('loading')
    const { deviceId } = this.props.match.params
    const result = await api.otrById(deviceId)
    this.toggleLoading('loading')
    if (result.success) {
      console.log(result.data)
      this.setState({ device: result.data, isCheckboxClicked: result.data.deviceEnabled})
    }
  }

  async getEnvironmentData () {
    this.toggleLoading('environmentLoading')
    const result = await api.allEnvironments()
    this.toggleLoading('environmentLoading')
    if (result.success) {
      console.log("Environments Result", result.data)
      let allEnvironments = [];

      result.data.forEach(environment => {
        allEnvironments.push(environment);
      });

      console.log("Processed", allEnvironments)

      this.setState({allEnvironments})
      }
  }

  async getDeviceUsers() {
    this.toggleLoading('usersLoading')
    const { deviceId } = this.props.match.params
    const result = await api.allUsers()
    this.toggleLoading('usersLoading')
    if (result.success) {
      this.setState({ allUsers: result.data.data })

      // Filters out the Device Users
      let deviceUsers = this.state.allUsers.filter(x => x.authorizedDevices.some(y => y.id == deviceId))
      let storedDeviceUsers = 
      { 
        pageSize: 15, 
        total: deviceUsers.length,
        data: deviceUsers
      };

      this.setState({ users: storedDeviceUsers})
    }

  }

  componentDidMount () {
    this.getDeviceData()
    this.getEnvironmentData()
    this.getDeviceUsers()
  }  

  updateDevice = async ({ name, rigName, latitude, longitude, notes, deviceEnabled }) => {
    this.toggleLoading('saveLoading')
    const { deviceId } = this.props.match.params
    const result = await api.updateOtr({ id: deviceId, name, rigName, latitude, longitude, notes, deviceEnabled})
    if (result.success) {
      this.setState({ device: { ...this.state.device, name } })
    } else {
      this.setState({ errors: result.data || ['We had a problem saving this device'] })
    }
    this.toggleLoading('saveLoading')
  }

  // New Bits for the User Aspect
  searchUpdated = (event, data) => {
    this.setState({ usersSearch: data.value })
  }

  // May not be used, keep that in mind and can remove.
  async getUsers () {
    const { deviceId } = this.props.match.params
    const result = await api.devicesGetUsers(deviceId)
    if (result.success) {
      this.setState({ deviceUsers: result.data.users })
    }
  }

  removeClicked = async (user) => {
    const { deviceId } = this.props.match.params
    await api.devicesRemoveUser({ userId: user.id, otrUnitId: deviceId})
    this.getDeviceUsers()
  }

  addUser = async ({ user }) => {
    const { deviceId } = this.props.match.params
    await api.devicesAddUser({ userId: user, otrUnitId: deviceId })
    this.getDeviceUsers()
  }

  showUserRemoveConfirm = () => {
    this.setState({ userRemoveOpen: true })
  }

  hideUserRemoveConfirm = () => {
    this.setState({ userRemoveOpen: false })
  }

  userRemoveClicked = (employeeId) => {
    this.showUserRemoveConfirm()
  }

  removeUser = () => {
    this.hideUserRemoveConfirm()
  }

  download = () => {
    const { device } = this.state;
    let deviceName = "otr-" + device.name + "-users";
    console.log(deviceName);
    downloadCSV(this.state.users.data, JSON.stringify(deviceName))
  }

  environmentChange = async (event, data) => {
    const { value } = data
    const { device } = this.state
    const deviceEnvironments = device.environments.map(u => u.id)

    const removals = _.difference(deviceEnvironments, value)
    const additions = _.difference(value, deviceEnvironments)
    
    for (let i = 0; i < removals.length; i += 1) {
      let result = await api.devicesRemoveEnvironment({ otrUnitId: device.id, environmentId: removals[i] })

      if (result.success)
      {
        const newEnvironments = value.map(v => ({ id: v }))
        this.setState({ device: { ...device, environments: newEnvironments } })

        console.log('success', this.state.device);

      }
    }

    for (let i = 0; i < additions.length; i += 1) {
      let result = await api.devicesAddEnvironment({ otrUnitId: device.id, environmentId: additions[i] })
      if (result.success)
      {
        const newEnvironments = value.map(v => ({ id: v }))
        this.setState({ device: { ...device, environments: newEnvironments } })
      }
    }


  }

  OnCheckedClick = () => {
    this.setState({isCheckboxClicked: !this.state.isCheckboxClicked});
  }

  // Existing

  render () {
    const { errors, device, saveLoading, loading, usersSearch, allUsers, users, allEnvironments, isCheckboxClicked } = this.state

    let avaliableUsers = allUsers.filter(x => !users.data.includes(x))
    //const environments = device.environments.map(u => {const container = {}; container.text = u.name; container.value = u.id; return container;})


    // Weird format returned from the Backend - Need to convert it... Because changing it breaks the other bits
    // HOUSE OF CARDS!
    const environments = allEnvironments.map(u => u.id)
    const allEnvs = allEnvironments.map(env => { const container = {};
    container.text = env.name;
    container.value = env.environmentId;
    return container;})

    // console.log(allEnvs)


    const actions = (
      <React.Fragment>
        <ExportButton onClick={this.download} />
        <AddUserModal users={avaliableUsers} validSubmit={this.addUser} />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <ScreenHeader
          title={device.name}
          backText='DEVICES'
          backLocation='/devices/'
        />
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <Grid>
          <GridRow>
            <GridColumn width={16}>
              <Segment>
                {loading && (<OtrLoadingSpinner shown name='Device Information' />)}
                {!loading && (
                  <Form onValidSubmit={this.updateDevice}>
                    <Grid>
                      {/*<OtrTextInput title='ID' name='id' value={device.id} disabled />*/}
                      <OtrTextInput title='Name' name='name' value={device.name} />
                      <OtrTextInput title='Rig Name' required={false} name='rigName' value={device.rigName} />
                      <OtrTextInput title='Latitude' name='latitude' value={device.latitude} validations='isLatitude' validationErrors={{ isLatitude: 'Latitude is not valid' }} />
                      <OtrTextInput title='Longitude' name='longitude' value={device.longitude} validations='isLongitude' validationErrors={{ isLongitude: 'Longitude is not valid' }} />
                      <OtrTextArea title='Notes' required={false} name='notes' value={device.notes} />
                      <OtrDropdown title='Environments' name='environments' options={allEnvs} multiple value={environments} onChange={this.environmentChange} />
                      <OtrCheckbox title="Device Enabled" name="deviceEnabled" value={this.state.device.deviceEnabled}/>
                      <GridRow>
                        <GridColumn>
                          <Button loading={saveLoading} primary type='submit'>SAVE</Button>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </Form>
                )}
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
        <ScreenSubHeader
          title='DEVICE USERS'
          search={usersSearch}
          searchUpdated={this.searchUpdated}
          actions={actions}
        />
        <OtrTable
          data={users.data}
          name='Users'
          loading={saveLoading}
          search={usersSearch}
          hideColumns={["ID",
                        "CUSTOM DATA NAME 001","CUSTOM DATA VALUE 001",
                        "CUSTOM DATA NAME 002","CUSTOM DATA VALUE 002",
                        "CUSTOM DATA NAME 003","CUSTOM DATA VALUE 003",
                        "CUSTOM DATA NAME 004","CUSTOM DATA VALUE 004",
                        "CUSTOM DATA NAME 005","CUSTOM DATA VALUE 005"]}
          filteredColumns={['groups', 'jobRoles']}
          rowRemove={this.removeClicked}
          totalRows={users.total}
          pageSize={users.pageSize}
        />
      </React.Fragment>
    )
  }
}
