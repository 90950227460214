import React, { Component } from 'react'
import { Grid, GridColumn } from 'semantic-ui-react'
import ReportIcon from '../../../components/reportIcon'

const colors = [
  '#4fae7c',
  '#b1342d',
  '#5d89ea',
  '#db7a3f',
  '#6d29b9',
  '#bbad57',
  '#5096b4',
  '#999ba1'
]

export default class ReportBar extends Component {
  renderRows = () => {
    const { data = [], onClick } = this.props
    console.log(data)
    return data.sort((a, b) => {
      if (a.reportRank < b.reportRank) return -1
      if (a.reportRank > b.reportRank) return 1
      return 0
    }).map(({ id, name, description, selected = false, icon = 'calendar' }, idx) => (
      <GridColumn computer={16} key={id} style={{ paddingBottom: '0.3rem', paddingTop: '0.2rem' }}>
        <ReportIcon
          id={id}
          title={name}
          subtitle={description}
          icon={icon}
          color={colors[idx % colors.length]}
          selected={selected}
          onClick={onClick}
        />
      </GridColumn>
    ))
  }
  render () {
    const rows = this.renderRows()
    return (
      <Grid divided={false} stackable doubling style={{ marginTop: '0.5rem', marginBottom: '0.5em' }}>
        {rows}
      </Grid>
    )
  }
}
