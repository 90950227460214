import React, { Component } from 'react'
import { Grid, Header } from 'semantic-ui-react'

export default class fourofour extends Component {
  render () {
    return (
      <Grid id='loginGrid' padded='horizontally'>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column mobile={16} tablet={10} largeScreen={5} computer={3} style={{ textAlign: 'center' }}>
            <Header style={{ fontSize: '10rem' }} inverted>404</Header>
            <Header as='h1' inverted>OOPS, IT LOOKS LIKE YOU WERE SENT TO A MISSING PAGE.</Header>
            <a href='/'>CLICK HERE TO GO BACK HOME</a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
    )
  }
}
