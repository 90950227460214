import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import MenuLink from './menuLink'

export default class ViewReportsMenuItems extends Component {
  render () {
    const { filters, devices } = this.props
    return (
      <React.Fragment>
        <Grid.Row>
          <MenuLink to='/' title='Dashboard' />
        </Grid.Row>
        {/* {
          filters.map(filter => (
            <Grid.Row className='filterMenuItem' key={filter.id}>
              <MenuLink to={`/filter/${filter.id}`} exact={false} title={filter.name} />
            </Grid.Row>
          ))
        } */}
           {devices.map((device) => {
          // Pretty sure the version of JS doesn't support string interpolation ${}
          const titleName = device.rigName + " (" + device.name + ")"
          return (
          <Grid.Row className="filterMenuItem" key={device.id}>
            <MenuLink
              to={`/otr/${device.id}`}
              exact={false}
              title={titleName}
            />
          </Grid.Row>
          )}

        )}
        <Grid.Row>
          <MenuLink to='/reports/' exact={false} title='Reports' />
        </Grid.Row>
      </React.Fragment>
    )
  }
}
