/* eslint-disable default-case */
import { ADD_PAGE_FILTER, REMOVE_PAGE_FILTER, RESET_PAGE_FILTER, SET_PAGE_REPORT_DATA, SET_PAGE_DATA } from '../actions/actionTypes'
import { getFiltersOfPage } from '../../classes/filterHelper'

const initialState = {
  filtersByPage: [],
  reportData: {},
  pageData: {}
}

function rootReducer (state = initialState, action) {
  switch (action.type) {
    case ADD_PAGE_FILTER:
      if (state.filtersByPage.length > 0) {
        const pageFilters = getFiltersOfPage(state.filtersByPage, action.payload.page)
        if (pageFilters && pageFilters.length > 0) {
          const foundPageFilters = pageFilters.filter(pf => pf.filterOptions.headerValue === action.payload.filterOptions.headerValue)
          if (foundPageFilters.length > 0) {
            return Object.assign({}, state, {
              filtersByPage: state.filtersByPage.map((filter, index) => {
                if (index !== state.filtersByPage.indexOf(foundPageFilters[0])) {
                  return filter
                } else {
                  return Object.assign({}, filter, action.payload)
                }
              })
            })
          }
        }
      }
      return Object.assign({}, state, {
        filtersByPage: state.filtersByPage.concat(action.payload)
      })
    case REMOVE_PAGE_FILTER:
      if (state.filtersByPage.length > 0){
        let foundFilters = state.filtersByPage.filter(pf => pf.page === action.payload.page && pf.filterOptions.headerValue === action.payload.filterName);
        if (foundFilters.length > 0){
          return Object.assign({}, state, {
            filtersByPage: state.filtersByPage.filter(pf => !foundFilters.includes(pf))
          })
        }
      }
      break
    case SET_PAGE_REPORT_DATA:
      return Object.assign({}, state, {
        reportData: action.payload
      })
    case SET_PAGE_DATA:
      return Object.assign({}, state, {
        pageData: action.payload
      })
    case RESET_PAGE_FILTER:
      return Object.assign({}, state, {
        filtersByPage: state.filtersByPage.filter(fBp => fBp.page !== action.payload)
      })
  }
  return state
}
export default rootReducer
