import React, { Component } from 'react'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import ScreenHeader from '../../components/screenHeader'
import OtrTable from '../../components/otrTable'
import ExportButton from '../../components/exportButton'
import moment from 'moment'

export default class Settings extends Component {
  constructor (props) {
    super(props)
    this.settingClicked = this.settingClicked.bind(this)
    this.state = {
      search: '',
      loading: false,
      settings: []
    }
  }

  searchUpdated = (event, data) => {
    this.setState({ search: data.value })
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  async getData () {
    this.toggleLoading()
    const result = await api.getSettings()
    if (result.success) {
      this.setState({ settings: result.data.settings.map( row => (row.lastUpdated ? {...row, lastUpdated:moment(row.lastUpdated).format('DD-MM-YYYY - HH:mm:ss')} : row))})
      this.toggleLoading()
    }
  }
  
  componentDidMount () {
    this.getData()
  }

  settingClicked = ({ id }) => {
    this.props.history.push(`/settings/${id}`)
  }

  // Don't really need to be able to export this
  download = () => {
    downloadCSV(this.state.settings, 'otr-settings')

  }

  render () {
    const { settings, search, loading } = this.state
    return (
      <React.Fragment>
        <ScreenHeader
          title='SETTINGS'
          actions={<ExportButton onClick={this.download} />}
          search={search}
          searchUpdated={this.searchUpdated}
        />
        <OtrTable
          data={settings}
          hideColumns={["ID", "TYPE", "CURRENT VERSION HASH", "REQUESTED VERSION HASH", "OTR FILTER ID"]}
          name='Settings'
          loading={loading}
          rowClicked={this.settingClicked}
          search={search}
        />
      </React.Fragment>
    )
  }
}
