import React, { Component } from 'react'
import OtrTextInput from '../../../components/otrTextInput'
import { Button, Form, Grid, GridColumn } from 'semantic-ui-react'
import OtrDatePicker from '../../../components/otrDatePicker'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import moment from 'moment'
import apiSimulator from '../../../classes/apiSimulationServerSelector'
import Auth from '../../../services/auth'

export default class VmMachineUpdateRow extends Component {
  state = {
    startDate:null,
    endDate: null,
    email: null,
    name:null,
    loading:false,
    startDateError:"",
    endDateError:"",
    errorEmail:""
  }
  constructor (props) {
    super(props)
    this.handleOnStartDateChanged=this.handleOnStartDateChanged.bind(this);
    this.handleOnEndDateChanged=this.handleOnEndDateChanged.bind(this);
    this.handleOnEmailChange=this.handleOnEmailChange.bind(this);
    this.handleOnclickSend=this.handleOnclickSend.bind(this);
  }

  componentDidMount () {
    let startDate
    if(this.props.vmMachine.windowStart!==null){
      startDate= new Date(this.props.vmMachine.windowStart)
    }
    let endDate
    if(this.props.vmMachine.windowEnd!==null){
      endDate= new Date(this.props.vmMachine.windowEnd)
    }
    this.setState({startDate,endDate,email:this.props.vmMachine.launchUserId})
  }
  handleOnEmailChange(e){
    this.setState({email:e.target.value});
    this.props.vmMachine.launchUserId= e.target.value
  }
  handleOnStartDateChanged (d){
    if(d!==null){
      d.setHours(0,0,0,0)
    }
    this.setState({startDate:d});
    if(d!==null){
      let Month=d.getMonth()+1
      Month=("0"+Month).slice(-2)
      let Date = ("0"+d.getDate()).slice(-2)
      this.props.vmMachine.windowStart=d.getFullYear()+"-"+Month+"-"+Date
    }else{
      this.props.vmMachine.windowStart=null
    }
  }
  handleOnEndDateChanged (d){
    if(d!==null){
      d.setHours(0,0,0,0)
    }
    this.setState({endDate:d});
    if(d!==null){
      let Month=d.getMonth()+1
      Month=("0"+Month).slice(-2)
      let Date = ("0"+d.getDate()).slice(-2)
      this.props.vmMachine.windowEnd=d.getFullYear()+"-"+Month+"-"+Date
    }else{
      this.props.vmMachine.windowEnd=null
    }
  }
  checkForErrors(machineId,startDate,endDate,email){
    let dateNow=new Date()
    dateNow.setHours(0,0,0,0)
    let errorStartDate=""
    let errorEndDate=""
    let errorEmail=""
    let errorEmailDiv=document.getElementById(machineId+"-errorEmail")
    let errorStartDateDiv=document.getElementById(machineId+"-startDateError")
    let errorEndDateDiv=document.getElementById(machineId+"-endDateError")
    errorEmailDiv.innerText="";
    errorStartDateDiv.innerText="";
    errorEndDateDiv.innerText="";
    if(email==="" || email===null){
      errorEmail="Email can't be empty."
    }
    if(startDate===null){
      errorStartDate="Start date can't be empty."
    }else if(startDate>endDate){
      errorStartDate="Start date can't be after end Date."
    }
    if(endDate===null){
      errorEndDate="End date can't be empty."
    } else if(dateNow>endDate){
      errorEndDate="End date can't be before today."
    }
    errorEmailDiv.innerText=errorEmail;
    errorStartDateDiv.innerText=errorStartDate;
    errorEndDateDiv.innerText=errorEndDate;
    return !(errorEndDate !== "" || errorStartDate !== "" || errorEmail !== "");
  }
  handleOnclickSend(){
    console.log(this.props.vmMachine);
    let errorCheck=this.checkForErrors(this.props.vmMachine.id,this.state.startDate,this.state.endDate,this.state.email);
    if(errorCheck){
      let setVMByIdDto = {
        customerVmId:this.props.vmMachine.id,
        userEmail:this.props.vmMachine.launchUserId,
        startTime:this.props.vmMachine.windowStart,
        endTime:this.props.vmMachine.windowEnd,
      }
      let token = Auth.isAuthenticated().token
      this.setState({loading:true})
      apiSimulator.UpdateInstructorCustomerVMById(token,setVMByIdDto).then((response)=>{
        this.setState({loading:false})
        if(response.success){
          this.props.setMessage(response.data)
        }else{
          this.props.setMessage(response.data)
        }
      })
    }else{
      this.setState({loading:false})
    }
  }
  render () {
    return (<div style={{display:'flex', marginBottom:"1rem"}}>
          <div style={{display:'flex',width:"30rem" }}>
            <h3 style={{textAlign:"center",color:"white",paddingTop:"1.4rem"}}>{this.props.vmMachine.vmFriendlyName}</h3>
          </div>
        <div style={{marginRight:"1rem"}}>
          <label style={{color:"white"}}>Email</label>
          <Form.Input onChange={this.handleOnEmailChange} value={this.state.email} />
          <div id={this.props.vmMachine.id+"-errorEmail"} style={{height:"14px",color:"red", fontSize:"0.9rem"}}>
            {this.state.errorEmail}
          </div>
        </div>
        <div style={{display:'flex',flexDirection:"column",marginRight:"1rem"}}>
          <label style={{color:"white"}}>Start Date</label>
          <div>
            <SemanticDatepicker onDateChange={this.handleOnStartDateChanged} id={"StartDAte"}  selected={this.state.startDate}  format={'DD/MM/YYYY'} name={"startDAte"} type={'basic'} />
          </div>
          <div id={this.props.vmMachine.id+"-startDateError"} style={{height:"14px",color:"red", fontSize:"0.9rem"}}>
            {this.state.startDateError}
          </div>
        </div>
        <div style={{display:'flex',flexDirection:"column",marginRight:"1rem"}}>
          <label style={{color:"white"}}>End Date</label>
          <div>
            <SemanticDatepicker id={"endDate"}  format={'DD/MM/YYYY'} selected={this.state.endDate} name={"endDate"} type={'basic'}  onDateChange={this.handleOnEndDateChanged}/>
          </div>
          <div id={this.props.vmMachine.id+"-endDateError"} style={{height:"14px",color:"red", fontSize:"0.9rem"}}>
            {this.state.endDateError}
          </div>
        </div>
        <div style={{display:'flex',paddingTop:"1rem",paddingBottom:'14px'}}>
          <Button loading={this.state.loading} positive={true} onClick={this.handleOnclickSend}>Send</Button>
        </div>
    </div>
    )
  }
}