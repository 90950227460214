import React, { Component } from 'react'
import { Grid, Form, Label, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import apiSimulator from '../../../classes/apiSimulationServerSelector'
import auth from '../../../services/auth'

export default class ServerSelector extends Component {
  state = {
    data:[],
    regionSelected:null,
    errorMessage:null,
    machineReadyUrl:null,
  }
  componentDidMount () {
    apiSimulator.getRegionsWithVmMachines().then((response)=>{
      if(response.success){
        this.setState({data:response.data})
      }
    })

  }
  handleOnRegionSelected(region){
    apiSimulator.checkIfRegionHasAvailableMachines(1,region).then((res)=>{
      if(res.success){
        var token = auth.isAuthenticated().token
        apiSimulator.getAvailableMachineByRegion(region,1,token).then((response)=>{
          if(response.success){
            setTimeout(()=>{
              window.open(response.data, "_blank");
              this.setState({errorMessage:"Opened in a new Tab",machineReadyUrl:response.data})
            },5000)
            apiSimulator.getRegionsWithVmMachines().then((response)=>{
              if(response.success){
                this.setState({data:response.data})
              }
            })
          }else{
            if(response.error.response.data){
              this.setState({errorMessage:response.error.response.data+""})
              apiSimulator.getRegionsWithVmMachines().then((response)=>{
                if(response.success){
                  this.setState({data:response.data})
                }
              })
            }else{
              this.setState({errorMessage:"Error trying to get a machine for this Region"})
              apiSimulator.getRegionsWithVmMachines().then((response)=>{
                if(response.success){
                  this.setState({data:response.data})
                }
              })
            }
          }
        })
      }else {
        this.setState({regionSelected:null})
        apiSimulator.getRegionsWithVmMachines().then((response)=>{
          if(response.success){
            this.setState({data:response.data})
          }
        })
      }
    })
  }
  renderAvailabilityText(availability){
    if (availability){
      return "AVAILABLE";
    }else{
      return "UNAVAILABLE";
    }
  }
  renderRows = () => {
    let data = this.state.data
    return data.map(({ id, regionDescription , availability}, idx) => {
      if(availability){
        return (
            <GridColumn computer={16} key={id} style={{ paddingBottom: '0.3rem', paddingTop: '0.2rem' }}>
              <Segment className='reportIcon' onClick={()=>{this.setState({regionSelected:id});this.handleOnRegionSelected(id)}} inverted style={{ backgroundColor: this.state.regionSelected===id?'rgba(255,255,255, 0.2)':'rgba(255,255,255, 1)' }}>
                <div>
                  <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', color: 'gray' }}>
                    <h4 className='uppercase' style={{ margin: 0, alignSelf: 'center', display: 'inline', width: '24%', fontSize: '1.5rem' }}>{regionDescription}</h4>
                    <p style={{ paddingLeft: '1rem', flex: 1, height: '100%', fontSize: '1.2rem', margin: '0px', paddingTop: '0.5rem', fontWeight: '800', color: 'gray' }}>{}</p>
                    <div style={{ float: 'right',height: '100%' ,paddingTop: '0.4rem'}} >
                      <h4 style={{color:"green"}}>{
                        this.renderAvailabilityText(availability)
                      }</h4>
                    </div>
                  </div>
                </div>
              </Segment>
            </GridColumn>
        )
      }else{
        return (
            <GridColumn computer={16} key={id} style={{ paddingBottom: '0.3rem', paddingTop: '0.2rem' }}>
              <Segment className='reportIcon' inverted style={{ backgroundColor: 'rgba(255,255,255, 0.2)' }}>
                <div>
                  <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', color: 'gray' }}>
                    <h4 className='uppercase' style={{ margin: 0, alignSelf: 'center', display: 'inline', width: '24%', fontSize: '1.5rem' }}>{regionDescription}</h4>
                    <p style={{ paddingLeft: '1rem', flex: 1, height: '100%', fontSize: '1.2rem', margin: '0px', paddingTop: '0.5rem', fontWeight: '800', color: 'gray' }}>{}</p>
                    <div style={{ float: 'right',height: '100%' ,paddingTop: '0.4rem'}} >
                      <h4 style={{color:"red"}}>{
                        this.renderAvailabilityText(availability)
                      }</h4>
                    </div>
                  </div>
                </div>
              </Segment>
            </GridColumn>
        )
      }

    }
    )
  }
  render () {
    const rows = this.renderRows()
    if(this.state.machineReadyUrl){
      return (
          <div className="ui segment" style={{height:"60%"}}>
            <div className="ui active inverted dimmer">
              <div className="ui text " style={{color:"white",fontSize:'1.4rem',fontStyle:""}}>
                 Your machine is ready! <a target={"_blank" } href={this.state.machineReadyUrl}>Click Here</a> to open it if it didn't open automatically.
              </div>
              <p style={{cursor:"pointer",marginTop:"2rem",color:"#66f8ff"}} onClick={()=>{this.setState({machineReadyUrl:null})}}>Go back to server Selection </p>
            </div>
            <p></p>
          </div>
      )
    }else if(this.state.regionSelected && this.state.errorMessage===null){
      return (
        <div className="ui segment" style={{height:"60%"}}>
          <div className="ui active inverted dimmer">
            <div className="ui text loader" style={{color:"white",fontSize:'2rem',fontStyle:""}}>DrillSIM Academy will open in a new browser tab, please allow up to 1-2 minutes.</div>
          </div>
          <p></p>
        </div>
      )
    } else if (this.state.errorMessage){
      return (
        <Grid>
          <h1 style={{color:'white'}} >{this.state.errorMessage} Please Select another Region</h1>
          <Grid divided={false} stackable doubling style={{ marginTop: '0.5rem', marginBottom: '0.5em' ,width:'100%'}}>
            {rows}
          </Grid>
        </Grid>
        )
    } else{
      return (
        <Grid>
          <h2 style={{color:'white'}}>To launch the DrillSIM Academy Cloud Simulator,<br/> please click below on the region closest to your location</h2>
          <Grid divided={false} stackable doubling style={{ marginTop: '0.5rem', marginBottom: '0.5em' ,width:'100%'}}>
            {rows}
          </Grid>
        </Grid>
      )
    }

  }
}
