import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import OtrModal from '../../../components/otrModal'
import OtrDropdown from '../../../components/otrDropdown'

export default class AddUserModal extends Component {
  state = { modalOpen: false }
  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

  validSubmit = (data) => {
    this.handleClose()
    this.props.validSubmit(data)
  }

  render () {
    const { users } = this.props
    const userOptions = users.map((user) => ({
      value: user.id,
      text: `${user.firstName} ${user.lastName}`
    }))
    return (
      <OtrModal
        title='ADD USER'
        trigger={<Button primary onClick={this.handleOpen}>ADD</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Form onValidSubmit={this.validSubmit}>
          <Grid>
            <OtrDropdown title='User' name='user' options={userOptions} />
            <Grid.Row style={{ textAlign: 'right', paddingTop: '1.5rem' }}>
              <Grid.Column>
                <Button primary style={{ margin: 0 }}>
                  SAVE
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </OtrModal>
    )
  }
}
