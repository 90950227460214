import React, { Component } from 'react'
import '../styles/menu.css'
import { Grid } from 'semantic-ui-react'
import otrLogo from '../img/otr_logo_square.svg'
import drillingSystemsLogo from '../img/drilling_systems_logo.svg'
import AdminMenuItems from './adminMenuItems'
import StudentMenuItems from './studentMenuItems'
import ViewReportsMenuItems from './viewReportsMenuItems'
import auth from '../services/auth'
import userType from '../classes/userType'
import api from '../classes/api'
import { withRouter } from 'react-router-dom'

class Menu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      logoSrc: null,
      showMenu: false
    }
    this.startPressTimer = this.startPressTimer.bind(this)
    this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
    this.handleOnTouchStart = this.handleOnTouchStart.bind(this)
    this.handleOnTouchEnd = this.handleOnTouchEnd.bind(this)
    this.handleOnMouseUp = this.handleOnMouseUp.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.timerRef = React.createRef()
    this.isLongPress = React.createRef()
    this.getLogo()
  }
  async getLogo () {
    if (!this.state.logoSrc) {
      let meta = await api.meta()
      let logo = meta.data ? meta.data.logo || otrLogo : otrLogo
      this.setState({ logoSrc: logo })
    }
  }
  handleOnClick () {
    if (!this.isLongPress.current) {
      this.setState({ showMenu: false })
      this.props.history.push('/')
    }
  }
  handleOnMouseUp () {
    clearInterval(this.timerRef.current)
  }
  handleOnMouseDown () {
    this.startPressTimer()
  }
  handleOnTouchStart () {
    this.startPressTimer()
  }
  handleOnTouchEnd () {
    clearInterval(this.timerRef.current)
  }
  startPressTimer () {
    this.isLongPress.current = false
    this.timerRef.current = setTimeout(() => {
      this.setState({ showMenu: !this.state.showMenu })
      this.isLongPress.current = true
    }, 1000)
  }
  render () {
    const result = auth.getUserLevel()
    const { filters = [], environments = [], devices = [] } = this.props
    let element = null
    if (result.success) {
      let userLevel = userType.highestUserLevel(result.userLevel)
      switch (userLevel) {
        case 'UserManagement':
          element = <AdminMenuItems filters={filters} environments={environments} devices={devices} />
          break
        case 'Student':
          element = <StudentMenuItems />
          break
        case 'ViewReports':
          element = <ViewReportsMenuItems filters={filters} devices={devices} />
          break
        default:
          element = null
      }
    }
    const displayLogo = (this.state.logoSrc) ? 'visible' : 'hidden'

    return (
      <Grid.Column style={{ paddingRight: 0 }} mobile={4} tablet={4} computer={3} largeScreen={3}>
        <Grid.Row style={{ minHeight: '7rem', position: 'relative', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <img alt='Tenant logo' src={this.state.logoSrc} style={{ height: '6rem', visibility: displayLogo, marginRight: '0.5rem' }}
            onClick={this.handleOnClick}
          />
          <div style={{ position: 'absolute', zIndex: 50, backgroundColor: '#011c36', display: this.state.showMenu ? 'block' : 'none', left: '10%', top: '90%' }}>
            {element}
          </div>
        </Grid.Row>
        <img alt='Drilling Systems logo' src={drillingSystemsLogo} style={{ width: '6rem', position: 'fixed', bottom: '1.5rem', left: '2rem' }} />
      </Grid.Column>
    )
  }
}
export default withRouter(Menu)
