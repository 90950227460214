import React, { Component } from 'react'
import { Button, Modal, Header, GridColumn, Grid } from 'semantic-ui-react'

export default class OtrModal extends Component {
  render () {
    const { title, children, trigger, open, onClose, actions } = this.props
    return (
      <Modal
        trigger={trigger}
        open={open}
        onClose={onClose}
        style={{ padding: '1rem' }}
        //actions={actions}
      >
        <Modal.Content>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', borderBottom: '2px solid #dee3ec', paddingBottom: '1rem', marginBottom: '2.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start'}}>
              <Header as='h2' style={{ margin: 0, color: '#0A379D', paddingRight: '1rem'}}>{title}</Header>
              {actions}
            </div>
            <Button color='red' float='right'  className='modalCloseButton' onClick={onClose} />
          </div>
          {children}
        </Modal.Content>
      </Modal>
    )
  }
}
