import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class OtrCheckboxFilter extends Component {  
  state = {  
    isChecked: this.props.isActive || false
  }
  render () {
    const { title, name, disabled = false, isActive = false, value, validations, validationErrors, size = 'medium', placeholder = '', type = 'checkbox' } = this.props
    const { isChecked } = this.state;
    const inputDisabled = disabled || !isChecked; 
    const boolValue = String(value).toLowerCase()==='true'
    return (
      <Grid.Row>
          <Grid.Column width={1} style={{ 'alignContent': 'center' }}>
            <Form.Checkbox 
              name={`selected[${name}]`}
              disabled={disabled}
              checked={isChecked}
              defaultChecked={isActive}
              toggle
              onChange={(e, data) => this.setState({ isChecked: !isChecked })}
            />
        </Grid.Column>
        <Grid.Column width={4} style={{ alignContent: 'center', paddingInlineStart: '1.5rem' }}>
          <label>{title}</label>
        </Grid.Column>
        <Grid.Column width={11} style={{ alignContent: 'center', paddingLeft: '0rem' }}>
          <Form.Checkbox
            name={`value[${name}]`}
            placeholder={placeholder}
            size={size}
            disabled={inputDisabled}
            instantValidation
            validations={validations}
            validationErrors={{
              ...validationErrors,
              isDefaultRequiredValue: `${(title || placeholder)} is Required`
            }}
            errorLabel={<div className='errorLabel' icon='close' color='red' />}
            type={type}
            defaultChecked={boolValue}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
