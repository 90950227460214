import moment from 'moment'
import api from '../classes/api'
import OtrDropdownFilter from '../components/otrDropdownFilter'
import OtrDatePicker from '../components/otrDatePicker'
import OtrTextInputFilter from '../components/otrTextInputFilter'
import OtrNumberInputFilter from '../components/otrNumberInputFilter'
import OtrCheckboxFilter from '../components/otrCheckboxFilter'

export const NoneOption = "None";

export const getFilterOptionDefaultComponent = (type) => {
  switch (type){
    case 'Decimal':
      return OtrNumberInputFilter;
    case 'Int32':
      return OtrNumberInputFilter;      
    case 'Boolean':
      return OtrCheckboxFilter;      
    case 'DateTimeOffset':
      return OtrDatePicker;
    case 'Array':
      return OtrDropdownFilter;
    case 'Object':
      return OtrDropdownFilter;
    default:
      return OtrTextInputFilter;
  }
}

export const defaultFilterOptions = {
  'region': {
    name: 'Region',
    headerValue: 'region',
    dataPull: true,
    endPoint: api.getAllRigs,
    type: 'includes',
    dataType: 'String',
    controlComponent: OtrDropdownFilter
  },
  'competency': {
    name: 'Competency',
    headerValue: 'competency',
    type: '>',
    dataType: 'Decimal',
    controlComponent: OtrNumberInputFilter,
    outputModifier: (x) => x * 100,
    inputModifier: (x) => x / 100
  },
  'compliance': {
    name: 'Compliance',
    headerValue: 'compliance',
    type: '>',
    dataType: 'Decimal',
    controlComponent: OtrNumberInputFilter,
    outputModifier: (x) => x * 100,
    inputModifier: (x) => x / 100
  },
  'registereddevices': {
    name: 'Num. Devices',
    headerValue: 'registeredDevices',
    type: '>',
    dataType: 'Int32',
    controlComponent: OtrNumberInputFilter
  },
  'registeredusers': {
    name: 'Num. Registered Users',
    headerValue: 'registeredUsers',
    type: '>',
    dataType: 'Int32',
    controlComponent: OtrNumberInputFilter
  },
  'activeusers': {
    name: 'Num. Active Users',
    headerValue: 'activeUsers',
    type: '>',
    dataType: 'Int32',
    controlComponent: OtrNumberInputFilter
  },
  'accountenabled': {
    name: 'Account Enabled',
    headerValue: 'accountEnabled',
    type: '=',
    dataType: 'Boolean',
    controlComponent: OtrCheckboxFilter,
    outputModifier: (x) => x === '' ? true : x,
    inputModifier: (x) => String(x)
  },
  'active': {
    name: 'Rig Active',
    headerValue: 'active',
    type: '=',
    dataType: 'Boolean',
    controlComponent: OtrCheckboxFilter,
    outputModifier: (x) => x === '' ? true : x,
    inputModifier: (x) => String(x)
  }, 
  'deviceenabled': {
    name: 'Device Enabled',
    headerValue: 'deviceEnabled',
    type: '=',
    dataType: 'Boolean',
    controlComponent: OtrCheckboxFilter,
    outputModifier: (x) => x === '' ? true : x,
    inputModifier: (x) => String(x)
  }, 
  'jobroles': {
    name: 'Job Roles',
    headerValue: 'jobRoles',
    type: 'includes',
    dataPull: true,
    endPoint: api.allJobRoles,
    dataType: 'Array',
    controlComponent: OtrDropdownFilter
  },
  'roles': {
    name: 'Roles',
    headerValue: 'roles',
    type: 'includes',
    dataPull: true,
    endPoint: api.getAppRoles,
    dataType: 'Array',
    controlComponent: OtrDropdownFilter
  },
  'rigs': {
    name: 'Rigs',
    headerValue: 'rigs',
    type: 'includes',
    dataPull: true,
    endPoint: api.getAllRigs,
    dataType: 'Array',
    controlComponent: OtrDropdownFilter
  },
  'currentrig': {
    name: 'Rig',
    headerValue: 'currentRig',
    type: 'includes',
    dataPull: true,
    endPoint: api.getAllRigs,
    dataType: 'Object',
    controlComponent: OtrDropdownFilter
  },
  'environments': {
    name: 'Environments',
    headerValue: 'environments',
    type: 'includes',
    dataPull: true,
    endPoint: api.allEnvironments,
    dataType: 'Array',
    controlComponent: OtrDropdownFilter
  },
  'emailaddress': {
    name: 'Email Address',
    headerValue: 'emailAddress',
    type: 'includes',
    dataType: 'String',
    controlComponent: OtrTextInputFilter
  },
  'users': {
    name: 'Num. Registered Users',
    headerValue: 'users',
    type: '>',
    dataType: 'Array',
    controlComponent: OtrNumberInputFilter
  },
  'lastupdated': {
    name: 'Last Updated',
    headerValue: 'lastUpdated',
    type: '>',
    dataType: 'DateTimeOffset',
    controlComponent: OtrDatePicker,
    outputModifier: (x) => x !== '' ? new Date(x) : x,
    inputModifier: (x) => String(x)
  },
  'installedappversion': {
    name: 'App Version',
    headerValue: 'installedAppVersion',
    dataPull: true,
    endPoint: api.getAllReleases,
    type: 'includes',
    dataType: 'AppVersion',
    controlComponent: OtrDropdownFilter
  }
}

export const filterOptionsByPageDefault = [
  {
    filterOptions: [
      defaultFilterOptions['active'],
      defaultFilterOptions['region'],
      defaultFilterOptions['competency'],
      defaultFilterOptions['compliance'],
      defaultFilterOptions['registereddevices'],
      defaultFilterOptions['registeredusers'],
      defaultFilterOptions['activeusers']
    ],
    page: '/'
  },
  {
    filterOptions: [
      defaultFilterOptions['accountenabled'],
      defaultFilterOptions['jobroles'],
      defaultFilterOptions['roles'],
      defaultFilterOptions['rigs'],
      defaultFilterOptions['environments'],
      defaultFilterOptions['emailaddress']
    ],
    page: '/users/'
  },
  {
    filterOptions: [
      defaultFilterOptions['deviceenabled'],
      defaultFilterOptions['currentrig'],
      defaultFilterOptions['users'],
      defaultFilterOptions['lastupdated'],
      defaultFilterOptions['environments'],
      defaultFilterOptions['installedappversion']
    ],
    page: '/devices/'
  }
]

export const defaultFilterOptionDataMapping = {
  'installedAppVersion':  (d) => d.version,
  'region':               (d) => d.rigType
}

export const defaultFilterOptionDataTypeModifiers = {
  'Boolean': function (params){
    params.value = (String(params.value).toLowerCase())
    params.other = (String(params.other).toLowerCase())
  },
  'DateTimeOffset': function (params){
    params.value = moment(params.value)
    params.other = moment(params.other)
  },
  'Object': function (params){
    if (params.value && params.value.name){
      params.value = params.value.name
    }
  },
  'AppVersion': function (params){
    if (params.value && params.value.version){
      params.value = params.value.version
    }
  }
}

export const defaultFilterOptionTypeComparers = {
  '=': function (value, other) {
    if (Array.isArray(value)){
      return value.length === Number(other)
    }
    
    if (value instanceof moment){
      if (other instanceof moment) {
        return value.isSame(other);
      }
    }

    return value && Object.is(value, other);
  },
  '>': function (value, other) {
    if (Array.isArray(value)){
      return value.length >= Number(other)
    } 
    
    if (value instanceof moment){
      if (other instanceof moment) {
        return value.isSameOrAfter(other);
      }
      return false;
    }

    return Number(value) >= Number(other);
  },
  '<': function (value, other) {
    if (Array.isArray(value)){
      return value.length <= Number(other)
    } 
    
    if (value instanceof moment){
      if (other instanceof moment) {
        return value.isSameOrBefore(other);
      }
      return false;
    }

    return Number(value) <= Number(other);
  },
  'includes': function (value, other) {
    if (Array.isArray(value)){
      if (String(other).toLowerCase()==="none") return value.length === 0
      return value.filter(x => defaultFilterOptionTypeComparers['includes'](x, other)).length > 0;
    }    

    // if we are comparing objects, true and get a name value    
    let a = value;
    let b = other;
    if (value instanceof Object && value.name){
      a = value.name
    }
    if (other instanceof Object && other.name){
      b = other.name
    }

    // if our values are strings, we will compare case insensitively
    if (typeof(a)==='string' || a instanceof String){
      a = a.toLowerCase();
    }
    if (typeof(b)==='string' || b instanceof String) {
      b = b.toLowerCase();
    }

    return a && !!a.includes(b);
  }
}

export const getFilterOptionValue = (filters, option) => {
  let value = getValueFromHeader(filters, option.headerValue)
  if (option.outputModifier instanceof Function){
    value = option.outputModifier(value);
  }
  return value;
}

export const setFilterOptionValue = (option, newValue) => {
  if (option.inputModifier instanceof Function){
    option.value = option.inputModifier(newValue);
    return true;
  }
  option.value = newValue;
  return false;
}

export function getFiltersOfPage (filters, page) {
  return filters.filter(f => f.page === page)
}

export function filterData (pageFilters, columns, rows) {
  pageFilters.forEach((pF) => {
    const indexOfHeader = columns.indexOf(pF.filterOptions.headerValue)
    if (pF.filterOptions.dataType === 'DateTimeOffset') {
      if (pF.filterOptions.value !== '') {
        rows = rows.filter((row) => {
          if (row[indexOfHeader]) {
            let rowDate = moment(row[indexOfHeader])
            let filterDate = moment(pF.filterOptions.value)
            if (pF.filterOptions.type === '>') {
              return rowDate.isSameOrAfter(filterDate)
            } else if (pF.filterOptions.type === '<') {
              return rowDate.isSameOrBefore(filterDate)
            } else if (pF.filterOptions.type === '=') {
              return rowDate.isSame(filterDate)
            }
          }
        })
      }
    } else {
      if (pF.filterOptions.type === 'includes') {
        if (pF.filterOptions.value !== '') {
          rows = rows.filter(row => row[indexOfHeader] && row[indexOfHeader].toLowerCase().includes(pF.filterOptions.value.toLowerCase()))
        }
      } else if (pF.filterOptions.type === '>') {
        if (pF.filterOptions.value !== '') {
          rows = rows.filter(row => !Object.is(row[indexOfHeader], null) && Number(row[indexOfHeader]) >= Number(pF.filterOptions.value))
        }
      } else if (pF.filterOptions.type === '<') {
        if (pF.filterOptions.value !== '') {
          rows = rows.filter(row => !Object.is(row[indexOfHeader], null) && Number(row[indexOfHeader]) <= Number(pF.filterOptions.value))
        }
      } else if (pF.filterOptions.type === '=') {
        if (pF.filterOptions.value !== '') {
          rows = rows.filter(row => !Object.is(row[indexOfHeader], null) && Number(row[indexOfHeader]) === Number(pF.filterOptions.value))
        }
      }
    }
  })
  return rows
}

export function filterDataByObject (pageFilters, data) {
  let filteredData = data
  pageFilters.forEach((pF) => {
    if (pF.filterOptions.value !== '') {
      filteredData = filteredData.filter((d) => {        
        if (!defaultFilterOptionDataMapping[pF.filterOptions.headerValue] && 
          (d[pF.filterOptions.headerValue] === undefined || d[pF.filterOptions.headerValue] === null)){
          // we don't know what we're trying to filter, so skip it
          return true;
        }
        
        let params = {
          value: defaultFilterOptionDataMapping[pF.filterOptions.headerValue] && !d[pF.filterOptions.headerValue] ? 
                  defaultFilterOptionDataMapping[pF.filterOptions.headerValue](d) : 
                  d[pF.filterOptions.headerValue],
          other: pF.filterOptions.value
        }

        // if we don't have a value and the none option was selected    
        if (params.value === null && params.other === NoneOption){
          return true;
        }
           
        if (params.value !== null) {

          // modify values based on option data type
          if (defaultFilterOptionDataTypeModifiers[pF.filterOptions.dataType]){ 
            defaultFilterOptionDataTypeModifiers[pF.filterOptions.dataType](params); 
          }

          // compare based on option type
          if (defaultFilterOptionTypeComparers[pF.filterOptions.type]){
            return defaultFilterOptionTypeComparers[pF.filterOptions.type](params.value, params.other);
          }
        }

        return false;
      })
    }
  })

  return filteredData
}

export function hasFilter (filters, header) {
  return filters.filter(f => f.filterOptions.headerValue === header).length > 0;
}

export function getValueFromHeader (filters, header) {
  let result = filters.filter(f => f.filterOptions.headerValue === header)
  if (result.length > 0) {    
    return result[0].filterOptions.value
  } else {
    return ''
  }
}

export function getTypeFromHeader (filters, header) {
  let result = filters.filter(f => f.filterOptions.headerValue === header)
  if (result.length > 0) {
    return result[0].filterOptions.type
  } else {
    return '>'
  }
}

export function getDataTypeFromHeader (header, columns, arrayOfTypes) {
  const indexOfHeader = columns.indexOf(header)
  return arrayOfTypes[indexOfHeader]
}

export function getOptionValuesFromDataByHeader (header, columns, rows) {
  const indexOfHeader = columns.indexOf(header)
  let unique = [...new Set(rows.map((row) => row[indexOfHeader]))]
  unique = unique.filter(function (el) {
    return el != null
  })
  return unique
}

export function getOptionValuesFromData (header, data) {
  let values = defaultFilterOptionDataMapping[header] ? data.map(defaultFilterOptionDataMapping[header]) : data.map(d => d.name);
  let unique = [...new Set(values)]
  unique = unique.filter(function (el) {
    return el != null
  })              
  unique.unshift(NoneOption)
  return unique
}


export function getFilterOptionTypeBasedOnVariableType (header, columns, arrayOfTypes) {
  const indexOfHeader = columns.indexOf(header)
  switch (arrayOfTypes[indexOfHeader]) {
    case 'String':
      return 'includes'
    case 'Int32':
      return '>'
    case 'Decimal':
      return '>'
    case 'DateTimeOffset':
      return '>'
    default:
      return 'includes'
  }
}
