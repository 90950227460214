import React, { Component } from 'react'
import '../styles/menu.css'
import { Grid, Icon } from 'semantic-ui-react'
import AdminMenuItems from './adminMenuItems'
import StudentMenuItems from './studentMenuItems'
import ViewReportsMenuItems from './viewReportsMenuItems'
import auth from '../services/auth'
import userType from '../classes/userType'
import { Link, NavLink, withRouter } from 'react-router-dom'

class MenuRow extends Component {
  render () {
    const result = auth.getUserLevel()
    const { filters = [], environments = [], devices = [] } = this.props
    let element = null
    if (result.success) {
      let userLevel = userType.highestUserLevel(result.userLevel)
      switch (userLevel) {
        case 'UserManagement':
          element = <AdminMenuItems />
          break
        case 'Student':
          element = <StudentMenuItems />
          break
        case 'ViewReports':
          element = <ViewReportsMenuItems filters={filters} devices={devices} />
          break
        default:
          element = null
      }
    }

    return (
        element
    )
  }
}
export default withRouter(MenuRow)
