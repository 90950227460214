import React, { Component, ReactCenter } from 'react'
import { Button, Grid, Header, TextArea, GridRow, Segment, Card, CardHeader, CardContent, CardItem, Container, CardDescription } from 'semantic-ui-react'
import { Label, LabelList, Text, Pie, PieChart, Tooltip, Legend, Cell, Bar, BarChart, XAxis, YAxis, ResponsiveContainer } from 'recharts'

export default class SummaryItem extends Component {
  toSummaryNum = (summaryNumber) => {
    if (isNaN(summaryNumber)) {
      return ('')
    }
    if (summaryNumber > 1000000.0) {
      const retNum = (summaryNumber / 1000000.0).toFixed(1)
      return (retNum + 'M')
    }
    if (summaryNumber > 1000) {
      const retNum = (summaryNumber / 1000.0).toFixed(1)
      return (retNum + 'K')
    }
    const retNum = (summaryNumber / 1.0).toFixed(0)
    return retNum
  }
  render () {
    const { title, number, onClick, icon } = this.props
    const tileClicked = onClick ? () => onClick() : () => {}
    return (
      <React.Fragment>
        <div className='summaryNumber' style={{ cursor: 'pointer', border: '1px gray solid', backgroundColor: 'rgba(48, 82, 156, 0.3)', borderRadius: '3px' }} onClick={tileClicked}>
          <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <div style={{ display: 'flex', fontSize: '1.5rem', justifyContent: 'center', color: 'white' }}>
              {icon}
              <span style={{ textTransform: 'uppercase', fontSize: '1.3rem', paddingLeft: '4px' }}>
                {title}:
              </span>
              <span style={{ fontWeight: 'bold', paddingLeft: '4px' }}>
                {this.toSummaryNum(number)}
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
