import React from 'react'
import { Input } from 'semantic-ui-react'

const searchBox = ({ value, onChange }) => (
  <Input
    action={{ icon: 'search', style: { backgroundColor: '#0A379D', color: '#FFF' } }}
    placeholder='Search...'
    style={{ width: '20rem' }}
    className='searchBox'
    value={value}
    onChange={onChange}
  />
)

export default searchBox
