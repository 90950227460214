import React, { Component } from 'react'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import ScreenHeader from '../../components/screenHeader'
import ExportButton from '../../components/exportButton'
import moment from 'moment'

// Should be able to pass this around but it really doesn't like this.
import auth from '../../services/auth'
import userType from '../../classes/userType'

import AddDeviceModal from '../devices/components/addDeviceModal'
import OtrTableUpgraded from '../../components/otrTableUpgraded'
import { sortByPropertyIndex, sortColumns } from '../../classes/tableHelpers'
import OtrModal from '../../components/otrModal'
import DevicesUpdateForm from '../../components/DevicesUpdateForm'
import { filterDataByObject, getFiltersOfPage } from '../../classes/filterHelper'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Devices extends Component {
  constructor (props) {
    super(props)
    this.cancellationToken = { cancelled: false } 
    this.deviceClicked = this.deviceClicked.bind(this)
    this.state = {
      search: '',
      loading: false,
      devices: [],
      editingDevice: null,
      orderBy: null
    }
    this.handleClickOnEditButton = this.handleClickOnEditButton.bind(this)
    this.handleClickOnDeleteButton = this.handleClickOnDeleteButton.bind(this)
    this.handleOnOrderBy = this.handleOnOrderBy.bind(this)
    this.refreshData = this.refreshData.bind(this)
  }
  async handleClickOnEditButton (device) {
    this.setState({ editingDevice: device })
  }
  async handleClickOnDeleteButton (device) {
    this.deleteUser(device.id)
  }
  handleOnOrderBy (data) {
    let type
    if (this.state.orderBy) {
      if (this.state.orderBy.idx === data) {
        if (this.state.orderBy.type === 'desc') {
          type = 'asc'
          this.setState({ orderBy: { idx: data, type: type } }, () => {
            this.getData()
          })
        } else {
          this.setState({ orderBy: null }, () => {
            this.getData()
          })
        }
      } else {
        this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
          this.getData()
        })
      }
    } else {
      this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
        this.getData()
      })
    }
  }
  
  handleSortColumns (data) {
    return sortColumns(data, ['deviceEnabled'])
  }

  searchUpdated = (event, data) => {
    this.setState({ search: data.value })
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  async getData () {
    this.toggleLoading()
    const result = await api.allOtrs()
    if (result.success && !this.cancellationToken.cancelled) {
      if (this.state.orderBy) {
        //result.data = sortByPropertyIndex(this.state.orderBy.idx, this.state.orderBy.type, result.data)
      }
      const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
      result.data = filterDataByObject(pageFilters, result.data)
      this.setState({ devices: result.data.map(row => (row.lastUpdated ? { ...row, lastUpdated: moment(row.lastUpdated).format('DD-MM-YYYY HH:mm:ss') } : row)) })
      this.toggleLoading()
    }
  }

  componentDidMount () {
    this.cancellationToken.cancelled = false;
    this.getData()
  }

  componentWillUnmount () {
    this.cancellationToken.cancelled = true;
  }

  deviceClicked = ({ id }) => {
    this.props.history.push(`/devices/${id}`)
  }

  download = () => {
    const devices = this.state.devices.map(d => {
      const versionName = d.installedAppVersion instanceof Object ? d.installedAppVersion.version : d.installedAppVersion
      const rigName = d.currentRig instanceof Object ? d.currentRig.name : d.currentRig
      const environmentNames = d.environments.map(({ name }) => name).sort()
      const usersNames = d.users.map(({ username }) => username)
      return {
        ...d,
        installedAppVersion: versionName,
        currentRig: rigName,
        environments: environmentNames.join(';'),
        users: usersNames.join(';')
      }
    })

    downloadCSV(devices, 'otr-devices')
  }

  addDevice = async ({ deviceName }) => {
    const result = await api.devicesAddDevice({ name: deviceName })
    if (result.success) {
      this.getData()
      this.setState({ editingDevice: { id: result.data } })
    } else {
      this.setState({ errors: result.data || ['There was an error creating this device'] })
    }
  }
  async refreshData () {
    this.getData()
  }
  render () {
    const { devices, search, loading } = this.state

    const userRoles = auth.getUserLevel()

    // This really does not appear to be the highest..
    let highestRole = userType.highestAdminLevel(userRoles.userLevel)
    let actions

    if (highestRole == 'DeviceAdministrator') {
      actions = (
        <React.Fragment>
          <ExportButton onClick={this.download} />
          <AddDeviceModal validSubmit={this.addDevice} />
        </React.Fragment>
      )
    } else {
      actions = (
        <React.Fragment>
          <ExportButton onClick={this.download} />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <OtrModal open={this.state.editingDevice !== null} title='Update Device' onClose={() => { this.setState({ editingDevice: null }) }}>
          <DevicesUpdateForm closePopUp={() => { this.setState({ editingDevice: null }) }} device={this.state.editingDevice} refreshData={this.refreshData} />
        </OtrModal>
        <ScreenHeader
          title='DEVICES'
          actions={actions}
          search={search}
          searchUpdated={this.searchUpdated}
          reduxFilter
        />
        <OtrTableUpgraded
          data={devices}
          orderBy={this.state.orderBy}
          arrayLengthColumns={[]}
          sortColumnsHandler={this.handleSortColumns}
          hideColumns={['ID', 'ACCESS TOKEN', 'CURRENT VERSION HASH', 'REQUESTED VERSION HASH', 'OTR FILTER ID', 'RESULTS','ENVIRONMENTS', 'USERS']} 
          name='Devices'
          loading={loading}
          handleOnOrderBy={this.handleOnOrderBy}
          clientPaging
          handleClickOnEditButton={this.handleClickOnEditButton}
          search={search}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return { filtersByPage: state.filtersByPage }
}
export default connect(mapStateToProps)(withRouter(Devices))
