import React, { Component } from 'react'
import ScreenHeader from '../../components/screenHeader'
import { Button, Grid, Header } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class UserSettings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      error: false
    }
  }
  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  async getData () {
    this.toggleLoading()
    //  TODO: GET USER DATA FROM API
    this.toggleLoading()
  }

  componentDidMount () {
    this.getData()
  }
  onChangePicture () {

  }
  onChangePassword () {

  }
  render () {
    return (
      <React.Fragment>
        <ScreenHeader
          title='User Settings'
        />
        <Grid container centered>
          <Grid.Row>
            <Grid.Column>
              <React.Fragment>
                <Header as='h1' inverted>CHANGE YOUR PICTURE</Header>
                <Form id='changePicture' name='changePicture' onValidSubmit={this.onChangePicture}>
                  <Form.Input
                    size='huge'
                    name='file'
                    placeholder='Picture'
                    type='file'
                    required
                  />
                  <Button loading={this.state.loading} primary fluid size='huge'>CONFIRM</Button>
                </Form>
              </React.Fragment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <React.Fragment>
                <Header as='h1' inverted>CHANGE YOUR PASSWORD</Header>
                <Form id='changePassword' name='changePassword' onValidSubmit={this.onChangePassword}>
                  <Form.Input
                    size='huge'
                    name='password'
                    placeholder='Password'
                    type='password'
                    required
                    validations={{ minLength: 6, matchRegexp: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/ }}
                  />
                  <Form.Input
                    size='huge'
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    type='password'
                    required
                    validations='equalsField:password'
                  />
                  <Button loading={this.state.loading} primary fluid size='huge'>CONFIRM</Button>
                </Form>
              </React.Fragment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
}
