import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Table, Segment, Icon } from 'semantic-ui-react'
import loadingImage from '../img/OTR-loading-animation-small.gif'
import { serializeToJsonWithDeep } from '../classes/jsonSerialize'
import moment from 'moment'
export default class OtrTableGrouped extends Component {
  state = {
    rows: [],
    competencyPosition: null
  }
  constructor (props) {
    super(props)
    this.valueOnGroup = this.valueOnGroup.bind(this)
  }
  renderRowsWithJson (data) {
    let rows = []
    Object.keys(data).map((key) => {
      rows.push(this.checkAndRenderNewRow(data[key], [key], key))
    })
    this.setState({ rows: rows })
  }
  checkAndRenderNewRow (rowObject, arrayOfKeys, value, prevValues = []) {
    let allRows = []
    let bigValues = prevValues
    if (!Array.isArray(rowObject)) {
      let childrenRows = []
      Object.keys(rowObject).forEach((key) => {
        let values = []
        let arrayOfChildrenKeys = [...arrayOfKeys]
        arrayOfChildrenKeys.push(key)
        if (Array.isArray(rowObject[key])) {
          let childrenValues = []
          let updateKeys = [...arrayOfKeys, key]
          rowObject[key].forEach((valueArray) => {
            values.push(valueArray)
            childrenValues.push(this.valuesRow(updateKeys, valueArray))
          })
          childrenRows.push(this.collapsableRow(updateKeys, key, values))
          childrenRows.push(childrenValues)
        } else {
          childrenRows.push(this.checkAndRenderNewRow(rowObject[key], arrayOfChildrenKeys, key, values))
        }
        bigValues.push(values)
      })
      allRows.push(this.collapsableRow(arrayOfKeys, value, bigValues))
      allRows.push(childrenRows)
    }

    return allRows
  }
  generateKeyClassname (keys, header) {
    let classnameString = ''
    keys.forEach((key, index) => {
      classnameString += ` ${header[index].replace(/ /g, '')}~${key.replace(/ /g, '')}`
    })
    return classnameString
  }
  generateIdString (keys) {
    let fixedKeys = keys.map((k) => {
      return k.replace(/ /g, '')
    })
    return fixedKeys.join('~')
  }
  handleClickOnCollapsableRow (id, childrenClassname) {
    let collapsableRow = document.getElementById(id)
    if (collapsableRow.classList.contains('expanded')) {
      collapsableRow.querySelector('.minus').classList.add('hideRow')
      collapsableRow.querySelector('.plus').classList.remove('hideRow')
    } else {
      collapsableRow.querySelector('.minus').classList.remove('hideRow')
      collapsableRow.querySelector('.plus').classList.add('hideRow')
    }
    collapsableRow.classList.toggle('expanded')

    let childrenRows = document.getElementsByClassName(childrenClassname)
    for (let childrenRowKey in childrenRows) {
      if (typeof childrenRows[childrenRowKey] === 'object') {
        this.hideOrShowBasedOfParent(childrenRows[childrenRowKey])
      }
    }
  }
  getArrayValuesOfArray =(arr) => {
    let valuesArray = arr
    let notFlat = false
    do {
      notFlat = false
      let flatArray = valuesArray
      let newArray = []
      if (flatArray.length > 0) {
        if (Array.isArray(flatArray) && !Array.isArray(flatArray[0][0])) {
          valuesArray = flatArray
        } else {
          flatArray.forEach((fA) => {
            if (Array.isArray(fA) && Array.isArray(fA[0][0])) {
              notFlat = true
              newArray = newArray.concat(fA.flat(1))
            } else {
              newArray = newArray.concat(fA)
            }
          })
          valuesArray = newArray
        }
      }
    } while (notFlat)

    return valuesArray
  }

  calculateValueBasedOfAggregates = (headerIndex, values, aggregate, h) => {
    if (aggregate === 'None') {
      return ''
    } else if (aggregate === 'Sum') {
      let value = 0
      values.forEach((row) => {
        if (!Object.is(row[headerIndex], null)) {
          value += row[headerIndex]
        }
      })
      return value
    } else if (aggregate === 'Average') {
      let value = 0
      values.forEach((row) => {
        if (!Object.is(row[headerIndex], null)) {
          value += row[headerIndex]
        }
      })
      return (value / values.length).toFixed(0) + (h === 'Competency' ? '%' : '')
    } else if (aggregate === 'Max') {
      let value = null
      values.forEach((row) => {
        if (!Object.is(row[headerIndex], null)) {
          if (Object.is(value, null)) {
            value = moment(row[headerIndex])
          } else if (moment(row[headerIndex]).isAfter(value)) {
            value = moment(row[headerIndex])
          }
        }
      })
      if (value) {
        return value.format('DD-MM-YYYY')
      } else {
        return ''
      }
    }
  }

  collapsableRow (keys, value, values = null) {
    let arrayOfValues = []
    if (values) {
      arrayOfValues = this.getArrayValuesOfArray(values)
    }
    let keysCleaned = keys.map((k) => {
      return k.replace(/~/g, '')
    })

    let depthWithValueGroup = this.props.firstHeaderOnGroup ? 1 : 0
    let headerFixed = this.props.data.header.slice(this.props.data.depth + depthWithValueGroup, this.props.data.header.length)
    let aggregatesFixed = this.props.data.resultData.aggregates.slice(this.props.data.depth + depthWithValueGroup, this.props.data.resultData.aggregates.length)
    const keysWithoutValue = [...keysCleaned]

    const index = keysWithoutValue.indexOf(value)
    if (index > ~1) {
      keysWithoutValue.splice(index, 1)
    }

    let classnameString = `  ${this.generateKeyClassname(keysWithoutValue, this.props.data.header)} ${keysWithoutValue.length > 0 ? 'hideRow' : ''}`

    let paddingCalculated = (keysWithoutValue.length * 6) + 'rem'
    return (
      <Table.Row id={this.generateIdString(keysCleaned)} onClick={() => { this.handleClickOnCollapsableRow(this.generateIdString(keysCleaned), this.generateKeyClassname(keysCleaned, this.props.data.header)) }} className={classnameString} >
        <Table.Cell style={{ paddingLeft: paddingCalculated, borderBottom: '2px solid black', cursor: 'pointer' }}>
          <p><Icon name='plus' /><Icon className={'hideRow'} name='minus' />{value}</p>
        </Table.Cell>
        {
          headerFixed.map((h, index) => {
            return (<Table.Cell key={`Header_${h}`} style={{ borderBottom: '2px solid black', textAlign: 'center' }}>
              <p>
                { this.calculateValueBasedOfAggregates(index + depthWithValueGroup, arrayOfValues, aggregatesFixed[index], h)}
              </p>
            </Table.Cell>)
          })
        }

      </Table.Row>
    )
  }
  renderValueBasedOnType=(type, value) => {
    if (type === 'DateTimeOffset') {
      if (value) {
        return moment(value).format('DD-MM-YYYY')
      } else {
        return 'N/A'
      }
    } else {
      return value
    }
  }
  valueOnGroup (values, firstValueOnGrouping) {
    let depthWithValueGroup = this.props.firstHeaderOnGroup ? 1 : 0
    let competencyIndex
    let restOfHeader = this.props.data.header.slice(this.props.data.depth + depthWithValueGroup, this.props.data.header.length)
    restOfHeader.forEach((h, index) => {
      if (h === 'Competency') {
        competencyIndex = index
      }
    })
    let restOfTypes = this.props.data.resultData.types.slice(this.props.data.depth + depthWithValueGroup, this.props.data.header.length)
    restOfHeader.forEach((h, index) => {
      if (h === 'Competency') {
        competencyIndex = index
      }
    })
    if (firstValueOnGrouping) {
      let expandableHeader = this.props.data.header.slice(0, this.props.data.depth + depthWithValueGroup)
      let paddingCalculated = (expandableHeader.length * 6) + 'rem'
      let valuesWithoutFirst = [...values]
      valuesWithoutFirst.shift()
      return (<>
        <Table.Cell style={{ paddingLeft: paddingCalculated }}>
          <p>{values[0]}</p>
        </Table.Cell>
        {
          valuesWithoutFirst.map((value, i) => {
            return (<Table.Cell key={`val_${i}`}>
              <p>{this.renderValueBasedOnType(restOfTypes[i], value)}{i === competencyIndex ? '%' : ''}</p>
            </Table.Cell>)
          })
        }
        </>
      )
    } else {
      return (<>
        <Table.Cell>
          <p />
        </Table.Cell>
        {
          values.map((value, i) => {
            return (<Table.Cell key={`val_${i}`}>
              <p>{this.renderValueBasedOnType(restOfTypes[i], value)}{i === competencyIndex ? '%' : ''}</p>
            </Table.Cell>)
          })
        }
        </>
      )
    }
  }
  valuesRow (keys, values) {
    let keysCleaned = keys.map((k) => {
      return k.replace(/~/g, '')
    })
    let classnameString = `  ${this.generateKeyClassname(keysCleaned, this.props.data.header)} hideRow`

    return (
      <Table.Row id={this.generateIdString(keysCleaned) + '~ValueRow'} className={classnameString} style={{ textAlign: 'center' }}>
        {this.valueOnGroup(values, this.props.firstHeaderOnGroup)}
      </Table.Row>
    )
  }
  hideOrShowBasedOfParent (row) {
    let parentId = ''
    let ids = row.id.split('~')
    ids.splice(-1)
    parentId = ids.join('~')
    let parentRow = document.getElementById(parentId)
    if (parentRow !== null) {
      if (parentRow.classList.contains('expanded') && !parentRow.classList.contains('hideRow')) {
        row.classList.remove('hideRow')
      } else {
        if (!row.classList.contains('hideRow')) {
          row.classList.add('hideRow')
        }
      }
    }
  }
  renderLoading = (name) => (
    <div className='tableLoadingSpinner'>
      <img alt='loading spinner' src={loadingImage} />
      <h4>Loading {name}</h4>
    </div>
  );

  checkIfCompetencyAndSetIndex (header) {
    let depthWithValueGroup = this.props.firstHeaderOnGroup ? 1 : 0
    let restOfHeader = header.slice(this.props.data.depth + depthWithValueGroup, header.length)
    restOfHeader.forEach((h, index) => {
      if (h === 'Competency') {
        this.setState({ competencyPosition: index })
      }
    })
  }
  componentDidMount () {
    this.checkIfCompetencyAndSetIndex(this.props.data.header)

    this.renderRowsWithJson(serializeToJsonWithDeep(this.props.data.header, this.props.data.data, this.props.data.depth))
  }
  renderHeader=(header, depth) => {
    let headerGrouped = [...header]
    let depthWithValueGroup = this.props.firstHeaderOnGroup ? 1 : 0
    let restOfHeader = headerGrouped.slice(depth + depthWithValueGroup, header.length)
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ textAlign: 'center' }} key={11}>{_.startCase('')}</Table.HeaderCell>
          {restOfHeader.map((header, index) => {
            return <Table.HeaderCell style={{ textAlign: 'center' }} key={`h_${index}`}>{_.startCase(header)}</Table.HeaderCell>
          })}
        </Table.Row>
      </Table.Header>
    )
  }
  render () {
    let {
      loading = false,
      name = '',
      rowClicked = null,
      data
    } = this.props
    const table = (
      <Table basic='very' selectable={(rowClicked !== null)}>
        {this.renderHeader(data.header, data.depth)}
        <Table.Body>
          {
            this.state.rows
          }
        </Table.Body>
      </Table>
    )
    return (
      <Segment>
        { loading && this.renderLoading(name) }
        { (this.state.rows.length > 0 && !loading) && table }
      </Segment>
    )
  }
}
