import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Header, Responsive } from 'semantic-ui-react'
import squareImage from '../img/square.svg'

export default class UserMenuLink extends Component {
  render () {
    const { to, title, exact = true, icon = squareImage } = this.props
    return (
      <NavLink exact={exact} to={to} className='userMenuLink'>
        <Header inverted as='h5'>{title}</Header>
        {this.props.children}
      </NavLink>
    )
  }
}
