import React, { Component } from 'react'
import { Bar, BarChart, XAxis, YAxis, ResponsiveContainer } from 'recharts'

export default class BarChartTable extends Component {
  calculateBarColor ({ value }) {
    let color = '#49ac85'
    if (value < 20) {
      color = '#a43a3b'
    } else if (value < 80) {
      color = '#0a379d'
    }
    return color
  }
  render () {
    const { data, max, min } = this.props
    const calculatedData = data.map(d => ({ ...d, missedBy: max - d.value }))
    const bar = calculatedData.map((value, idx) => {
      const { key } = value
      const yWidth = (key ? 200 : 0)
      const barColor = this.calculateBarColor(value)
      return (
        <ResponsiveContainer key={idx} width='100%' height={32}>
          <BarChart layout='vertical' data={[value]}>
            <XAxis type='number' domain={[min, max]} hide />
            <YAxis tick={{ fill: '#0A379D' }} stroke='#ff00000' width={yWidth} dataKey='key' type='category' axisLine={false} tickLine={false} />
            <Bar radius={[5, 5, 5, 5]} dataKey='value' fill={barColor} background={{ fill: '#8899BC', radius: [5, 5, 5, 5] }} maxBarSize={8} />
          </BarChart>
        </ResponsiveContainer>
      )
    })

    return (
      <React.Fragment>
        {bar}
      </React.Fragment>
    )
  }
}
