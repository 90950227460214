import React, { Component } from 'react'
import { Grid, Button, Label } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class OtrTextInputFilter extends Component {  
  state = {  
    isChecked: this.props.isActive || false
  }
  renderTypesInput = (disabled) => {
    const { name, typeOptions, typeOptionsValue } = this.props;
    if (typeOptions) {
      return (        
        <Grid.Column width={0} style={{ display: 'flex', alignItems: 'center', paddingLeft: '0rem' }}>
          <Form.Dropdown
            name={`types[${name}]`}
            selection
            value={typeOptionsValue}
            options={typeOptions}
            disabled={disabled}
            errorLabel={<Label style={{ marginTop: '0.5rem', border: 0, fontFamily: 'Helvetica', fontWeight: 'normal', fontSize: '16px', padding: 0 }} icon='close' color='red' basic />}
          />
        </Grid.Column>
      )
    } else {
      return <Form.Input style={{ display: 'none' }} name={`types[${name}]`} type={'text'} value={'includes'} />
    }
  }
  render () {
    const { title, name, disabled = false, isActive = false, value = '', validations, validationErrors, required = true, size = 'medium', placeholder = '', type = 'text', titleWidth = 4, inputWidth = 11 } = this.props
    const { isChecked } = this.state;
    const inputDisabled = disabled || !isChecked; 
    return (
      <Grid.Row>
        <Grid.Column width={1} style={{ 'alignContent': 'center' }}>
          <Form.Checkbox 
            name={`selected[${name}]`}
            disabled={disabled}
            checked={isChecked}
            defaultChecked={isActive}
            toggle
            onChange={(e, data) => this.setState({ isChecked: !isChecked })}
          />
        </Grid.Column>
        <Grid.Column width={titleWidth} style={{ display: 'flex', alignItems: 'center', paddingInlineStart: '1.5rem'  }}>
          <label>{title}</label>
        </Grid.Column>        
          {this.renderTypesInput(inputDisabled)}
        <Grid.Column width={inputWidth} style={{ display: 'block', alignItems: 'center', paddingLeft: '0px' }}>
          <Form.Input
            name={`value[${name}]`}
            placeholder={placeholder}
            fluid
            size={size}
            value={value}
            required={required}
            disabled={inputDisabled}
            instantValidation
            validations={validations}
            validationErrors={{
              ...validationErrors,
              isDefaultRequiredValue: `${(title || placeholder)} is Required`
            }}
            errorLabel={<div className='errorLabel' icon='close' color='red' />}
            type={type}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
