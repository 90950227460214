import React, { Component } from 'react'
import { Grid, Button, Icon } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { connect } from 'react-redux'
import { addPageFilter, removePageFilter, resetPageFilter } from '../redux/actions'
import WithRouter from 'react-router-dom/withRouter'
import OtrModalFilter from './otrModalFilter'
import {
  defaultFilterOptions, setFilterOptionValue,
  getTypeFromHeader,getDataTypeFromHeader, 
  getFilterOptionTypeBasedOnVariableType, getFiltersOfPage,
  getFilterOptionValue, getOptionValuesFromDataByHeader, 
  filterOptionsByPageDefault, getFilterOptionDefaultComponent,
  hasFilter, getOptionValuesFromData
} from '../classes/filterHelper'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

class AddFilterModalRedux extends Component {
  state = {
    modalOpen: false,
    filterOptionsByPage: filterOptionsByPageDefault,
    pageValuesOptions: [],
    filterMethods: []
  }
  cancellationToken = { cancelled: false } 
  constructor (props) {
    super(props)
    this.getTypeFromInput = this.getTypeFromInput.bind(this)
  }
  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

  getTypeFromInput (types, headerName, defaultType = null) {
    let foundType = Object.keys(types).filter(t => t === headerName)
    if (foundType.length > 0) {
      return types[foundType]
    } else if (defaultType) {
      return defaultType;
    } 

    return getFilterOptionTypeBasedOnVariableType(headerName, this.props.reportData.data.columns, this.props.reportData.data.types);
  }
  validSubmit = (data) => {
    const { history, reportData } = this.props;
    const currentPage = history.location.pathname;
    if (data) {
      // make sure none of the submission data is undefined
      const filterVals = data.value instanceof Object ? data.value : data;
      const filterSelected = data.selected instanceof Object ? data.selected : {}
      const filterTypes = data.types instanceof Object ? data.types : {}
      
      Object.keys(filterVals).forEach((filterName, idx) => {
        let filterOptions;
        let template = defaultFilterOptions[filterName.toLowerCase()];
        if (template){          
          filterOptions = { ...template }
          filterOptions.type = this.getTypeFromInput(filterTypes, filterName, template.type);
          setFilterOptionValue(filterOptions, filterVals[filterName]);
        } else {
          let dataType = getDataTypeFromHeader(filterName, reportData.data.columns, reportData.data.types);
          filterOptions = {
            headerValue: filterName,
            value: filterVals[filterName],
            type: this.getTypeFromInput(filterTypes, filterName),
            dataType: dataType,
            controlComponent: getFilterOptionDefaultComponent(dataType)
          }
        }
        if (filterOptions && filterSelected[filterName] === true){
          this.props.addPageFilter({ page: currentPage, filterOptions });
        } else {
          this.props.removePageFilter({ page: currentPage, filterName})
        }
      })
    }
    this.handleClose()
    setTimeout(() => {
      window.location.reload()
    }, 300)
  }
  componentDidMount () {
    if (this.props.header) {
      this.setState({ filterOptionsByPage: this.generateFilterOptionsFromHeader(this.props.header) })
    }
    this.generateOptionsForFilters()
  }
  componentWillUnmount () {
    this.cancellationToken.cancelled = true;
  }
  generateOptionsForFilters=() => {
    let filterOptions = this.state.filterOptionsByPage.filter(o => o.page === this.props.history.location.pathname)
    if (filterOptions.length > 0) {
      filterOptions[0].filterOptions.forEach((filterOption) => {
        if (filterOption.dataPull) {
          filterOption.endPoint().then((response) => {
            if (this.cancellationToken.cancelled)
              throw new Error("filterOption.endPoint() got cancelled");

            if (response.success) {
              let optionsMapped = getOptionValuesFromData(filterOption.headerValue, response.data).map(d => ({ text: d, value: d }))
              let objectSet = { page: this.props.history.location.pathname, headerValue: filterOption.headerValue, options: optionsMapped }
              this.setState({ pageValuesOptions: [...this.state.pageValuesOptions, objectSet] })
            }
          }).catch(err => {
            console.log(err.message);
          })
        }
      })
    }
  }
  getOptionsFromState=(headerValue) => {
    let headerValueOptions = this.state.pageValuesOptions.filter(pageOptions => pageOptions.headerValue === headerValue)
    if (headerValueOptions.length > 0) {
      return headerValueOptions[0].options
    } else {
      return []
    }
  }
  resetThisPageFilters =() => {
    this.props.resetPageFilter(this.props.history.location.pathname)
    setTimeout(() => {
      window.location.reload()
    }, 300)
  }
  generateFilterOptionsFromHeader = (header) => {
    return [
      {
        filterOptions: header.map((h) => {
          let dataType = getDataTypeFromHeader(h, this.props.reportData.data.columns, this.props.reportData.data.types)
          let type = getFilterOptionTypeBasedOnVariableType(h, this.props.reportData.data.columns, this.props.reportData.data.types)
          return {
            name: h,
            headerValue: h,
            type: type,
            dataType: dataType,
            controlComponent: getFilterOptionDefaultComponent(dataType)
          }
        }),
        page: this.props.history.location.pathname
      }
    ]
  }
  renderFilterOptions = (filterOption, filtersOfPage) => {
    if (filterOption.length > 0){
      return filterOption[0].filterOptions.map((option) => {
        let value = getFilterOptionValue(filtersOfPage, option);
        let typeOptionValue = getTypeFromHeader(filtersOfPage, option.headerValue)  
        let isActive = hasFilter(filtersOfPage, option.headerValue);      
        let options = []
        if (option.dataPull){
          options = this.getOptionsFromState(option.headerValue);
        } else if (this.props.reportData.data){
          options = getOptionValuesFromDataByHeader(option.headerValue, this.props.reportData.data.columns, this.props.reportData.data.rows).map(d => ({ text: d, value: d }))
          options.unshift( { text: 'None', value: 'none' })
        } 

        return <option.controlComponent 
          key={`filterOption_${option.headerValue}`}
          title={option.name} name={option.headerValue} 
          value={value} typeOptionsValue={typeOptionValue} 
          required={false} options={options} isActive={isActive} />
      })
    }
  } 
  render () {
    let filterOption
    if (this.props.table) {
      filterOption = this.generateFilterOptionsFromHeader(this.props.header)
    } else {
      filterOption = filterOptionsByPageDefault.filter(o => o.page === this.props.history.location.pathname)
    }
    const filtersOfPage = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)
    return (
      <React.Fragment>
        <OtrModalFilter
          title='FILTERS'
          trigger={<Button style={{ marginRight: '0px', backgroundColor: 'rgb(39, 177, 236)', color: 'white' }} onClick={this.handleOpen}><Icon style={{ marginRight: '0px' }} size='large' rotated={'clockwise'} name='sliders' /><span style={{ fontSize: '1.5rem', paddingLeft: '0.5rem' }}>FILTERS ({filtersOfPage.filter(fop => fop.filterOptions.value !== '').length})</span></Button>}
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <Form onValidSubmit={this.validSubmit}>
            <Grid>
              {
                this.renderFilterOptions(filterOption, filtersOfPage)
              }
              <Grid.Row style={{ textAlign: 'right', paddingTop: '1.5rem' }}>
                <Grid.Column>
                  <Button primary style={{ margin: 0 }}>
                    SET
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </OtrModalFilter>
        <button style={{ height: '3rem', paddingRight: '0px', paddingLeft: '0px', cursor: 'pointer', backgroundColor: 'rgb(39, 177, 236)', border: '0px', marginLeft: '2px' }} onClick={this.resetThisPageFilters}><Icon style={{ marginRight: '0px' }} color={'red'} size='big' name='close' /></button>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    filtersByPage: state.filtersByPage,
    reportData: state.reportData,
    pageData: state.pageData
  }
}
const mapDispatchToProps = {
  addPageFilter,
  removePageFilter,
  resetPageFilter
}
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(AddFilterModalRedux))
