import React, { Component } from 'react'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import ScreenHeader from '../../components/screenHeader'
import ExportButton from '../../components/exportButton'
import AddUserModal from './components/addUserModal'
import OtrModal from '../../components/otrModal'
import ImportUsersModal from './components/importUsersModal'
import DeleteUserModal from './components/deleteUserModal'

import auth from '../../services/auth'
import userType from '../../classes/userType'
import OtrTableUpgraded from '../../components/otrTableUpgraded'
import DeleteFromSelectedModal from '../../components/deleteFromSelectedModal'
import { sortByPropertyIndex, sortColumns } from '../../classes/tableHelpers'
import { Form } from 'formsy-semantic-ui-react'
import { Button, Grid, GridColumn, GridRow } from 'semantic-ui-react'
import OtrTextInput from '../../components/otrTextInput'
import ResetPasswordModal from '../user/components/resetPasswordModal'
import OtrDropdown from '../../components/otrDropdown'
import OtrCheckbox from '../../components/otrCheckbox'
import UserUpdateForm from '../../components/UserUpdateForm'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { filterData, filterDataByObject, getFiltersOfPage } from '../../classes/filterHelper'

class Users extends Component {
  constructor (props) {
    super(props)
    this.handleOnSelectRow = this.handleOnSelectRow.bind(this)
    this.handleOnOrderBy = this.handleOnOrderBy.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.handleClickOnEditButton = this.handleClickOnEditButton.bind(this)
    this.handleClickOnDeleteButton = this.handleClickOnDeleteButton.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.handleSortColumns = this.handleSortColumns.bind(this);
  }

  state = {
    users: [],
    allUsers: [],
    errors: [],
    totalUsers: 0,
    pageSize: 15,
    pageOffset: 0,
    search: '',
    loading: false,
    selectedRows: [],
    orderBy: null,
    editingUser: null,
    removingUser: null,
    groups: [],
    jobRoles: [],
    appRoles: [],
    devices: [],
    saveLoading: false
  }

  searchUpdated = (event, data) => {
    this.setState({ search: data.value }, () => {
      this.getData()
    })
  }

  handleOnSelectRow (data, add) {
    if (add) {
      let array = this.state.selectedRows
      array.push(data)
      this.setState({ selectedRows: array })
    } else {
      var index = this.state.selectedRows.indexOf(data)
      let array = this.state.selectedRows
      if (index !== -1) {
        array.splice(index, 1)
      }
      this.setState({ selectedRows: array })
    }
  }

  async handleOnBlur (user, index, change) {
    let keys = Object.keys(user)
    user[keys[index]] = change
    const result = await api.updateUser(user)
    if (result.success) {

    } else {
      let errors = Object.keys(result.data).map((key) => {
        return result.data[key]
      })
      this.setState({ errors: errors || ['There was an error updating the user'] })
    }
  }

  async handleClickOnEditButton (user) {
    this.setState({ editingUser: user })
  }

  async refreshData () {
    this.getData()
  }

  async handleClickOnDeleteButton (user) {
    this.deleteUser(user.id)
    this.getData()
  }

  handleOnOrderBy (data) {
    let type
    if (this.state.orderBy) {
      if (this.state.orderBy.idx === data) {
        if (this.state.orderBy.type === 'desc') {
          type = 'asc'
          this.setState({ orderBy: { idx: data, type: type } }, () => {
            this.getData()
          })
        } else {
          this.setState({ orderBy: null }, () => {
            this.getData()
          })
        }
      } else {
        this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
          this.getData()
        })
      }
    } else {
      this.setState({ orderBy: { idx: data, type: 'desc' } }, () => {
        this.getData()
      })
    }
  }

  handleSortColumns (data) {
    return sortColumns(data, ['accountEnabled'])
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  async getData () {
    this.toggleLoading()
    const pageFilters = getFiltersOfPage(this.props.filtersByPage, this.props.history.location.pathname)

    // get all users
    let result = await api.allUsers();
    if (result.success){      
      let { data, total } = result.data

      let totalUsers = total;
      let allUsers = data;
      let filteredUsers = filterDataByObject(pageFilters, allUsers)
      this.setState({ allUsers, users: filteredUsers, totalUsers })
    } else {
      this.setState({ allUsers: [] })
    }   

    this.toggleLoading()
  }

  componentDidMount () {
    this.getData()
  }

  download = async () => {
    // convert the jobRoles and groups into lists of names seperated by semicolons
    const userData = this.state.allUsers.map(r => {
      const jobRolesNames = r.jobRoles.map(({ name }) => name)
      const rolesNames = r.roles.map(({ name }) => name)
      const devicesNames = r.authorizedDevices.map(({ name }) => name)
      const rigsNames = r.rigs.map(({ name }) => name)
      const environmentNames = r.environments.map(({ name }) => name).sort()
      const groupsNames = r.groups.map(({ name }) => name)
      return { 
        ...r, 
        jobRoles: jobRolesNames.join(';'), 
        roles: rolesNames.join(';'), 
        authorizedDevices: devicesNames.join(';'),
        rigs: rigsNames.join(';'), 
        environments: environmentNames.join(';'), 
        groups: groupsNames.join(';') }
    })

    downloadCSV(userData, 'otr-users')
  }

  userClicked = ({ id }) => {
    this.props.history.push(`/users/${id}`)
  }

  paginationPressed = (pageOffset) => {
    this.setState({ pageOffset }, () => {
      this.getData()
    })
  }

  userAdd = async ({ emailAddress, password }) => {
    const result = await api.createUser({ emailAddress, password })
    if (result.success) {
      this.handleClickOnEditButton({ id: result.data })
      this.getData()
    } else {
      this.setState({ errors: result.data || ['There was an error saving the user'] })
    }
  }

  deleteUser = async (user) => {
    let id = user
    if (user.user) {
      id = user.user
    }
    let exists = this.state.allUsers.find(element => element.id === id)
    for (let group of exists.groups) {
      const deleteGroups = await api.groupRemoveUser({ userId: id, groupId: group.id })
    }

    const result = await api.deleteUser({ userId: id })

    if (result.success) {
      // Woop? Do something perhaps. Not sure if we will need to refresh.
      this.getData()
    } else {
      this.setState({ errors: result.data || ['There was an error removing the user'] })
    }
  }

  onUpdateFormClose = () => {
    this.setState({ editingUser: null })
  }

  render () {
    const userRoles = auth.getUserLevel()
    const { users, allUsers, search, loading, totalUsers, pageSize, pageOffset, errors } = this.state

    // This really does not appear to be the highest..
    let highestRole = userType.highestAdminLevel(userRoles.userLevel)
    let actions
    if (highestRole == 'DeviceAdministrator') {
      actions = (
        <React.Fragment>
          <ExportButton onClick={this.download} />
          <AddUserModal validSubmit={this.userAdd} />
          <DeleteUserModal users={allUsers} validSubmit={this.deleteUser} />
          <ImportUsersModal />
        </React.Fragment>
      )
    } else {
      actions = (
        <React.Fragment>
          <ExportButton onClick={this.download} />
          <AddUserModal validSubmit={this.userAdd} />
          <ImportUsersModal />
          {
            // Disable Temporal <DeleteFromSelectedModal itemsIds={this.state.selectedRows} endpointToCall={api.deleteUser} propertyId={'userId'} />
          }
        </React.Fragment>
      )
    }
    const result = auth.getUserLevel()
    return (
      <React.Fragment>
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <OtrModal open={this.state.editingUser !== null} title='Update User' onClose={this.onUpdateFormClose}>
          <UserUpdateForm user={this.state.editingUser} refreshData={this.refreshData}/>
        </OtrModal>
        <ScreenHeader
          title='USERS'
          actions={actions}
          search={search}
          reduxFilter
          searchUpdated={this.searchUpdated}
        />
        <OtrTableUpgraded
          handleOnSelectedRow={this.handleOnSelectRow}
          orderBy={this.state.orderBy}
          data={users}
          arrayLengthColumns={[
          ]}
          hideColumns={['ID', 'GROUPS',
            'CUSTOM DATA NAME 001', 'CUSTOM DATA VALUE 001',
            'CUSTOM DATA NAME 002', 'CUSTOM DATA VALUE 002',
            'CUSTOM DATA NAME 003', 'CUSTOM DATA VALUE 003',
            'CUSTOM DATA NAME 004', 'CUSTOM DATA VALUE 004',
            'CUSTOM DATA NAME 005', 'CUSTOM DATA VALUE 005',            
            'AUTHORIZED DEVICES', 'ENVIRONMENTS', 'RIGS', 'IS SUPPORT']}
          sortColumnsHandler={this.handleSortColumns}
          name='Users'
          loading={loading}
          totalRows={totalUsers}
          pageSize={pageSize}
          pageOffset={pageOffset}
          handleOnOrderBy={this.handleOnOrderBy}
          paginationPressed={this.paginationPressed}
          handleClickOnEditButton={this.handleClickOnEditButton}
          handleClickOnDeleteButton={highestRole === 'DeviceAdministrator' ? this.handleClickOnDeleteButton : null}
          clientPaging
          search={search}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return { filtersByPage: state.filtersByPage }
}
export default connect(mapStateToProps)(withRouter(Users))
