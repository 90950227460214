import React, { Component } from 'react'
import ScreenHeader from '../../components/screenHeader'
import ServerInstructor from './components/ServerInstructor'

export default class SimulatorIntructor extends Component {
  render () {
    let actions = (
      <React.Fragment>
        <div style={{ paddingTop: '38px' }} />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <ScreenHeader actions={actions} title='Simulator Selection' />
        <ServerInstructor />
      </React.Fragment>
    )
  }
}
