import React, { Component } from 'react'
import { Button, Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import AddUserModal from './components/addUserModal'
import api from '../../classes/api'
import OtrTable from '../../components/otrTable'
import ScreenHeader from '../../components/screenHeader'
import OtrTextInput from '../../components/otrTextInput'
import ScreenSubHeader from '../../components/screenSubHeader'
import ExportButton from '../../components/exportButton'
import OtrLoadingSpinner from '../../components/otrLoadingSpinner'
import { downloadCSV } from '../../classes/data'
import OtrModal from '../../components/otrModal'

export default class GroupScreen extends Component {
  state = {
    errors: [],
    userRemoveOpen: false,
    pageLoading: false,
    saveLoading: false,
    group: {},
    users: {
      pageSize: 15,
      total: 0,
      data: []
    },
    allUsers: [],
    usersSearch: ''
  }

  toggleLoading (key) {
    const loading = this.state[key]
    this.setState({ [key]: (!loading) })
  }

  showUserRemoveConfirm = () => {
    this.setState({ userRemoveOpen: true })
  }

  hideUserRemoveConfirm = () => {
    this.setState({ userRemoveOpen: false })
  }

  userRemoveClicked = (employeeId) => {
    this.showUserRemoveConfirm()
  }

  removeUser = () => {
    this.hideUserRemoveConfirm()
  }

  async getUsers () {
    const result = await api.allUsers()
    if (result.success) {
      this.setState({ allUsers: result.data.data })
    }
  }

  async getData () {
    const { groupId } = this.props.match.params
    this.toggleLoading('pageLoading')
    const result = await api.groupById(groupId)
    this.toggleLoading('pageLoading')
    if (result.success) {
      this.setState({ group: result.data })
      if (result.data.users.length > 0) {
        const usersResult = await api.allUsers(null, null, result.data.users)
        if (usersResult.success) {
          this.setState({ users: usersResult.data })
        }
      }
    }
  }

  componentDidMount () {
    this.getData()
    this.getUsers()
  }

  groupFormSubmit = async (data) => {
    this.toggleLoading('saveLoading')
    const result = await api.groupUpdate({ id: this.state.group.id, name: data.name })
    if (result.success) {
      this.setState({ group: { ...this.state.group, name: data.name } })
    } else {
      this.setState({ errors: result.data || ['We had a problem saving this group'] })
    }
    this.toggleLoading('saveLoading')
  }

  searchUpdated = (event, data) => {
    this.setState({ usersSearch: data.value })
  }

  removeClicked = async (user) => {
    const { group } = this.state
    await api.groupRemoveUser({ userId: user.id, groupId: group.id })
    this.getData()
  }

  addUser = async ({ user }) => {
    const { group } = this.state
    await api.groupAddUser({ userId: user, groupId: group.id })
    this.getData()
  }

  download = () => {
    downloadCSV(this.state.users.data, 'otr-group-users')
  }

  render () {
    const { errors, group, saveLoading, usersSearch, pageLoading, users, allUsers } = this.state

    const actions = (
      <React.Fragment>
        <ExportButton onClick={this.download} />
        <AddUserModal users={allUsers} validSubmit={this.addUser} />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <OtrModal open={errors.length > 0} title='ERROR' onClose={() => { this.setState({ errors: [] }) }}>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </OtrModal>
        <ScreenHeader
          title={group.name}
          backText='GROUPS'
          backLocation='/groups/'
        />
        <Grid>
          <GridRow>
            <GridColumn width={16}>
              <Segment>
                {pageLoading && (<OtrLoadingSpinner shown name='Group Information' />)}
                {!pageLoading && (
                  <Form onValidSubmit={this.groupFormSubmit}>
                    <Grid>
                      {/*<OtrTextInput title='ID' name='id' value={group.id} disabled />*/}
                      <OtrTextInput title='Group Name' name='name' value={group.name} />
                      <GridRow>
                        <GridColumn>
                          <Button loading={saveLoading} primary type='submit'>SAVE</Button>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </Form>
                )}
              </Segment>
            </GridColumn>
          </GridRow>
        </Grid>
        <ScreenSubHeader
          title='GROUP USERS'
          search={usersSearch}
          searchUpdated={this.searchUpdated}
          actions={actions}
        />
        <OtrTable
          data={users.data}
          name='Users'
          loading={pageLoading}
          search={usersSearch}
          hideColumns={["ID",
                        "CUSTOM DATA NAME 001","CUSTOM DATA VALUE 001",
                        "CUSTOM DATA NAME 002","CUSTOM DATA VALUE 002",
                        "CUSTOM DATA NAME 003","CUSTOM DATA VALUE 003",
                        "CUSTOM DATA NAME 004","CUSTOM DATA VALUE 004",
                        "CUSTOM DATA NAME 005","CUSTOM DATA VALUE 005"]}
          filteredColumns={['groups', 'jobRoles']}
          rowRemove={this.removeClicked}
          totalRows={users.total}
          pageSize={users.pageSize}
        />
      </React.Fragment>
    )
  }
}
