import React, { Component } from 'react'
import { Grid, Label } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

export default class OtrTextArea extends Component {
  render () {
    const { title, name, disabled = false, value = '', validations, validationErrors, required = true } = this.props
    return (
      <Grid.Row>
        <Grid.Column width={3} style={{ display: 'flex', alignItems: 'center' }}>
          <label>{title}</label>
        </Grid.Column>
        <Grid.Column width={13}>
          <Form.TextArea
            name={name}
            value={value}
            required={required}
            disabled={disabled}
            instantValidation
            validations={validations}
            validationErrors={{
              ...validationErrors,
              isDefaultRequiredValue: `${title} is Required`
            }}
            errorLabel={<Label style={{ marginTop: '0.5rem', border: 0, fontFamily: 'Helvetica', fontWeight: 'normal', fontSize: '16px', padding: 0 }} icon='close' color='red' basic />}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
