import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import api from '../../classes/api'
import { searchData } from '../../classes/search'
import ReportBar from './components/reportBar'
import ScreenHeader from '../../components/screenHeader'
import loadingImage from '../../img/OTR-loading-animation-small.gif'

export default class Reports extends Component {
  state = {
    loading: false,
    reportLoading: false,
    search: '',
    reports: [],
    selectedReport: null,
    reportSearch: '',
    filter: null,
    filterId: ''
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }
  async getData () {
    this.toggleLoading()
    const result = await api.allReports()
    this.toggleLoading()
    if (result.success) {
      this.setState({ reports: result.data })
    }
  }
  componentDidMount () {
    this.getData()
  }
  reportClicked = (reportId) => {
    this.props.history.push(`/reports/${reportId}`)
  }
  render () {
    const { reports, loading, search } = this.state
    const filteredReports = searchData(reports, search, ['name', 'description'])

    const reportBarElement = <ReportBar data={filteredReports} onClick={this.reportClicked} />

    const emptyReports = <p>No reports found</p>
    let actions = (
      <React.Fragment>
        <div style={{ paddingTop: '38px' }} />
      </React.Fragment>
    )
    const loadingElement = (
      <Segment>
        <div className='tableLoadingSpinner'>
          <img alt='loading spinner' src={loadingImage} />
          <h4>Loading Reports</h4>
        </div>
      </Segment>
    )
    return (
      <React.Fragment>
        <ScreenHeader actions={actions} title='REPORTS' />
        { loading ? loadingElement : reportBarElement }
        { (!loading && filteredReports.length === 0) && emptyReports }
        <div style={{ marginTop: '3rem' }} />
      </React.Fragment>
    )
  }
}
