import React, { Component, useEffect } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Markers,
  Marker,
  ZoomableGroup
} from 'react-simple-maps'
import ReactTooltip from 'react-tooltip'
import map from './data/world-countries-sans-antarctica.json'
import HoverInfo from './mapComponents/HoverInfo'
import { mergeHeadAndRowsArrayToJson } from '../../classes/jsonSerialize'
import { getCompetencyColorBasedOnActivesAndDevices } from '../../classes/competencyHelper'
import { spaceMarkers } from '../../classes/spacing'
import JackUps from '../../img/mapicons/png/Jackup.png'
import Floaters from '../../img/mapicons/png/SemiSub.png'
import OfficeLocation from '../../img/mapicons/png/OfficeBuilding.png'
import Ship from '../../img/mapicons/WhiteShip.svg'
import { addPageFilter, resetPageFilter } from '../../redux/actions'
import { connect } from 'react-redux'
import WithRouter from 'react-router-dom/withRouter'
import { Button } from 'semantic-ui-react'
import { getMapIconByRegion } from './mapComponents/mapIcons'
import FloaterIcon from '../../img/3t_logo.svg'
import HarshEnvIcon from '../../img/mapicons/SemiSub.svg'
import JackupIcon from '../../img/mapicons/Jackup.svg'
import DefaultIcon from '../../img/mapicons/OfficeBuilding.svg'

const markerStyle = (marker) => {
  return {
    default: { fill: marker.color, strokeWidth: '0px' },
    hover: { fill: marker.color, strokeWidth: '10', stroke:'black'  },
    pressed: { fill: marker.color, strokeWidth: '10', stroke: marker.color }
  }
}

class WorldMapV2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hoveredMarkerInfo: null,
      hoveredIndex: null,
      joinedMapData: null,
      mouseIn: false,
      timeOut: null,
      popupIndex: false
      }
    this.handleOnHover = this.handleOnHover.bind(this)
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this)
    this.setMouseIn = this.setMouseIn.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleClickOff = this.handleClickOff.bind(this)
  }
  componentDidUpdate (prevProps, prevState, snapshot) {    
    ReactTooltip.rebuild()
    if (prevProps.mapData !== this.props.mapData && this.props.mapData) {
      this.setState({ mapData: this.processMapDate(this.props.mapData)})
    }
  }
  handleOnClick = (id) => {
      //return;
      clearTimeout(this.state.timeOut)
      let timeout = setTimeout(() => {
          if (this.state.popupIndex === null || this.state.popupIndex !== id) {
              this.setState({ hoveredMarkerInfo: this.state.mapData[id], popupIndex: id })
          }
      },100)
      this.setState({ hoveredIndex: id, timeOut: timeout})
  }
    handleClickOff = () => {
        clearTimeout(this.state.timeOut)
        let timeout = setTimeout(() => {
            if (this.state.popupIndex !== null) {
                this.setState({ hoveredMarkerInfo: null, hoveredIndex: null, popupIndex: null })
            }
        }, 100)
        this.setState({timeOut: timeout})
    }
   handleOnHover(id) {
      clearTimeout(this.state.timeOut)
      // wait longer if popup is already up
      let waitTime = this.state.popupIndex === null ? 300 : 400;
      let timeout = setTimeout(() => {
      if (this.state.popupIndex === null || this.state.popupIndex !== id) {
        this.setState({ hoveredMarkerInfo: this.state.mapData[id], popupIndex: id })
      }
    }, waitTime)
    this.setState({ hoveredIndex: id, mouseIn: true, timeOut: timeout })
  }

  setMouseIn (v) {
    this.setState({ mouseIn: v })
  }
  handleOnMouseLeave (mouseOut = false) {
    clearTimeout(this.state.timeOut)
    let timeout = setTimeout(() => {
      if (!this.state.mouseIn) {
        this.setState({ hoveredMarkerInfo: null, hoveredIndex: null, mouseIn: false, popupIndex: null })
      }
    }, 200)
    this.setState({ mouseIn: false, timeOut: timeout })
  }
  getSelectedHoverIcon= (region) => {
    switch (region) {
      case 'Floaters':
        return Ship
      case 'Harsh Environment':
        return Floaters
      case 'Jack Ups':
        return JackUps
      default:
        return OfficeLocation
    }
  }  
  getUserDetailsForLocation = (location, userExerciseDetails) => {
    let result = [];
    if (location instanceof Object && userExerciseDetails instanceof Array){
      if (location.users instanceof Array){
        const locationUserIds = location.users.map(y => y.id)
        result = userExerciseDetails.filter(x => locationUserIds.includes(x.userId));
      }
    }
    
    return result;
  }
  getAvgCompetencyForUsers = (userExerciseDetails) => {
    if (userExerciseDetails instanceof Array) {
      return userExerciseDetails.reduce((accumulated, current) => accumulated + current.competency, 0) / Math.max(userExerciseDetails.length, 1);
    }

    return 
  }  
  processMapDate(data){
    // set up initial data
    let mapMarkersForSpace = [];
    if (data.locations){
      mapMarkersForSpace = data.locations.map(l => {
        return {
            otrId: l.rigId,
            active: l.active,
            coordinates: [l.longitude, l.latitude],
            color: getCompetencyColorBasedOnActivesAndDevices((l.competency + l.compliance) / 2 * 100, l.activeUsers, l.registeredDevices, true),
            name: l.name,
            region: l.rigType,
            devices: l.registeredDevices,
            users: l.registeredUsers,
            actives: l.activeUsers,
            competency: l.competency,
            compliance: l.compliance,
            icon: this.getSelectedHoverIcon(l.rigType),
        }}
      );
    }
      let spacedMarkers = spaceMarkers(mapMarkersForSpace).sort((a, b) => (a.competency > b.competency) ? 1 : -1)
    return spacedMarkers
    }
  render () {
    const { mapData } = this.props
    let mapDataProcessed = []
    if (mapData) {
      mapDataProcessed = this.processMapDate(mapData);
    }
    return (
      <React.Fragment>
            <HoverInfo markerInfo={this.state.hoveredMarkerInfo} id={this.state.hoveredIndex} handleClickOff={this.handleClickOff} handleOnEnter={this.handleOnHover} handleOnLeave={this.handleOnMouseLeave} setMouseIn={this.setMouseIn} />        
        <ReactTooltip id='svgTooltip' className='otrTooltip' />
          <ComposableMap style={{ width: '100%', height: '100%'}}>
          <ZoomableGroup center={[ 20, 30 ]} disablePanning >
            <Geographies geography={map}>
              {(geographies, projection) => geographies.map((geography, index) => (
                <Geography
                  key={index}
                  geography={geography}
                  projection={projection}
                  style={{
                    default: { fill: '#d3d3d3' },
                    hover: { fill: '#d3d3d3' },
                    pressed: { fill: '#d3d3d3' }
                  }}
                />
              ))}
            </Geographies>
            <Markers> 
            {
              mapDataProcessed.map((marker, i) => (
                <Marker key={i} marker={marker} style={markerStyle(marker)}>
                  <Button>{Floaters}</Button>
                  { 
                          <a id={`markerLink-${i}`} onClick={() => { this.handleOnClick(i) }}
                              onMouseEnter={() => { this.handleOnHover(i) }} onMouseLeave={() => { this.handleOnMouseLeave(); this.setMouseIn(false) }}                          >
                      { getMapIconByRegion(marker) }                    
                    </a>                                        
                  } 
                </Marker>))  
            }          
            </Markers>
          </ZoomableGroup>
        </ComposableMap>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  resetPageFilter,
  addPageFilter
}
export default connect(null, mapDispatchToProps)(WithRouter(WorldMapV2))
