import Papa from 'papaparse'
import _ from 'lodash'

const downloadCSV = (data, fileName = 'otr-download') => {
  const processedData = data.map(c => {
    const keyValues = Object.keys(c).map(key => {
      const newKey = _.startCase(key)
      return { [newKey]: c[key] }
    })
    return Object.assign({}, ...keyValues)
  })

  const csvString = Papa.unparse(processedData)
  const uri = `data:text/csv;charset=utf-8,${csvString}`

  const downloadLink = document.createElement('a')
  downloadLink.href = uri
  downloadLink.download = `${fileName}.csv`

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export { downloadCSV }
