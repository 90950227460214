import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import OtrModal from '../../../components/otrModal'
import OtrTextInput from '../../../components/otrTextInput'

export default class AddUserModal extends Component {
  state = { modalOpen: false }
  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

  validSubmit = (data) => {
    this.handleClose()
    this.props.validSubmit(data)
  }

  render () {
    return (
      <OtrModal
        title='ADD USER'
        trigger={<Button primary onClick={this.handleOpen}>ADD</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Form onValidSubmit={this.validSubmit}>
          <Grid>
            <OtrTextInput
              title='Email Address'
              name='emailAddress'
              validations='isEmail'
              validationErrors={{ isEmail: 'Please use a valid email address' }}
            />
            <OtrTextInput
              title='Password'
              name='password'
              validations={{ minLength: 6, matchRegexp: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/ }}
              validationErrors={{
                minLength: 'Password should be longer than 6 characters',
                matchRegexp: 'Password should include at least one number, uppercase character and special character'
              }}
            />
            <Grid.Row style={{ textAlign: 'right', paddingTop: '1.5rem' }}>
              <Grid.Column>
                <Button primary style={{ margin: 0 }}>
                  SAVE
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </OtrModal>
    )
  }
}
