import React, { Component } from "react";
import ScreenHeader from '../../components/screenHeader'
import api from '../../classes/api'
import { Table, Menu, Segment } from "semantic-ui-react";
import _ from 'lodash'

import exerciseResult from '../../classes/exerciseResult'
import OtrLoadingSpinner from "../../components/otrLoadingSpinner";

export default class Matrix extends Component {
	
	loadingParts = 0
	canFilterUsers = false

	updateLoading(change){

		this.loadingParts += change

		if(this.loadingParts === 0){
			this.setState({exerciseDetails: this.state.exerciseDetails.sort((a, b) => {return this.getExerciseName(a.exerciseId).localeCompare(this.getExerciseName(b.exerciseId)) })}) 
			this.forceUpdate()
		}
		
	}

    state = {
        users: [],
        exerciseDetails: [],
        userStats: [],
		exercises: [],
		environment: null,
		jobRoles: [],
        clientPagingOffset: 0
    }
    initialState(environment){
        this.setState({
			users: new Array(),
			exerciseDetails: new Array(),
			userStats: new Array(),
			exercises: new Array(),
			environment: environment,
			jobRoles: new Array(),
			clientPagingOffset: 0
		})
	}
	
	// get the current environment and start loading jobroles
	async refreshFilterData () {
		this.updateLoading(1)
		const environment = _.get(this.props, 'match.params.environment', null)

		this.initialState(environment)

		let result
		if (environment) {
			
			result = await api.allEnvironments([environment])
		}
		else{
			result = await api.allEnvironments()
		}

		if (result.success && result.data.length > 0) {
			const filter = result.data[0].Id
			this.setState({ environment, filter })

			this.canFilterUsers = false			
			this.loadExercises()
			this.loadJobRoles()
			this.loadUserData()


			this.loadEnvironments()
		}

		this.updateLoading(-1)
	  }

	// get the relevant job roles and start loading the user data and exercise data
	async loadJobRoles () {
		this.updateLoading(1)
		let result
		
		result = await api.allJobRoles()
		
		this.setState({exerciseDetails: []})

		if (result.success) {
			const  data = result.data.filter(j => j.environment === this.state.environment)
			this.setState({ jobRoles: data })

			this.checkCanFilterUsers()

			let idsToLoad = []
			this.state.jobRoles.forEach(jr => {
				jr.exercises.forEach(e => {
						if(idsToLoad.findIndex(i => i === e.exerciseId) === -1){							
							// This is very hacky, but this is an utter mess. Quicker to do this due to lack of time and other pressing projects.
							let name = this.getExerciseName(e.exerciseId);
							if (name === -1)
								return;

							if (!name.includes('dd3') && !name.includes('DD3'))
								idsToLoad.push(e.exerciseId)
						}
				})
			})
			idsToLoad.forEach(id => {
				this.loadExerciseData(id)
			})

		}
		this.updateLoading(-1)
	}

	// get the relevant users and load their stats
    async loadUserData () {
		this.updateLoading(1)
        let result
        result = await api.allUsers()

        if (result.success) {

			let { data } = result.data
			data.sort((a, b) => {return a.userName.localeCompare(b.userName)})
			this.setState({ users: data })
			this.checkCanFilterUsers()
		}
		this.updateLoading(-1)
	}
	
	checkCanFilterUsers(){
		if(this.canFilterUsers){
			this.setState({ users: this.state.users.filter(u => u.jobRoles.findIndex(j => this.state.jobRoles.findIndex(k => k.id === j.id) !==-1) !== -1) })

			this.setState({ userStats: [] })
            this.state.users.forEach(u => {
				this.loadUserStats(u.id)
			})
		}
		else{
			this.canFilterUsers = true
		}
	}

	// get the user completion statistics
	async loadUserStats (userId) {
		this.updateLoading(1)
		let result
		
		result = await api.studentUsageLog(userId, undefined, 0)
		
	
		if (result.success) {
			const { data} = result.data
			if (data.length > 0){
				this.state.userStats.push({id: userId, value: data})
				this.setState({ userStats: this.state.userStats })
			}
		}
		this.updateLoading(-1)
	}

	// 
    async loadExerciseData (exerciseId) {
		this.updateLoading(1)
		
        let result
            result = await api.exerciseDetails(exerciseId)

        if (result.success) {
			const  data = result.data
			if(this.state.exerciseDetails.findIndex(e => e.exerciseId === data.exerciseId) === -1){
				this.state.exerciseDetails.push(data)
				this.setState({exerciseDetails: this.state.exerciseDetails})
			}
			
		}
		this.updateLoading(-1)
        
    }

    async loadExercises () {
		this.updateLoading(1)
		let result
			result = await api.allExercises()

		if (result.success) {
			const  data = result.data
			this.setState({ exercises: data })
		}
      this.updateLoading(-1)
	}

	async loadEnvironments () {
		this.updateLoading(1)
		let result
		
		result = await api.allEnvironments()
		
	
		if (result.success) {
			const  data = result.data
			this.setState({ environments: data })
		}
		this.updateLoading(-1)
	}

	// on page load...
    componentDidMount () {
		this.refreshFilterData()
	}
	
	// on environment changed or finished loading...
	async componentDidUpdate () {
		const filterIdParam = _.get(this.props, 'match.params.environment', null)
	
		if (filterIdParam === null) {
			if (this.state.environment !== null) {
				this.setState({environment: undefined})
			}
			return
		}
	
		if (filterIdParam !== this.state.environment) {
			this.refreshFilterData()
		}
	}

	// set the contents of a cell

	getCompletionStats(value){

		let total = 0
		this.state.userStats.forEach(role => {
			role.value.forEach(status => {
				if(exerciseResult.toString(status.result) === value){
					total++
				}
			})
		})

		return value + ': ' + total
	}


    getCellValue(user, exercise){
		let symbol = ' '

		const stats = this.state.userStats.filter(i => i.id === user.id)

		const jobRoles = this.state.jobRoles.filter(jr => user.jobRoles.findIndex(ujr => ujr.id === jr.id) !== -1)

		const exerciseInfo = this.state.exercises.find(e => e.exerciseId === exercise.exerciseId)
		
		if(jobRoles.length > 0){
			let passed = false
			let failed = false
			let aborted = false
			let unknown = false
			jobRoles.forEach(role => {
				if(role.exercises.findIndex(r => r.exerciseId === exerciseInfo.exerciseId) !== -1){
					stats.forEach(s => {
						if(s.value.findIndex(v => v.exercise === exerciseInfo.name && exerciseResult.toString(v.result) === "Passed") !== -1){
							
							passed = true
						}
						if(s.value.findIndex(v => v.exercise === exerciseInfo.name && exerciseResult.toString(v.result) === "Failed") !== -1){
							failed = true
						}
						if(s.value.findIndex(v => v.exercise === exerciseInfo.name && exerciseResult.toString(v.result) === "Aborted") !== -1){
							aborted = true
						}
						
						if(s.value.findIndex(v => v.exercise === exerciseInfo.name && exerciseResult.toString(v.result) === "Unknown") !== -1){
							unknown = true
						}
					})
					if(stats.length === 0){
						unknown = true
					}
					
				}
			})

			if(passed){
				symbol = '🟢'
			}
			else if(failed){
				symbol = '🔴'
			}
			else if(aborted){
				symbol = '🟠'
			}
			else if(unknown){
				symbol = ''
			}
			
		}
        return symbol;
	}

	getCellColour(user, exercise){
		switch(this.getCellValue(user, exercise)){
			// case 'X':
			// 	return '#FF4444'
			// case 'O':
			// 	return '#44CC44'
			case ' ':
				return '#dddddd'
			default:
				return '#FFFFFF'
		}
	}

	getTooltipText(user, exercise){
		const exerciseInfo = this.state.exercises.find(e => e.exerciseId === exercise.exerciseId)

		const stats = this.state.userStats.filter(i => i.id === user.id)
		let attempts = 0
		stats.forEach(element => {
			stats.forEach(s => {
					attempts += s.value.filter(v => v.exercise === exerciseInfo.name).length
			})
		});
		if(attempts === 1 ){
			return attempts + ' attempt'
		}
		if(attempts > 0){
			return attempts + ' attempts'
		}
		return ''
	}

	showHideTooltip(id, user, exercise, show){
		
		const el = document.getElementById(id)
		
		const text = this.getTooltipText(user, exercise)
		const shouldElementShow = show && (text !== '')
		
		if(el !== null){
			el.style.display = shouldElementShow? 'block': 'none'
		}
	}

	
	// get the name of a given exercise
	getExerciseName(exerciseId){

		if(this.state.exercises.findIndex(e => e.exerciseId === exerciseId) !== -1){
			return this.state.exercises.find(e => e.exerciseId === exerciseId).name
		}
		return -1
	}

    clientPagingationPressed = (offset) => {
        this.setState({ clientPagingOffset: offset })
	}

    renderPagination = (pageSize, totalRows, pageOffset, paginationPressed, extendedPaging) => {
        const pageCount = Math.ceil(totalRows / pageSize)
        const currentPageCount = Math.ceil(pageOffset / pageSize)
    
        if (pageCount < 2) {
          return null
        }
    
        if ( !extendedPaging )
        {
			return (
				<Table.Footer>
				<Table.Row>
					<Table.HeaderCell colSpan={this.state.exerciseDetails.length + 1}> 
					<Menu pagination text>
						{pageOffset > 0 &&
						<Menu.Item
							as='a'
							icon
							onClick={() => { paginationPressed(pageOffset - pageSize) }}
							style={{ paddingRight: '1.5rem', fontWeight: 'bold' }}
						>
							PREVIOUS
						</Menu.Item>
						}
						{
						[...Array(pageCount)].map((_, i) => (
							<Menu.Item
							key={i}
							as='a'
							active={((i*pageSize) === pageOffset)}
							onClick={() => { paginationPressed(i * pageSize) }}
							style={{ fontWeight: 'bold'}}
							>
							{((i*pageSize) === pageOffset)?"(":""}{i + 1}{((i*pageSize) === pageOffset)?")":""}
							</Menu.Item>
						))
						}
						{currentPageCount !== (pageCount - 1) &&
						<Menu.Item as='a' icon onClick={() => { paginationPressed(pageOffset + pageSize) }} style={{ fontWeight: 'bold'}}>
							NEXT
						</Menu.Item>
						}
					</Menu>
					</Table.HeaderCell>
				</Table.Row>
				</Table.Footer>
			)
        }
        else
        {
			var pageArray = []
			var startPage = (currentPageCount-2) > 1 ? currentPageCount-2 : 1
			var endPage = (currentPageCount+4) < pageCount ? currentPageCount+4 : pageCount
		
			
			for ( var workingPage = startPage ; workingPage <= endPage ; workingPage++)
			{
				pageArray.push(workingPage)
			}
			var arrayFirstPage = pageArray[0]
			var arrayLastPage = pageArray[pageArray.length-1]
			return (
				<Table.Footer>
				<Table.Row>
				<Table.HeaderCell colSpan={100} > 
					<Menu pagination text>
					{pageOffset > 0 &&
						<Menu.Item
						as='a'
						icon
						onClick={() => { paginationPressed(pageOffset - pageSize) }}
						style={{ paddingRight: '1.5rem', fontWeight: 'bold' }}
						>
						PREVIOUS
						</Menu.Item>
					}
					{arrayFirstPage > 1 && 
						<Menu.Item
						as='a'
						icon
						onClick={() => { paginationPressed(0) }}
						style={{ paddingRight: '1.5rem', fontWeight: 'bold' }}
						>
						1
						</Menu.Item>
					}
					{arrayFirstPage > 1 && 
						<Menu.Item
						as='a'
						icon
						active={false}
						onClick={() => { }}
						style={{ paddingRight: '1.5rem', fontWeight: 'bold' }}
						>
						...
						</Menu.Item> 
					}
					{
						pageArray.map( linkPage => (
						<Menu.Item
							key={(linkPage-1)}
							as='a'
							active={(((linkPage-1)*pageSize) === pageOffset)}
							onClick={() => { paginationPressed((linkPage-1) * pageSize) }}
						>
							{(((linkPage-1)*pageSize) === pageOffset)?"(":""}{linkPage}{(((linkPage-1)*pageSize) === pageOffset)?")":""}
						</Menu.Item>
						))
					}
					{arrayLastPage < pageCount &&
						<Menu.Item
						as='a'
						icon
						active={false}
						onClick={() => { }}
						style={{ paddingRight: '1.5rem' }}
						>
						...
						</Menu.Item> 
					}
					{arrayLastPage < pageCount &&
						<Menu.Item
						as='a'
						icon
						onClick={() => { paginationPressed((pageCount-1)*pageSize) }}
						style={{ paddingRight: '1.5rem' }}
						>
						{pageCount}
						</Menu.Item> 
					}
					{currentPageCount !== (pageCount - 1) &&
						<Menu.Item as='a' icon onClick={() => { paginationPressed(pageOffset + pageSize) }} style={{ fontWeight: 'bold'}}>
						NEXT
						</Menu.Item>
					}
		
					</Menu>
				</Table.HeaderCell>
				</Table.Row>
			</Table.Footer>
			)      
		}
	}

    render () {
        let {
            pageSize = 15,
            pageOffset = 0,
            paginationPressed = this.clientPagingationPressed,
            extendedPaging = false
        } = this.props
        
        const { clientPagingOffset } = this.state

        let filteredUserData = this.state.users//.slice(clientPagingOffset, clientPagingOffset + pageSize)
        let totalRows = this.state.users.length
        pageOffset =clientPagingOffset;

        return (
			
            <React.Fragment>
				<ScreenHeader title='MATRIX VIEW' />
				{ (this.loadingParts === 0 && this.state.environment) && (

					<div>
						<div id='Stats'  style={{marginTop: '10px', marginBottom: '15px'}}>
							<span id = 'passedStats' style={{background: '#34cd2b', padding: '10px'}}>
								{this.getCompletionStats('Passed')}
							</span>
							
							<span id = 'abortedStats' style={{background: '#f8772b', padding: '10px'}}>
								{this.getCompletionStats('Aborted')}
							</span>
							
							<span id = 'failedStats' style={{background: '#eb3140', padding: '10px'}}>
								{this.getCompletionStats('Failed')}
							</span>
							
						</div>

						<div id='Matrix' style={{overflowX: "auto"}}>
							<Table style={{width: '1rem'}}>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell style={{border: 'solid #EEEEEE 1px', borderBottomColor: '#8899bc', borderRightColor: '#8899bc', borderBottomWidth: 2, borderRightWidth: 2}}></Table.HeaderCell>
										{ this.state.exerciseDetails.map((item, index) => (
											<Table.HeaderCell key={this.state.environment + '/' + item.exerciseId } style={{border: 'solid #EEEEEE 1px', borderBottomColor: '#8899bc', borderBottomWidth: 2, width: '1rem'}}>
												<div style={{writingMode: 'vertical-lr', height: '10rem', transform: 'rotate(180deg)' }}>{this.getExerciseName(item.exerciseId)}</div>
											</Table.HeaderCell>
										))}
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{filteredUserData.map(user => (
										<Table.Row key={user.id} >
											<Table.Cell style={{border: 'solid #EEEEEE 1px', borderRightColor: '#8899bc', borderRightWidth: 2}}>
												<div style={{width: '10rem'}}>
													{user.userName}
												</div>
											</Table.Cell>
											{ this.state.exerciseDetails.map((item, index) => (
												<Table.Cell  
													id={this.state.environment + '/' + user.id + '/' + item.exerciseId } 
													key={this.state.environment + '/' + user.id + '/' + item.exerciseId } 
													style={{border: 'solid #EEEEEE 1px', backgroundColor: this.getCellColour(user, item), position: 'relative', alignContent: 'center', textAlign: 'center'}}
													onMouseOver={() => this.showHideTooltip(this.state.environment + '/' + user.id + '/' + item.exerciseId + '/hover', user, item, true)}
													onMouseOut={() => this.showHideTooltip(this.state.environment + '/' + user.id + '/' + item.exerciseId + '/hover', user, item, false)}
												>
													<span 
														id={this.state.environment + '/' + user.id + '/' + item.exerciseId + '/hover' }
														style={{
															position: 'absolute', 
															zIndex: 1, 
															left: '0px',
															top: '-20px', 
															whiteSpace: 'nowrap',
															display: 'none'
														}}
														>
														<span  style={{ padding: '10px',background: '#00369a', color: 'white'}}>{this.getTooltipText(user, item)}</span>
														<div style={{
															width: '10px',
															borderTop: 'solid #00369a 10px',
															borderLeft: '10px solid transparent',
															borderRight: '10px solid transparent',
															marginLeft: 'auto',
															marginRight: 'auto',
															marginTop: '5px'
															}}></div>
														</span>
													<span variant="contained" color="primary" style={{marginLeft: 'auto',marginRight: 'auto'}}>
														{this.getCellValue(user, item)}
													</span>
													
												</Table.Cell>
											))}
										</Table.Row>
									))}
								</Table.Body>
								{/* {this.renderPagination(pageSize, totalRows, pageOffset, paginationPressed, extendedPaging)} */}
							</Table>
						</div>
					
					</div>
				)}
				{ this.loadingParts !== 0 && (
					<Segment>
						<OtrLoadingSpinner shown name='Matrix View' />
					</Segment>
				)}
                
            </React.Fragment>
        )
    }
}
