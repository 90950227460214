import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Header, Responsive } from 'semantic-ui-react'
import squareImage from '../img/square.svg'

export default class MenuLink extends Component {
  render () {
    const { to, title, exact = true, icon = squareImage } = this.props
    return (
      <NavLink exact={exact} to={to} className='menuLink'>
        <Responsive minWidth={1025}>
          <img alt={`Menu Icon for item: ${title}`} style={{ height: '2rem', width: '2rem' }} src={icon} />
        </Responsive>
        <Header inverted as='h5'>{title}</Header>
      </NavLink>
    )
  }
}
