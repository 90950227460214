import { ADD_PAGE_FILTER, REMOVE_PAGE_FILTER, RESET_PAGE_FILTER, SET_PAGE_REPORT_DATA, SET_PAGE_DATA } from './actionTypes'

export function addPageFilter (payload) {
  return { type: ADD_PAGE_FILTER, payload }
}
export function removePageFilter (payload) {
  return { type: REMOVE_PAGE_FILTER, payload }
}
export function resetPageFilter (payload) {
  return { type: RESET_PAGE_FILTER, payload }
}
export function setPageReportData (payload) {
  return { type: SET_PAGE_REPORT_DATA, payload }
}
export function setPageData (payload) {
  return { type: SET_PAGE_DATA, payload }
}