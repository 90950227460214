const resultEnumString = (resultEnum) => {
    switch (resultEnum)
    {
        case 1:
            return "Passed"
            break
        case 2:
            return "Failed"
            break
        case 3:
            return "Aborted"
            break
        default:
            return "Unknown"
    }
  }

  const exerciseResult = {
    toString: (resultEnum) => resultEnumString(resultEnum)
  }

  export default exerciseResult