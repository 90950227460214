import React, { Component } from "react";
import ScreenHeader from "../../components/screenHeader";
import { Button, Container, Divider, Header, Label, Message } from "semantic-ui-react";
import { Checkbox, Form } from "formsy-semantic-ui-react";
import * as XLSX from 'xlsx-js-style'
import api from "../../classes/api";

const setAllCheckboxId = 'SetAllCheckbox';
const setAllCheckboxIndeterminate = 'SetAllCheckboxIndeterminate';

const exportOptions = [
    { id: 'AL', name: "Activity Logs", getData: async () => await api.allActivityLog() },
    { id: 'Dev', name: "Devices", getData: async () => await api.getDevicesTableExport() },
    { id: 'Env', name: "Environments", getData: async () => await api.allEnvironments() },
    { id: 'Ex', name: "Exercises", getData: async () => await api.getExercisesTableExport() },
    { id: 'ExR', name: "Exercise Results", getData: async () => await api.allExerciseResults() },
    { id: 'Fbk', name: "Feedback", getData: async () => await api.getAllFeedback() },
    { id: 'JR', name: "Job Roles", getData: async () => await api.getJobRolesTableExport() },    
    { id: 'Rel', name: "Releases", getData: async () => await api.getReleasesTableExport() },
    { id: 'Rig', name: "Rigs", getData: async () => await api.getRigsTableExport() },
    { id: 'Usr', name: "Users", getData: async () => await api.getUsersTableExport() }
];

export default class ExportScreen extends Component {
    constructor(props){ 
        super(props);
        this.state = {
            exporting: false,
            exportingAll: false,
            warning: null,
            error: null,
            success: null,
            options: {}
        }
    }

    handleToggleChange = (e, data) => {
        let { options } = this.state;         
        if (data.name === setAllCheckboxId){   
            // update all options to match check
            exportOptions.forEach(option => options[option.id] = data.checked);

            // if we've changed the set all toggle, it cant be indeterminate
            options[setAllCheckboxIndeterminate] = false;
            options[setAllCheckboxId] = data.checked;
        } else {
            options[data.name] = data.checked;

            // if not all checkboxes are the same, then set setAll to indeterminate
            const indeterminate = !exportOptions.every(x => {
                console.log(x.id, options[x.id]);
                if (options[x.id] === undefined){
                    options[x.id] = false
                }
                return options[x.id] === data.checked 
                });
            options[setAllCheckboxIndeterminate] = indeterminate;
            options[setAllCheckboxId] = !indeterminate && data.checked === true;
        }

        this.setState({ options })
    }

    handleSubmit = async (event) => {

        this.setState({ exporting: true, warning: null, error: null, success: null })

        const result = await this.runExport(this.state.options);     

        this.setState({ exporting: false, warning: result.warning, error: result.error, success: result.success })
    }

    handleSubmitAll = async (event) => {

        this.setState({ exportingAll: true, warning: null, error: null, success: null })

        let options = {}
        exportOptions.forEach(x => options[x.id] = x.getData);
        const result = await this.runExport(options);     

        this.setState({ exportingAll: false, warning: result.warning, error: result.error, success: result.success })
    }

    setAll = (toggled) => {
        exportOptions.forEach(option => this.handleToggleChange(null, { name: option.id, checked: toggled }));
    }

    isIndeterminate = () => {
        let prevVal;
        for (const option of exportOptions){
            if (this.state[option.id]){
                if (prevVal && prevVal !== this.state[option.id]){
                    return true;
                }

                prevVal = this.state[option.id];
            }
        }
        return false;
    }

    runExport = async (options) => {
        let result = {
            data: {},
            error: null,
            warning: null,
            success: null
        };

        // filter by ticked boxes
        const selectedOptions = exportOptions.filter(x => options[x.id]);
        if (selectedOptions.length === 0){
            result.warning = "No export options selected!";
            return result;
        } 

        // get data for selected options
        result = await this.getData(selectedOptions);
        if (result.error){
            return result;
        }

        // create workbook        
        let wb = XLSX.utils.book_new();

        // create sheets for data
        let ws = await this.buildWorksheets(selectedOptions, result.data);

        // append sheets to wb
        for (const sheetName of Object.keys(ws)){
            XLSX.utils.book_append_sheet(wb, ws[sheetName], sheetName.toLowerCase())
        }

        if (wb.SheetNames.length === 0){
            result.error = "A problem was encountered when building the xlsx workbook. Please contact support.";
            return result;
        }

        // download
        let filename = `AcademyDataExport_${selectedOptions.length <= 1 ? selectedOptions[0].name.replace(' ','') : 'Multi'}.xlsx`;
        XLSX.writeFile(wb, filename);

        if (!result.error&& !result.warning){
            result.success = `Completed data export to file ${filename}`
        }

        return result;
    }

    buildWorksheets = async (selectedOptions, data) => {
        let result = {};
        for (const option of selectedOptions){
            // format data
            let dataResult = data[option.id]
            if (dataResult.data === null) continue;

            let cleanedName = this.cleanName(option.name);
            let formattedData = await this.formatData(cleanedName, dataResult.data);
            if (formattedData === null) continue;

            let dataObj = formattedData[cleanedName]
            if (dataObj){
                let keys = Object.keys(dataObj);
                for (const key of keys){
                    let sheetName = (key !== cleanedName) ? `${cleanedName}_${key}` : key;
                    if (!result[sheetName]){                        
                        // create sheet
                        result[sheetName] = XLSX.utils.aoa_to_sheet(dataObj[key]);
                    } else {
                        XLSX.utils.sheet_add_aoa(result[sheetName], dataObj[key].slice(1));
                    }
                }
            }
        }

        return result;
    } 

    cleanName = (name) => {
        return name.replace(' ', '').toLowerCase()
    }

    getData = async (options = []) => {
        let result = {
            data: {},
            error: null
        }

        try{
            let option, data;
            for (let i=0; i<options.length; i++){
                option = options[i];
                if (option.getData instanceof Function){
                    data = await option.getData();
                    result.data[option.id] = data;
                } else if (!option.getData) {
                    throw new Error(`${option.name} data endpoint is undefined!`)
                }
            }            
        } catch (err) {
            result.error = err.message;
        }

        return result;
    }

    formatData = async (name, data = {}) => {
        let formattedData = {};
        
        if (data.total && data.total === 0){
            console.log("No data to format!");
            return formattedData;
        }

        formattedData[name] = await this.flattenObjectArray(name, data.data ? data.data : data);
        return formattedData;
    }
    
    flattenObjectArray = async (name, objects = [], relatedObject = {}) => {        
        let result = {};      
        
        if (objects.length === 0){
            return result;
        }

        // we need to remove array columns
        const keys = Object.keys(objects[0]);
        const arrayFields = keys.filter(x => (objects[0][x] instanceof Array));
        const columnTitles = keys.filter(x => !arrayFields.includes(x));

        if (relatedObject && relatedObject.name){
            columnTitles.unshift(relatedObject.name);
        }

        // set up arrays
        result[name] = [];
        result[name].push(columnTitles); 

        // populate array
        for (const object of objects){
            // main
            let column = []
            columnTitles.forEach(y => {
                let val = object[y];
                if (relatedObject && relatedObject.name && relatedObject.name === y){
                    val = relatedObject.id;
                }

                column.push(val);
            });
            result[name].push(column);

            // array fields
            for (const arrayField of arrayFields){                
                let flattened = await this.flattenObjectArray(arrayField, object[arrayField]);
                if (flattened[arrayField]){
                    result[arrayField] = (result[arrayField] instanceof Array) ? result[arrayField].concat(flattened[arrayField].slice(1)) : flattened[arrayField];
                }
            }
        }

        return result;
    }

    render(){        
        const { history } = this.props;
        const { exporting, exportingAll, error, success, warning, options } = this.state;
        const loading = exporting || exportingAll;
        return (
            <React.Fragment>
                <ScreenHeader
                    title="Export"
                    history={history}
                />
                <Container style={{ 'background-color':'#EFEFEF', 'padding':'1.5rem', 'border-radius':'8px' }}>
                    <Header>Data Export</Header>
                    <p>Choose the data you wish to export</p>
                    <Divider/>
                    <Form onSubmit={this.handleSubmit}>
                        {
                            exportOptions.map(
                                o => <Form.Checkbox 
                                    key={`id_${o.id}`} 
                                    name={o.id} label={o.name} checked={options[o.id]} 
                                    onChange={this.handleToggleChange} disabled={loading}
                                />
                            )
                        }         
                        {/*<Checkbox name={setAllCheckboxId} label='All' onChange={this.handleToggleChange} checked={options[setAllCheckboxId]} disabled={loading} indeterminate={options[setAllCheckboxIndeterminate]}/>*/ }
                                            
                    </Form>
                    <Divider/>
                    <Button style={{ backgroundColor: 'rgb(39, 177, 236)', color: '#FFFFFF' }} loading={exporting} disabled={exportingAll} onClick={this.handleSubmit}>Export</Button>   
                    <Button primary loading={exportingAll} disabled={exporting} onClick={this.handleSubmitAll}>Export All</Button>   
                </Container>     
                <Container>
                    <Form>
                        <Message
                            success
                            header="Export Completed!"
                            content={success}
                            visible={success!==null}
                        />
                        <Message
                            warning
                            header="Warning!"
                            content={warning}
                            visible={warning!==null}
                        />
                        <Message
                            error
                            header="Error!"
                            content={error}
                            visible={error!==null}
                        />
                    </Form>
                </Container>           
            </React.Fragment>
        )
    }
}