import React, { Component } from 'react'
import { Header, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { Link } from 'react-router-dom'
import '../../styles/login.css'
import api from '../../classes/api'
import PublicFrame from '../../components/publicFrame'
import OtrTextInput from '../../components/otrTextInput'
import queryString from 'query-string'

class ForgotPasswordScreen extends Component {
  constructor (props) {
    super(props)
    this.onReset = this.onReset.bind(this)
    this.state = {
      loading: false,
      complete: false,
      success: false
    }
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  getTokenFromProps(){
    const { location: { search } } = this.props    
    const params = queryString.parse(search);
    // query string converts + into spaces, so we must convert back. 
    // shouldn't be a problem as params shouldn't have whitespace
    return params.token ? params.token.replace(/\s/g, '+') : ''
  }

  onReset = async ({ password, emailAddress }) => {
    this.toggleLoading()
    const token = this.getTokenFromProps();
    const result = await api.resetPassword({ emailAddress, token, newPassword: password })
    this.toggleLoading()
    this.setState({ complete: true, success: result.success })
  }

  render () {
    const { loading, complete, success } = this.state

    const resetForm = (
      <React.Fragment>
        <Header as='h1' inverted>RESET YOUR PASSWORD</Header>
        <hr />
        <Form id='loginForm' name='loginForm' onValidSubmit={this.onReset}>
          <OtrTextInput size='huge' name='emailAddress' required placeholder='Email Address' validations='isEmail' validationErrors={{ isEmail: 'Email Address not valid' }} />          
          <Form.Input
            size='huge'
            name='password'
            placeholder='Password'
            type='password'
            required
            validations={{ minLength: 8, matchRegexp: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/ }}
          />
          <Form.Input
            size='huge'
            name='confirmPassword'
            placeholder='Confirm Password'
            type='password'
            required
            validations='equalsField:password'
          />
          <Button loading={loading} primary fluid size='huge'>CONFIRM</Button>
        </Form>
        <hr />
        <Link to='/login/'>REMEMBER YOUR LOGIN DETAILS?</Link>
      </React.Fragment>
    )

    const completeSuccess = (
      <React.Fragment>
        <Header as='h1' inverted>YOUR PASSWORD HAS BEEN RESET</Header>
        <Link to='/login/' replace>LOGIN WITH YOUR NEW DETAILS</Link>
      </React.Fragment>
    )

    const completeFailure = (
      <React.Fragment>
        <Header as='h1' inverted>THERE WAS A PROBLEM RESETTING YOUR PASSWORD</Header>
        <Link to='/forgot/' replace>TRY AGAIN</Link>
      </React.Fragment>
    )

    return (
      <PublicFrame>
        <div style={{ textAlign: 'center' }}>
          { complete && success ? completeSuccess : null }
          { complete && !success ? completeFailure : null }
          { !complete && resetForm }
        </div>
      </PublicFrame>
    )
  }
}

export default ForgotPasswordScreen
