const serializeToJson = (header, data, deep = 3) => {
  checkForCompetencyAndConvert(header, data)
  // TODO: HARDCODED FOR NOW, I HAVE TO DO DEEP JSON CREATION
  let json = {}
  data.forEach((row) => {
    if (row[0] === null) {
      row[0] = 'Region Not Assigned'
    }
    if (row[1] === null) {
      row[1] = 'Rig Not Assigned'
    }
    if (row[2] === null) {
      row[2] = 'JobRole Not Assigned'
    }
    if (!json[row[0]]) {
      json[row[0]] = {}
    }
    if (!json[row[0]][row[1]]) {
      json[row[0]][row[1]] = {}
    }
    if (!json[row[0]][row[1]][row[2]]) {
      json[row[0]][row[1]][row[2]] = {}
    }
    if (!json[row[0]][row[1]][row[2]][row[3]]) {
      json[row[0]][row[1]][row[2]][row[3]] = []
    }
    if (!json[row[0]][row[1]][row[2]][row[3]][row[4]]) {
      json[row[0]][row[1]][row[2]][row[3]][row[4]] = []
    }
    if (!json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]]) {
      json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]] = []
    }
    let exerciseRow = row.slice(6, row.length)
    json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]].push(exerciseRow)
  })
  console.log(json)
  return json
}
const checkForCompetencyAndConvert = (header, data) => {
  let competencyPosition
  header.forEach((h, index) => {
    if (h === 'Competency') {
      competencyPosition = index
    }
  })
  if (competencyPosition) {
    data.forEach((row) => {
      row[competencyPosition] = Number((row[competencyPosition] * 100).toFixed(0))
    })
  }
}
const serializeToJsonWithDeep = (header, data, depth = 4) => {
  checkForCompetencyAndConvert(header, data)
  // TODO: HARDCODED FOR NOW, I HAVE TO DO DEEP JSON CREATION
  let json = {}
  data.forEach((row) => {
    if (!json[row[0]]) {
      json[row[0]] = {}
    }
    switch (depth) {
      case 1:
        if (row[0] === null) {
          row[0] = header[0] + ' Not Assigned'
        }
        console.log(json)
        if (json[row[0]]) {
          json[row[0]] = []
        }
        json[row[0]].push(row.slice(depth, row.length))
        break
      case 2:
        if (row[0] === null) {
          row[0] = header[0] + ' Not Assigned'
        }
        if (row[1] === null) {
          row[1] = header[1] + ' Not Assigned'
        }
        if (!json[row[0]][row[1]]) {
          json[row[0]][row[1]] = []
        }
        json[row[0]][row[1]].push(row.slice(depth, row.length))
        break
      case 3:
        header.slice(0, depth).forEach((h, hIndex) => {
          if (row[hIndex] === null) {
            row[hIndex] = h + ' Not Assigned'
          }
        })
        if (!json[row[0]][row[1]]) {
          json[row[0]][row[1]] = {}
        }
        if (!json[row[0]][row[1]][row[2]]) {
          json[row[0]][row[1]][row[2]] = []
        }
        json[row[0]][row[1]][row[2]].push(row.slice(depth, row.length))
        break
      case 4:
        header.slice(0, depth).forEach((h, hIndex) => {
          if (row[hIndex] === null) {
            row[hIndex] = h + ' Not Assigned'
          }
        })
        if (!json[row[0]][row[1]]) {
          json[row[0]][row[1]] = {}
        }
        if (!json[row[0]][row[1]][row[2]]) {
          json[row[0]][row[1]][row[2]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]]) {
          json[row[0]][row[1]][row[2]][row[3]] = []
        }
        json[row[0]][row[1]][row[2]][row[3]].push(row.slice(depth, row.length))
        break
      case 5:
        header.slice(0, depth).forEach((h, hIndex) => {
          if (row[hIndex] === null) {
            row[hIndex] = h + ' Not Assigned'
          }
        })
        if (!json[row[0]][row[1]]) {
          json[row[0]][row[1]] = {}
        }
        if (!json[row[0]][row[1]][row[2]]) {
          json[row[0]][row[1]][row[2]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]]) {
          json[row[0]][row[1]][row[2]][row[3]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]][row[4]]) {
          json[row[0]][row[1]][row[2]][row[3]][row[4]] = []
        }
        json[row[0]][row[1]][row[2]][row[3]][row[4]].push(row.slice(depth, row.length))
        break
      case 6:
        header.slice(0, depth).forEach((h, hIndex) => {
          if (row[hIndex] === null) {
            row[hIndex] = h + ' Not Assigned'
          }
        })
        if (!json[row[0]]) {
          json[row[0]] = {}
        }
        if (!json[row[0]][row[1]]) {
          json[row[0]][row[1]] = {}
        }
        if (!json[row[0]][row[1]][row[2]]) {
          json[row[0]][row[1]][row[2]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]]) {
          json[row[0]][row[1]][row[2]][row[3]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]][row[4]]) {
          json[row[0]][row[1]][row[2]][row[3]][row[4]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]]) {
          json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]] = []
        }
        json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]].push(row.slice(depth, row.length))
        break
      case 7:
        header.slice(0, depth).forEach((h, hIndex) => {
          if (row[hIndex] === null) {
            row[hIndex] = h + ' Not Assigned'
          }
        })
        if (!json[row[0]][row[1]]) {
          json[row[0]][row[1]] = {}
        }
        if (!json[row[0]][row[1]][row[2]]) {
          json[row[0]][row[1]][row[2]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]]) {
          json[row[0]][row[1]][row[2]][row[3]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]][row[4]]) {
          json[row[0]][row[1]][row[2]][row[3]][row[4]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]]) {
          json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]] = {}
        }
        if (!json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]][row[6]]) {
          json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]][row[6]] = []
        }
        json[row[0]][row[1]][row[2]][row[3]][row[4]][row[5]][row[6]].push(row.slice(depth, row.length))
        break
    }
  })
  let fullObject = {}
  Object.keys(json).forEach((key) => {
    if (key !== 'null') {
      fullObject[key] = json[key]
    }
  })
  return fullObject
}
const mergeHeadAndRowsArrayToJson = (head, rows) => {
  let jsonObject = []
  rows.forEach((row, rId) => {
    let rowObject = {}
    head.forEach((head, i) => {
      rowObject[head] = row[i]
    })
    jsonObject.push(rowObject)
  })
  return jsonObject
}

export { serializeToJson, mergeHeadAndRowsArrayToJson, serializeToJsonWithDeep, checkForCompetencyAndConvert }
