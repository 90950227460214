import React, { Component } from 'react'
import { Header, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { Link } from 'react-router-dom'
import '../../styles/login.css'
import api from '../../classes/api'
import authService from '../../services/auth'
import PublicFrame from '../../components/publicFrame'
import OtrTextInput from '../../components/otrTextInput'

class LoginScreen extends Component {
  constructor (props) {
    super(props)
    this.onLogin = this.onLogin.bind(this)
    this.state = {
      loading: false,
      error: false
    }
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: (!loading) })
  }

  onLogin = async ({ username, password }) => {
    this.toggleLoading()
    const result = await api.authLogin(username, password)
    
    this.toggleLoading()
    if (result.success) {
      authService.setToken(result.data.token)
      const ch = await api.OTRAutoCreateFilter('')
      this.props.history.push('/')
    } else {
      this.setState({ error: true })
    }
  }

  render () {
    const { loading, error } = this.state
    return (
      <PublicFrame>
        <div style={{ textAlign: 'center' }}>
          <Header as='h2' inverted>LOGIN TO YOUR ACCOUNT</Header>
          <hr />
          <Form id='loginForm' name='loginForm' onValidSubmit={this.onLogin}>
            <OtrTextInput size='huge' name='username' placeholder='Username' />
            <OtrTextInput size='huge' name='password' placeholder='Password' type='password' />
            <Button loading={loading} primary fluid size='huge'>LOGIN</Button>
          </Form>
          {
            error && (
              <p className='errorMessage'>
                The login details have not been recognised please try again
                or use the forgotten your login details below
              </p>
            )
          }
          <hr />
          <Link to='/forgot/'>FORGOTTEN YOUR LOGIN DETAILS?</Link>
        </div>
      </PublicFrame>
    )
  }
}

export default LoginScreen
