import axios from 'axios'
import queryString from 'query-string'
import authService from '../services/auth'
import { async } from 'q'

const baseUrl = process.env.REACT_APP_API_ROOT_SIMULATION

const getHeaders = () => {
  const authd = authService.isAuthenticated()
  if (authd.success) {
    return {
      'Authorization': `Bearer ${authd.token}`
    }
  }
  return {}
}

const apiGet = async (url, params) => {
  return axios.get(`${baseUrl}${url}?${queryString.stringify(params)}`, { headers: getHeaders() })
    .catch(error => ({ success: false, error }))
    .then(response => {
      const { success = true, error = null, data = null } = response
      return { success, error, data }
    })
}

const apiPost = async (url, payload) => {
  return axios.post(`${baseUrl}${url}`, payload, { headers: getHeaders() })
    .catch(error => ({ success: false, error, data: error.response.data }))
    .then(response => {
      const { success = true, error = null, data = null } = response
      return { success, error, data }
    })
}
const apiPostWithD = async (url,params, payload) => {
  return axios.post(`${baseUrl}${url}?${queryString.stringify(params)}`, payload, { headers: getHeaders() })
    .catch(error => ({ success: false, error, data: error.response.data }))
    .then(response => {
      const { success = true, error = null, data = null } = response
      return { success, error, data }
    })
}

const apiSimulator = {
  getRegionsWithVmMachines: async (customerId=1) => apiGet('AwsRegions/GetAwsRegionsWithVms', {customerId}),
  checkIfRegionHasAvailableMachines: async (customerId=1,RegionId) => apiGet('AwsRegions/CheckIfRegionHasAvailableMachines', {customerId,RegionId}),
  getAvailableMachineByRegion : async (AwsRegionId,CustomerId=1,token) => apiGet('Request/GetAvailableVmByRegion', { AwsRegionId,CustomerId,token }),
  getAvailableMachinesForInstructor : async (token) => apiGet('IDrillSimHostCustomerVms/GetCustomerVmsByCustomerApiUser', { token }),
  UpdateInstructorCustomerVMById : async (token,payload) => apiPostWithD('IDrillSimHostCustomerVms/SetVMById', { token },payload),
  UpdateAllInstructorCustomerVmsById : async (token,payload) => apiPostWithD('IDrillSimHostCustomerVms/SetUserVMsList', { token },payload)
}

export default apiSimulator
