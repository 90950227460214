import React, { Component } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import MenuLink from './menuLink'
import MenuLinkIco from './menuLinkIco'
import SubMenu from './SubMenu'
import {NavLink} from "react-router-dom";

export default class AdminMenuItems extends Component {
  render () {
    return (
      <React.Fragment>
          <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '3rem' }} >
              <NavLink exact className='menuLinkRow' to={'/'}>HOME</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/rigs/'}>RIGS</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/devices/'}>DEVICES</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/users/'}>USERS</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/reports'}>REPORTS</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/releases/'}>VERSIONS</NavLink>
              <div className={'menuDivider'} />
              <NavLink className='menuLinkRow' to={'/simulator/'}>SIMULATOR</NavLink>
          </div>
      </React.Fragment>
    )
  }
}
