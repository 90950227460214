import React, { Component } from 'react'
import { Grid, Button, Label, Checkbox } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { padStart } from 'lodash'

export default class OtrNumberInputFilter extends Component {
  state = {
    isChecked: this.props.isActive || false
  }
  renderTypesInput = (disabled) => {
    return (
        <Form.Dropdown
          name={`types[${this.props.name}]`}
          selection
          disabled={disabled}
          value={this.props.typeOptionsValue}
          options={[{ text: '>', value: '>' }, { text: '<', value: '<' }, { text: '=', value: '=' }]}
          errorLabel={<Label style={{ marginTop: '0.5rem', border: 0, fontFamily: 'Helvetica', fontWeight: 'normal', fontSize: '16px', padding: 0 }} icon='close' color='red' basic />}
        />
    )
  }
  render () {
    const { title, name, isActive = false, disabled = false, value = '', validations, validationErrors, required = true, size = 'medium', placeholder = '', type = 'text', titleWidth = 4, inputWidth = 10 } = this.props
    const { isChecked } = this.state;
    const inputDisabled = disabled || !isChecked; 
    // NOTE: Semantic UI grid rows come with 16 columns by default
    return (
      <Grid.Row>
        <Grid.Column width={1} style={{ 'alignContent': 'center' }}>
          <Form.Checkbox 
            toggle
            name={`selected[${name}]`}
            disabled={disabled}
            checked={isChecked}
            defaultChecked={isActive}
            onChange={(e, data) => this.setState({ isChecked: !isChecked })}
          />
        </Grid.Column>
        <Grid.Column width={titleWidth} style={{ 'alignContent': 'center', paddingInlineStart: '1.5rem'  }}>
          <label>{title}</label>
        </Grid.Column>        
        <Grid.Column width={1} style={{ display: 'flex', alignItems: 'center', paddingLeft:'0rem' }}>
          {this.renderTypesInput(inputDisabled)}
        </Grid.Column>
        <Grid.Column width={inputWidth} style={{ 'alignContent': 'center'}}>
          <Form.Input
            name={`value[${name}]`}
            placeholder={placeholder}
            fluid
            size={size}
            value={value}
            required={required}
            disabled={inputDisabled}
            instantValidation
            validations={validations}
            validationErrors={{
              ...validationErrors,
              isDefaultRequiredValue: `${(title || placeholder)} is Required`
            }}
            errorLabel={<div className='errorLabel' icon='close' color='red' />}
            type={type}
          />
        </Grid.Column>
      </Grid.Row>
    )
  }
}
