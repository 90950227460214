import React, { Component } from 'react'
import ScreenHeader from '../../components/screenHeader'
import ServerSelector from './components/ServerSelector'

export default class SimulatorSelector extends Component {
  render () {
    let actions = (
      <React.Fragment>
        <div style={{ paddingTop: '38px' }} />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <ScreenHeader actions={actions} title='Simulator Selection' />
        <ServerSelector />
      </React.Fragment>
    )
  }
}
