import React, { Component, ReactCenter } from 'react'
import { Icon } from 'semantic-ui-react'
import { getComplianceColor } from '../../../classes/complianceHelper'

export default class FleetCompliance extends Component {
  renderPercentage (value) {
    if (value) {
      return value.toFixed(1)
    } else {
      return '0.0'
    }
  }
  render () {
    const {
      value
    } = this.props

    return (
      <React.Fragment>
        <div style={{ border: '1px gray solid', backgroundColor: 'rgba(48, 82, 156, 0.3)', borderRadius: '3px', padding: '0.6rem', width: '100%', height: '100%' }}>
          <div style={{ backgroundColor: getComplianceColor(value), padding: '0.4rem', color: 'white', fontSize: '1.3rem', textAlign: 'center', borderRadius: '4px' }}>
            <Icon name='check' />FLEET COMPLIANCE: <span>{this.renderPercentage(value)}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
