import React, { Component } from 'react'
import { Grid, GridRow, GridColumn } from 'semantic-ui-react'
import dsLogo from '../img/drilling_systems_logo.svg'
import threetLogo from '../img/3t_logo.svg'
import api from "../classes/api"

export default class PublicFrame extends Component {
  constructor(props){
    super(props);
    this.state = {
      logoSrc: null
    }
    this.getLogo();
  }
  async getLogo() {
    if (!this.state.logoSrc)
    {
      let meta = await api.meta();
      let logo = meta.data ? meta.data.logo || dsLogo : dsLogo; 
      this.setState({ logoSrc : logo });
    }
  }

  render () {
    const { children } = this.props
    return (
      <Grid id='loginGrid' padded='horizontally'>
        <Grid.Row id='loginHeader'>
          {this.state.logoSrc && <img src={this.state.logoSrc} alt='Tenant Logo' height={80} />}
        </Grid.Row>
        <GridRow>
          <Grid.Column mobile={16} tablet={10} computer={8} largeScreen={6} widescreen={5} >
            {children}
          </Grid.Column>
        </GridRow>
        <GridRow id='loginFooter'>
          <GridColumn id='loginFooter'>
            <img alt='3T Energy Group logo' src={threetLogo} />
            <p>Powered by 3T Energy Group</p>
          </GridColumn>
        </GridRow>
      </Grid>
    )
  }
}
