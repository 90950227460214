import React, { Component } from 'react'
import loadingImage from '../img/OTR-loading-animation-small.gif'

export default class OtrLoadingSpinner extends Component {
  render () {
    const { shown = false, name = '' } = this.props
    if (shown) {
      return (
        <div className='tableLoadingSpinner'>
          <img alt='loading spinner' src={loadingImage} />
          <h4>Loading {name}</h4>
        </div>
      )
    }
  }
}
