import React, { Component } from 'react'
import { Grid, Form, Label, GridColumn, GridRow, Segment, Button } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import InputList from './InputList'
import OtrTextInput from '../../../components/otrTextInput'
import apiSimulator from '../../../classes/apiSimulationServerSelector'
import auth from '../../../services/auth'
import VmMachineUpdateRow from './VmMachineUpdateRow'
import Auth from '../../../services/auth'

export default class InstructorServerForm extends Component {
  state = {
    vms:[],
    message:'',
    loadingSendAll:false
  }

  constructor (props) {
    super(props)
    this.renderVmMachinesList=this.renderVmMachinesList.bind(this);
    this.handleClickOnSendAll=this.handleClickOnSendAll.bind(this);
    this.setMessage=this.setMessage.bind(this);
  }
  setMessage(str){
    this.setState({message:str})
  }
  componentDidMount () {
    let token = Auth.isAuthenticated().token
    apiSimulator.getAvailableMachinesForInstructor(token).then((response)=>{
      if(response.success){
        this.setState({vms:response.data})
        console.log(response.data)
      }
    })
  }
  renderVmMachinesList (){
    return (
      <div>
        {
          this.state.vms.map((vmMachine)=>{
            return <VmMachineUpdateRow setMessage={this.setMessage} vmMachine={vmMachine} ></VmMachineUpdateRow>
          })
        }
      </div>
    )

  }

  checkForErrors(machineId,startDate,endDate,email){
    let dateNow=new Date()
    dateNow.setHours(0,0,0,0)
    console.log(endDate)
    let endDateFixed=new Date(endDate)
    let startDateFixed=new Date(startDate)
    endDateFixed.setHours(0,0,0,0)
    startDateFixed.setHours(0,0,0,0)
    let errorStartDate=""
    let errorEndDate=""
    let errorEmail=""
    // SET ERROR TO NOTHING , Using plain javascript for now.
    let errorEmailDiv=document.getElementById(machineId+"-errorEmail")
    let errorStartDateDiv=document.getElementById(machineId+"-startDateError")
    let errorEndDateDiv=document.getElementById(machineId+"-endDateError")
    errorEmailDiv.innerText="";
    errorStartDateDiv.innerText="";
    errorEndDateDiv.innerText="";

    if(email==="" || email===null){
      errorEmail="Email can't be empty."
    }
    if(startDate===null){
      errorStartDate="Start date can't be empty."
    }else if(startDateFixed>endDateFixed){
      errorStartDate="Start date can't be after end Date."
    }
    if(endDate===null){
      errorEndDate="End date can't be empty."
    } else if(dateNow>endDateFixed){
      errorEndDate="End date can't be before today."
    }
    // SET ERRORS
    errorEmailDiv.innerText=errorEmail;
    errorStartDateDiv.innerText=errorStartDate;
    errorEndDateDiv.innerText=errorEndDate;
    return !(errorEndDate !== "" || errorStartDate !== "" || errorEmail !== "");
  }

  handleClickOnSendAll(e){

    let dtos = []
    this.state.vms.forEach((vmMachine)=>{
      console.log(vmMachine)
      let setVMByIdDto = {
        customerVmId:vmMachine.id,
        userEmail:vmMachine.launchUserId,
        startTime:vmMachine.windowStart,
        endTime:vmMachine.windowEnd,
      }
      if(vmMachine.launchUserId){
        dtos.push(setVMByIdDto);
      }
    })
    let noErrors=true;
    dtos.forEach((dto)=>{
      let noError = this.checkForErrors(dto.customerVmId,dto.startTime,dto.endTime,dto.userEmail)
      if(noError===false){
        noErrors=noError
      }
    })
    if(noErrors){
      let token = Auth.isAuthenticated().token
      this.setState({loadingSendAll:true})
      apiSimulator.UpdateAllInstructorCustomerVmsById(token ,dtos).then((response)=>{
        if(response.success){
          this.setState({message:response.data,loadingSendAll:false})
          setTimeout(()=>{
            this.setState({message:'',loadingSendAll:false})
          },7000)

        }else{
          console.log(response.error)
        }
      })
    }else{
      this.setState({message:'',loadingSendAll:false})
    }
  }
  render () {
      return (<div style={{margin:"auto"}}>

          {
            this.renderVmMachinesList()
          }
          <Button loading={this.state.loadingSendAll} onClick={this.handleClickOnSendAll}>Send All</Button>
            <p style={{fontSize:"1.4rem",color:"white"}}>{this.state.message}</p>
        </div>
      )
  }
}
