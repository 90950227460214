import React, { Component } from 'react'
import ScreenHeader from '../../components/screenHeader'
import api from '../../classes/api'
import { downloadCSV } from '../../classes/data'
import OtrTable from '../../components/otrTable'
import ExportButton from '../../components/exportButton'

export default class JobRoles extends Component {
  state = {
    search: '',
    loading: false,
    jobRoles: []
  }

  toggleLoading () {
    const { loading } = this.state
    this.setState({ loading: !loading })
  }

  searchUpdated = (event, data) => {
    this.setState({ search: data.value })
  }

  download = () => {
    downloadCSV(this.state.jobRoles, 'otr-jobRoles')
  }

  async getData () {
    this.toggleLoading()
    const result = await api.allJobRoles()
    this.toggleLoading()
    if (result.success) {
      this.setState({ jobRoles: result.data })
    }
  }

  componentDidMount () {
    this.getData()
  }

  render () {
    const { jobRoles, loading, search } = this.state
    return (
      <React.Fragment>
        <ScreenHeader
          title='JOB ROLES'
          actions={<ExportButton onClick={this.download} />}
          search={search}
          searchUpdated={this.searchUpdated}
        />
        <OtrTable
          data={jobRoles}
          hideColumns={["ID"]}
          name='Job Roles'
          loading={loading}
          search={search}
          clientPaging
        />
      </React.Fragment>
    )
  }
}
