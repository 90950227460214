import axios from 'axios'
import queryString from 'query-string'
import authService from '../services/auth'

const baseUrl = process.env.REACT_APP_API_ROOT

const getHeaders = () => {
  const authd = authService.isAuthenticated()
  if (authd.success) {
    return {
      'Authorization': `Bearer ${authd.token}`
    }
  }
  return {}
}

const apiGet = async (url, params) => {
  return axios.get(`${baseUrl}${url}?${queryString.stringify(params)}`, { headers: getHeaders() })
    .catch(error => ({ success: false, error }))
    .then(response => {
      const { success = true, error = null, data = null } = response
      return { success, error, data }
    })
}

const apiPost = async (url, payload) => {
  return axios.post(`${baseUrl}${url}`, payload, { headers: getHeaders() })
    .catch(error => ({ success: false, error, data: error.response.data }))
    .then(response => {
      const { success = true, error = null, data = null } = response
      return { success, error, data }
    })
}

const api = {
  authLogin: async (username, password) => apiPost('Authentication/Login', { username, password }),
  authRefresh: async payload => apiPost('Authentication/Refresh', payload),

  allActivityLog: async () => apiGet('ActivityLog/GetAll'),

  competencyByJobRoles: async FilterId => apiGet('Competency/GetByJobRoles', { FilterId }),
  competencyByGroups: async FilterId => apiGet('Competency/GetByGroups', { FilterId }),
  studentCompetency: async (FilterId, Count, Offset) => apiGet('Competency/GetStudentCompetency', { FilterId, Count, Offset }),
  currentStudentCompetency: async () => apiGet('Competency/GetCurrentStudent'),

  allEnvironments: async () => apiGet('Environment/GetAll'),

  allExercises: async () => apiGet('Exercise/GetAll'),
  exerciseDetails: async ExerciseId => apiGet('Exercise/GetDetails', { ExerciseId }),
  exerciseMeta: async query => apiGet('Exercise/GetMeta', { query }),
  getExercisesTableExport: async () => apiGet('Exercise/GetTableExport'),

  allExerciseResults: async () => apiGet('ExerciseResults/GetTableExport'),

  groupCreate: async payload => apiPost('Group/Create', payload),
  groupUpdate: async payload => apiPost('Group/Update', payload),
  groupById: async Id => apiGet('Group/GetById', { Id }),
  allGroups: async () => apiGet('Group/GetAll'),
  groupAddUser: async payload => apiPost('Group/AddUser', payload),
  groupRemoveUser: async payload => apiPost('Group/RemoveUser', payload),

  buildInfo: async query => apiGet('Info/BuildInfo', { query }),

  getJobRolesTableExport: async () => apiGet('JobRole/GetTableExport'),
  allJobRoles: async () => apiGet('JobRole/GetAll'),
  jobRoleAddUser: async payload => apiPost('JobRole/AddUser', payload),
  jobRoleRemoveUser: async payload => apiPost('JobRole/RemoveUser', payload),

  getAppRoles: async () => apiGet('Role/GetRoles'),
  appRoleAddUser: async payload => apiPost('Role/AddUser', payload),
  appRoleRemoveUser: async payload => apiPost('Role/RemoveUser', payload),

  otrHeartBeat: async () => {}, // /api/Otr/Heartbeat
  otrObjectLink: async () => {}, // /api/Otr/GetObjectLink
  otrById: async Id => apiGet('Otr/GetById', { Id }),
  allOtrs: async () => apiGet('Otr/GetAll'),
  createOtr: async () => {}, // /api/Otr/Create
  updateOtr: async payload => apiPost('Otr/Update', payload),
  getDevicesTableExport: async () => apiGet('Otr/GetTableExport'),

  allReports: async () => apiGet('Report/GetAll'),
  reportData: async ReportId => apiGet('Report/GetReportData', { ReportId }),
  reportDataByName: async ReportName => apiGet('Report/GetReportDataByName', { ReportName }),

  studentUsageLog: async (StudentId, Count, Offset) => apiGet('Student/GetUsageLog', { StudentId, Count, Offset }),
  studentUsage: async (Count, Offset) => apiGet('Student/GetStudentUsage', { Count, Offset }),
  students: async () => {}, // /api/Student/GetStudents

  createUser: async payload => apiPost('User/Create', payload),
  updateUser: async payload => apiPost('User/Update', payload),
  importUsers: async payload => apiPost('User/UsersImport', payload),

  getUsersTableExport: async () => apiGet('User/GetTableExport'),
  deleteUser: async payload => apiPost('User/Delete', payload),
  userById: async Id => apiGet('User/GetById', { Id }),
  user: async () => apiGet('User/GetUser'),
  allUsers: async (Count, Offset, Users) => apiGet('User/GetUsers', { Count, Offset, Users }),
  modifyUserPermissions: async () => {}, // /api/User/ModifyUserPermissions
  requestPasswordReset: async payload => apiPost('User/RequestPasswordReset', payload),
  requestPasswordResetEmail: async payload => apiPost('User/RequestEmailPasswordReset', payload),
  resetPassword: async payload => apiPost('User/ResetPassword', payload),
  userAddEnvironment: async payload => apiPost('User/AddEnvironment', payload),
  userRemoveEnvironment: async payload => apiPost('User/RemoveEnvironment', payload),
 
  getRigStats: async () => apiGet('Rig/GetRigStats', {}),
  getOtrStats: async (rigIds) => apiGet('Otr/GetOtrStats', { rigIds }),
  getUserStats: async (rigIds, deviceIds) => apiGet('User/GetUserStats', { rigIds, deviceIds }),

  createFilter: async payload => apiPost('Filter/Create', payload),
  deleteFilter: async payload => apiPost('Filter/Delete', payload),
  getFilters: async Ids => apiGet('Filter/Get', { Ids }),
  OTRAutoCreateFilter: async RigName => apiPost('Filter/OTRAutoCreate', { RigName }),

  // New Device Linking for Users
  devicesAddUser: async payload => apiPost('OtrUser/AddUser', payload),
  devicesRemoveUser: async payload => apiPost('OtrUser/RemoveUser', payload),
  devicesGetUsers: async Id => apiGet('OtrUser/GetUsers', { Id }),

  // Environment needed for Linking OTRs to specific elements for Reports
  devicesRemoveEnvironment: async payload => apiPost('Otr/RemoveEnvironment', payload),
  devicesAddEnvironment: async payload => apiPost('Otr/AddEnvironment', payload),

  devicesAddDevice: async payload => apiPost('Otr/Create', payload),

  // New Global Settings
  getSettings: async () => apiGet('Settings/GetSettings'),
  getSetting: async Id => apiGet('Settings/GetSetting', { Id }),
  updateSetting: async payload => apiPost('Settings/UpdateSetting', payload),

  getReleasesTableExport: async () => apiGet('AppRelease/GetTableExport'),
  getAllReleases: async () => apiGet('AppRelease/GetAll'),
  updateRelease: async (payload) => apiPost('AppRelease/Update', payload),


  getRigsTableExport: async () => apiGet('Rig/GetTableExport'),
  getAllRigs: async () => apiGet('Rig/GetAll'),
  rigAddOtr: async (payload) => apiPost('OtrRig/AddOtr', payload),
  rigRemoveOtr: async (payload) => apiPost('OtrRig/RemoveOtr', payload),
  userRemoveRig: async (payload) => apiPost('UserRig/Remove', payload),
  userAddRig: async (payload) => apiPost('UserRig/Add', payload),

  // feedback
  getFeedback: async (UserIds, ExerciseIds, OtrUnitIds) => apiGet('Feedback/Get', { UserIds, ExerciseIds, OtrUnitIds }),
  getAllFeedback: async () => apiGet('Feedback/GetAll'),
  deleteFeedback: async (payload) => apiPost('Feedback/Delete', payload),
  createFeedback: async (payload) => apiPost('Feedback/Create', payload),

  meta: async () => apiGet('Meta/Public')
}

export default api
