import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import { changeDataInXmL, changePivotCacheWorkSource, changeTableRef } from './xlmHelper'

const exportToXLSXWithTable = async (reportFile, dataFromApi, nameToSaveAs = 'Report.xlsx') => {
  let zipFileR = new JSZip.external.Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(reportFile, function (err, data) {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  }).then(function (data) {
    return JSZip.loadAsync(data)
  })
  let fixedDataFromApi = fixConversion(dataFromApi)
  let updatedDimension
  zipFileR.then(async (r) => {
    await r.files['xl/worksheets/sheet1.xml'].async('string').then(function (data) {
      var parser = new DOMParser()
      var xmlDoc = parser.parseFromString(data, 'text/xml')
      updatedDimension = changeDataInXmL(xmlDoc, fixedDataFromApi)
      console.log()
      r.file('xl/worksheets/sheet1.xml', new XMLSerializer().serializeToString(xmlDoc).replaceAll(`row xmlns=""`, 'row'))
    })
    await r.files['xl/tables/table1.xml'].async('string').then(function (data) {
      var parser = new DOMParser()
      var xmlDoc = parser.parseFromString(data, 'text/xml')
      changeTableRef(xmlDoc, updatedDimension)
      r.file('xl/tables/table1.xml', new XMLSerializer().serializeToString(xmlDoc))
    })
    r.generateAsync({ type: 'blob' })
      .then(function (content) {
        saveAs(content, nameToSaveAs)
      })
  })
}
const fixConversion = (data) => {
  let result = []
  result.push(data.columns)
  data.rows.forEach((row) => {
    result.push(row)
  })
  return result
}
export default exportToXLSXWithTable
