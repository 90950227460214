import React, { Component } from 'react'
import { Grid, Form, Label, GridColumn, GridRow, Segment, Button } from 'semantic-ui-react'
import OtrTextInput from '../../../components/otrTextInput'

export default class InputList extends Component {
  state = {
    users:[],
    userEmail:''
  }

  renderUserList(){
    return(
      <Grid>
        {
          this.state.users.map((user)=>{
            return (
              <GridRow>
                <h2>{user}</h2>
              </GridRow>
            )
          })
        }
      </Grid>
    )
  }
  handleFormSubmit(data){
    console.log(data)
  }
  render () {
    return (
      <Grid style={{width:'100%'}}>
          <OtrTextInput value={this.state.userEmail} name={"Users"} />
          <Button>Add Email</Button>
        { this.renderUserList()}
      </Grid>
    )
  }
}
