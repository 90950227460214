import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import OtrModal from './otrModal'
import OtrTextInput from './otrTextInput'

export default class DeleteFromSelectedModal extends Component {
  state = { modalOpen: false, deleted: false, errorMessage: null }
  handleOpen = () => this.setState({ modalOpen: true })
  handleClose = () => this.setState({ modalOpen: false })

   validSubmit = async (data) => {
     if (this.props.itemsIds) {
       let promises = this.props.itemsIds.map((id) => {
         let object = {}
         object[this.props.propertyId] = id
         return this.props.endpointToCall(object)
       })
       await Promise.all(promises).then((r) => {
         r.forEach((result) => {
           if (!result.success) {
             this.setState({ errorMessage: result.error.message })
           }
         })
         this.setState({ deleted: true })
         setTimeout(() => {
           this.handleClose()
         }, 2000)
       })
     }
   }

   render () {
     return (
       <OtrModal
         title='DELETE SELECTED'
         trigger={<Button primary onClick={this.handleOpen}>DELETE</Button>}
         open={this.state.modalOpen}
         onClose={this.handleClose}
       >
         <Form onValidSubmit={this.validSubmit}>
           <Grid>
             <Grid.Row style={{ textAlign: 'Center', paddingTop: '1.5rem' }}>
               <Grid.Column>
                 <p>
                  ARE YOU SURE YOU WANT TO DELETE SELECTED {this.props.itemsIds.length} ITEMS?
                 </p>
                 {
                   this.state.errorMessage ? <p>
                     {this.state.errorMessage}
                   </p> : this.state.deleted ? <p>Deleted Successfully</p> : ''
                 }
               </Grid.Column>
             </Grid.Row>
             <Grid.Row style={{ textAlign: 'right', paddingTop: '1.5rem' }}>
               <Grid.Column>
                 <Button primary color={'red'} style={{ margin: 0 }}>
                  DELETE
                 </Button>
               </Grid.Column>
             </Grid.Row>
           </Grid>
         </Form>
       </OtrModal>
     )
   }
}
