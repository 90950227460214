import React, { Component } from 'react'
import { Button, Label, Segment } from 'semantic-ui-react'
import defaultLogo from '../img/drilling_systems_logoBlack.svg'
import AddFilterModal from '../screens/adminHome/component/addFilterModal'
import api from "../classes/api";

export default class DashboardHeader extends Component {

  constructor(props)
  {
    super(props);
    this.state = {
      logoSrc : null,
    };
    this.getLogo();
  }

  async getLogo()
  {
    let meta = await api.meta();
    this.setState({ logoSrc : meta.data.logo });
  }

  render () {
    const {
      onFilterAdd,
      adminDashboard,
      filter,
      onFilterDelete
    } = this.props
    return (
      <Segment style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
       <img src={this.state.logoSrc || defaultLogo} alt='Tenant Logo' className={this.state.logoSrc ? '' : 'defaultLogo'} height="40em" />
        {/* {
          (filter) && (
            <div style={{ flex: 1, margin: '0 1rem' }}>
              { (filter.groups) && filter.groups.map(g => <Label key={g} className='groupLabel'>{g}</Label>) }
              { (filter.jobRoles) && filter.jobRoles.map(jr => <Label key={jr} className='groupLabel'>{jr}</Label>) }
              { (filter.devices) && filter.devices.map(d => <Label key={d} className='groupLabel'>{d}</Label>) }
            </div>
          )
        } */}
        {/* {
          (adminDashboard && !filter) && (
            <AddFilterModal validSubmit={onFilterAdd} />
          )
        }
        {
          (adminDashboard && filter) && (
            <Button color='red' onClick={() => { onFilterDelete(filter) }}>DELETE FILTER</Button>
          )
        } */}
      </Segment>
    )
  }
}
