import React, { Component } from 'react'
import _ from 'lodash'
import { Button, Menu, Header, Table, Segment } from 'semantic-ui-react'
import { searchData } from '../classes/search'
import loadingImage from '../img/OTR-loading-animation-small.gif'
import { Checkbox } from 'formsy-semantic-ui-react'

export default class OtrTable extends Component {
  state = {
    clientPagingOffset: 0
  }

  renderHeaders = (data, rowRemove, hideColumns) => {
    const firstRow = (data.length > 0 ? data[0] : {})
    const keys = Object.keys(firstRow)
    const rows = keys.map((val, idx) => {
      if (Array.isArray(hideColumns) && hideColumns.indexOf(_.upperCase(val)) > -1) {
        return (null)
      } else {
        return (<Table.HeaderCell key={idx}>{_.startCase(val)}</Table.HeaderCell>)
      }
    })

    if (rowRemove) {
      rows.push(<Table.HeaderCell key={999}></Table.HeaderCell>)
    }

    return rows
  }

  renderRows = (data, rowClicked, rowRemove, hideColumns) => {
    return data.map((row, idx) => {
      const keys = Object.keys(row)
      const onClick = rowClicked ? () => rowClicked(row) : () => {}

      return (
        <Table.Row key={idx} onClick={onClick}>
          {
            keys.map((val, i) => {
              if (Array.isArray(hideColumns) && hideColumns.indexOf(_.upperCase(val)) > -1) {
                return (null)
              } else {
                let rowVal = row[val]
                if (Array.isArray(rowVal)) {
                  rowVal = rowVal.map((v, idx) => <div key={idx}>{v.name}</div>)
                } else if (typeof (rowVal) === 'boolean') {
                  rowVal = <input type='checkbox' name={idx.toString()} defaultChecked={rowVal} value={rowVal} />
                }

                return (
                  <Table.Cell key={i}>
                    <p>{rowVal}</p>
                  </Table.Cell>
                )
              }
            })
          }
          {
            rowRemove && (
              <Table.Cell width={1}>
                <Button color='red' onClick={() => { rowRemove(row) }}>REMOVE</Button>
              </Table.Cell>
            )
          }
        </Table.Row>
      )
    })
  }

  renderPagination = (pageSize, totalRows, pageOffset, paginationPressed, extendedPaging) => {
    const pageCount = Math.ceil(totalRows / pageSize)
    const currentPageCount = Math.ceil(pageOffset / pageSize)

    if (pageCount < 2) {
      return null
    }

    if (!extendedPaging) {
      return (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={100}>
              <Menu pagination text>
                {pageOffset > 0 &&
                  <Menu.Item
                    as='a'
                    icon
                    onClick={() => { paginationPressed(pageOffset - pageSize) }}
                    style={{ paddingRight: '1.5rem' }}
                  >
                    PREVIOUS
                  </Menu.Item>
                }
                {
                  [...Array(pageCount)].map((_, i) => (
                    <Menu.Item
                      key={i}
                      as='a'
                      active={((i * pageSize) === pageOffset)}
                      onClick={() => { paginationPressed(i * pageSize) }}
                    >
                      {((i * pageSize) === pageOffset) ? '(' : ''}{i + 1}{((i * pageSize) === pageOffset) ? ')' : ''}
                    </Menu.Item>
                  ))
                }
                {currentPageCount !== (pageCount - 1) &&
                  <Menu.Item as='a' icon onClick={() => { paginationPressed(pageOffset + pageSize) }}>
                    NEXT
                  </Menu.Item>
                }
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )
    } else {
      var pageArray = new Array()
      var startPage = (currentPageCount - 2) > 1 ? currentPageCount - 2 : 1
      var endPage = (currentPageCount + 4) < pageCount ? currentPageCount + 4 : pageCount

      for (var workingPage = startPage; workingPage <= endPage; workingPage++) {
        pageArray.push(workingPage)
      }
      var arrayFirstPage = pageArray[0]
      var arrayLastPage = pageArray[pageArray.length - 1]
      return (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={100} >
              <Menu pagination text>
                {pageOffset > 0 &&
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => { paginationPressed(pageOffset - pageSize) }}
                  style={{ paddingRight: '1.5rem' }}
                >
                  PREVIOUS
                </Menu.Item>
                }
                {arrayFirstPage > 1 &&
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => { paginationPressed(0) }}
                  style={{ paddingRight: '1.5rem' }}
                >
                  1
                </Menu.Item>
                }
                {arrayFirstPage > 1 &&
                <Menu.Item
                  as='a'
                  icon
                  active={false}
                  onClick={() => { }}
                  style={{ paddingRight: '1.5rem' }}
                >
                  ...
                </Menu.Item>
                }
                {
                  pageArray.map(linkPage => (
                    <Menu.Item
                      key={(linkPage - 1)}
                      as='a'
                      active={(((linkPage - 1) * pageSize) === pageOffset)}
                      onClick={() => { paginationPressed((linkPage - 1) * pageSize) }}
                    >
                      {(((linkPage - 1) * pageSize) === pageOffset) ? '(' : ''}{linkPage}{(((linkPage - 1) * pageSize) === pageOffset) ? ')' : ''}
                    </Menu.Item>
                  ))
                }
                {arrayLastPage < pageCount &&
                <Menu.Item
                  as='a'
                  icon
                  active={false}
                  onClick={() => { }}
                  style={{ paddingRight: '1.5rem' }}
                >
                  ...
                </Menu.Item>
                }
                {arrayLastPage < pageCount &&
                <Menu.Item
                  as='a'
                  icon
                  onClick={() => { paginationPressed((pageCount - 1) * pageSize) }}
                  style={{ paddingRight: '1.5rem' }}
                >
                  {pageCount}
                </Menu.Item>
                }
                {currentPageCount !== (pageCount - 1) &&
                <Menu.Item as='a' icon onClick={() => { paginationPressed(pageOffset + pageSize) }}>
                  NEXT
                </Menu.Item>
                }

              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )
    }
  }

  renderLoading = (name) => (
    <div className='tableLoadingSpinner'>
      <img alt='loading spinner' src={loadingImage} />
      <h4>Loading {name}</h4>
    </div>
  );

  clientPagingationPressed = (offset) => {
    this.setState({ clientPagingOffset: offset })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.search !== this.props.search) {
      this.setState({ clientPagingOffset: 0 })
    }
  }

  render () {
    let {
      data = [],
      hideColumns = [],
      rows = null,
      totalRows = 0,
      pageSize = 25,
      loading = false,
      name = '',
      pageOffset = 0,
      paginationPressed = () => {},
      rowClicked = null,
      search = '',
      filteredColumns = [],
      rowRemove = null,
      clientPaging = false,
      extendedPaging = false
    } = this.props

    const { clientPagingOffset } = this.state

    const searchedData = searchData(data, search)
    let filteredData = searchedData.map((row) => {
      filteredColumns.forEach(f => {
        delete row[f]
      })
      return row
    })

    if (clientPaging) {
      totalRows = filteredData.length
      filteredData = filteredData.slice(clientPagingOffset, clientPagingOffset + pageSize)
      paginationPressed = this.clientPagingationPressed
      pageOffset = clientPagingOffset
    }

    const table = (
      <Table basic='very' className={'alternateTable'} selectable={(rowClicked !== null)}>
        <Table.Header>
          <Table.Row>
            {this.renderHeaders(filteredData, rowRemove, hideColumns)}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { rows || this.renderRows(filteredData, rowClicked, rowRemove, hideColumns) }
        </Table.Body>
        {this.renderPagination(pageSize, totalRows, pageOffset, paginationPressed, extendedPaging)}
      </Table>
    )

    const empty = (
      <Header as='h4' className='emptyMessage' textAlign='center' style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>No {name} found</Header>
    )

    return (
      <Segment>
        { loading && this.renderLoading(name) }
        { (filteredData.length > 0 && !loading) && table }
        { (filteredData.length === 0 && !loading) && empty }
      </Segment>
    )
  }
}
